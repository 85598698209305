import { Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import {
  useGetVendorByIdQuery,
  useAddVendorMutation,
  useUpdateVendorMutation,
} from "../../../../../../../store/apis/VendorApis";
import GeneratedForm from "../../../../../../shared/generatedForm/GeneratedForm";
import { BaseButton } from "../../../../../../shared/BaseButton";
import { VENDOR_FIELDS } from "./forms.constants";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";

export default function VendorInfoForm() {
  const [searchParams] = useSearchParams();
  const currentVendorId = searchParams.get("id");
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const [
    addVendor,
    {
      isSuccess: isSuccessAdd,
      isLoading: addLoading,
      isError: isErrorAdd,
      error: addError,
    },
  ] = useAddVendorMutation();

  const [
    updateVendor,
    {
      isSuccess: isSuccessUpdate,
      isLoading: updateLoading,
      isError: isErrorUpdate,
      error: updateError,
    },
  ] = useUpdateVendorMutation();

  const isSubmitting = addLoading || updateLoading;
  const isSuccess = isSuccessAdd || isSuccessUpdate;
  const isError = isErrorAdd || isErrorUpdate;
  const error = addError || updateError;

  useEffect(() => {
    if (!isSubmitting && isSuccess) {
      navigate(-1);
    }
  }, [isSubmitting, isSuccess]);

  useEffect(() => {
    if (isError && error) {
      setErrorsForProperties({ setError: setError, responseError: error });
    }
  }, [error]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const isEdit = null != currentVendorId;

  const {
    data: currentVendor,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetVendorByIdQuery(currentVendorId, { skip: !isEdit });

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentVendor) {
      reset(currentVendor);
    }
  }, [currentVendor]);

  const onSubmit = (data) => {
    if (isAuthenticated) {
      if (isEdit) {
        updateVendor(data);
        return;
      }
      addVendor(data);
    }
  };

  const isEditLoading = isEdit && currentFetching;

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
      <Box>
        <BaseButton
          text="Back To Vendors"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />
      </Box>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "row",
            }}>
            <GeneratedForm
              control={control}
              list={VENDOR_FIELDS}
              errors={errors}
            />

            <Grid item md={12}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            <Grid item md={12}>
              <ValidationErrorSummary errors={error} />
            </Grid>
            {/* {isError ? (
              <Typography color={"error"}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined} */}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
