import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export default function CalculatedPricing() {
  const { control, setValue } = useFormContext();
  const selectedProducts = useWatch({ control, name: "selectedProducts" });
  const watchedValues = useWatch({
    control,
    name: [
      "isHardCopy",
      "hardCopyMenuQuantity",
      "hardCopyMenuPrice",
      "isWebBasedMenu",
      "webBasedMenuQuantity",
      "webBasedMenuPrice",
    ],
  });

  const { pricing } = calculateTotals({
    selectedProducts,
    watchedValues,
  });

  setValue("pricing", pricing);

  return <></>;
}

function calculateTotals({ selectedProducts, watchedValues }) {
  const [
    isHardCopy,
    hardCopyMenuQuantity,
    hardCopyMenuPrice,
    isWebBasedMenu,
    webBasedMenuQuantity,
    webBasedMenuPrice,
  ] = watchedValues;

  let pricing = 0;
  if (isHardCopy) pricing += hardCopyMenuQuantity * hardCopyMenuPrice;
  if (isWebBasedMenu) pricing += webBasedMenuQuantity * webBasedMenuPrice;

  selectedProducts?.forEach((_, index) => {
    if (selectedProducts[index].isSelected) {
      pricing +=
        selectedProducts[index].quantity * selectedProducts[index].price;
    }
  });

  return { pricing };
}
