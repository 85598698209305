import {
  Box,
  Breadcrumbs,
  Card,
  ListItemIcon,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { BaseButton } from "../BaseButton";
import { useSelector } from "react-redux";
import { selectHeaderText } from "store/slices/appSlice/AppSlice";
import { DMStyles } from "styles/Styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { theme } from "styles/theme";
import { Circle } from "@mui/icons-material";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseContent.md)
 */

const BaseContent = ({
  headerText,
  subHeaderText,
  backLink = -1,
  backText = "Back",
  backIcon = "arrow_back",
  children,
  transparent = false,
  disableBack = false,
  disableHeight = false,
  cardPadding = "8px",
  boxMargin,
  cardMarginTop = "10px",
  btnColor,
  cardOverFlow = true,
  isStateHeaderText = false,
  headerTextLoading = false,
  breadcrumbs,
  flexGrow = false,
  icon = true,
}) => {
  const crumbs = useMemo(() => {
    return breadcrumbs || (headerText ? undefined : []);
  }, [breadcrumbs, headerText]);

  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const stateHeaderText = isStateHeaderText
    ? useSelector(selectHeaderText)
    : headerText;

  let styleObject = {
    padding: cardPadding,
    marginTop: cardMarginTop,
    backgroundColor: transparent ? "transparent" : "white",
  };

  if (!disableHeight) {
    styleObject["flexGrow"] = 1;
    styleObject["display"] = "flex";
    styleObject["height"] = "100%";
    styleObject["flexDirection"] = "column";
  }

  if (flexGrow) {
    styleObject["flexGrow"] = 1;
  }

  if (cardOverFlow) {
    styleObject["overflow"] = "auto";
  }

  if (!transparent) {
    styleObject["boxShadow"] = "none !important";
  }
  const backNavigation = () => navigate(-1);

  const linkName = pathname?.split("/")[1];
  return (
    <Box
      id="base-content"
      sx={{
        height: "100%",
        marginX: boxMargin || 1,
        marginY: boxMargin || 0,
        display: "flex",
        flexDirection: "column",
        marginTop: "-69px",
      }}
    >
      <Box
        id="base-content-header"
        sx={{
          display: "flex",
          alignContent: "center",
          flexDirection: "row",
          minHeight: "78px",
          maxHeight: "78px",
          flexShrink: 0,
          paddingY: "0.8rem",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "relative",
          background:
            pathname === "/home" || linkName.length === 0
              ? "transparent"
              : theme.palette.green.greenGradient,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            width: { xs: "100%", md: "calc(100% - 500px)" },
            alignItems: "center",
            marginLeft: { xs: 3, md: 0 },

            gap: 2,
          }}
        >
          {!(pathname === "/home" || linkName.length === 0) && (
            <GetIconName linkName={linkName} />
          )}
          <Box
            sx={{
              mt: "5px",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "center",
              gap: 2,
              zIndex: 1,
            }}
          >
            <Box>
              {headerTextLoading ? (
                <Skeleton variant="text" width={200} height={"40px"} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={stateHeaderText}>
                    <Typography
                      variant="h1"
                      component={"h1"}
                      sx={{
                        fontSize: DMStyles.HeadingFontSize,
                        fontWeight: "900",
                        width: !disableBack && !subHeaderText ? "54%" : "50%",
                        color: "white !important",
                        whiteSpace: "nowrap", // Prevents text wrapping
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        position: "absolute",
                      }}
                    >
                      {stateHeaderText}
                    </Typography>
                  </Tooltip>
                  {!subHeaderText ? (
                    <Typography
                      variant="h1"
                      component={"h1"}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "900",
                        fontStyle: "italic",
                      }}
                    >
                      {subHeaderText}
                    </Typography>
                  ) : null}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                justifyContent: "flex-start",
                alignItems: "center",
                color: "white",
              }}
            >
              {!disableBack && (
                <BaseButton
                  colors={btnColor || "white"}
                  variant={"contained"}
                  To={backLink ? backLink : undefined}
                  state={state}
                  text={backText}
                  startIcon={
                    icon && <span className="material-icons">{backIcon}</span>
                  }
                  onClick={!backLink ? backNavigation : undefined}
                />
              )}
              {crumbs ? (
                <Breadcrumbs
                  separator={
                    <Circle
                      color="white"
                      sx={{
                        fontSize: "9px !important",
                      }}
                    />
                  }
                >
                  {crumbs.map((crumb, index) => (
                    <Typography
                      key={index}
                      variant="h1"
                      component={crumb.link ? Link : "h1"}
                      to={crumb.link}
                      color="white"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        zIndex: 1,
                      }}
                    >
                      {`${crumb.name}`}
                    </Typography>
                  ))}
                </Breadcrumbs>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <Card
        id="base-content-card"
        sx={{
          ...styleObject,
        }}
      >
        {children}
      </Card>
    </Box>
  );
};

export default BaseContent;

function GetIconName({ linkName }) {
  let icon;
  if (!linkName) return null;
  switch (true) {
    case linkName.includes("admin"):
      icon = "admin_panel_settings";
      break;
    case linkName.includes("home"):
      icon = "home";
      break;
    case linkName.includes("Consultant"):
      icon = "assignment";
      break;
    case linkName.includes("plateFul") || linkName.includes("plateful"):
      icon = "flatware";
      break;
    case linkName.includes("MealCard"):
      icon = "groups";
      break;
    case linkName.includes("TableSide"):
      icon = "table_restaurant";
      break;
    case linkName.includes("clientPortal"):
      icon = "computer";
      break;
    case linkName.includes("FAQ"):
      icon = "help";
      break;
    case linkName.includes("contact"):
      icon = "phone";
      break;
    case linkName.includes("chatAIConvo"):
      icon = "message";
      break;
    case linkName.includes("mycredentials"):
      icon = "home";
      break;
    default:
      icon = null;
      break;
  }

  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        border: "3px solid white",
        borderRadius: "50%",
        width: "55px",
        height: "55px",
        backgroundColor: "transparent",
        marginLeft: "1rem",
      }}
    >
      <ListItemIcon>
        <span
          style={{
            color: "white",
            margin: "0 auto",
          }}
          className="material-icons"
        >
          {icon}
        </span>
      </ListItemIcon>
    </Box>
  );
}
