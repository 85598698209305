import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import "./ImportVendorPrice.css";
import BaseAutoComplete from "../../../../../shared/baseForm/BaseAutoComplete";
import {
  useGetVendorSelectBoxQuery,
  useUploadVendorDocumentMutation,
} from "../../../../../../store/apis/VendorApis";
import { debounce } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import { BaseButton } from "../../../../../shared/BaseButton";
import {
  INDEX_OPTIONS,
  VENDOR_PRICE_IMPORT_FIELDS,
} from "./ImportVendorPrice.constants";

function ImportVendorPrice() {
  const navigate = useNavigate();
  const [debouncedVendorSearch, setDebouncedVendorSearch] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [vendorId, setVendorId] = useState(0);
  const { data: vendors, isFetching } = useGetVendorSelectBoxQuery(
    debouncedVendorSearch,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [
    uploadDoc,
    {
      data: uploadedData,
      isSuccess: uploadSuccess,
      isLoading: uploadLoading,
      isError,
      error,
    },
  ] = useUploadVendorDocumentMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: {
      vendorId: null,
      itemIndex: "",
      descriptionIndex: "",
      packIndex: "",
      priceIndex: "",
    },
  });
  const {
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (uploadedData && uploadSuccess && !uploadLoading) {
      navigate(`ImportFile?vendorId=${vendorId}`);
    }
  }, [uploadSuccess, uploadLoading, uploadedData]);

  useEffect(() => {
    if (isError) {
      setErrorsForProperties({
        setError,
        responseError: error,
      });
    }
  }, [isError, error]);

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedVendorSearch(query);
      }, 400),
    []
  );

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
    clearErrors("file");
  };

  const onSubmit = (data) => {
    setVendorId(getValues("vendorId"));

    const formData = new FormData();
    if (data.vendorId === null)
      setError("vendorId", { type: "required", message: "Vendor is required" });
    else formData.append("vendorId", data.vendorId);
    if (!uploadedFile) {
      setError("file", {
        type: "required",
        message: "File is required",
      });
      return;
    } else formData.append("file", uploadedFile);
    formData.append("itemIndex", data.itemIndex);
    formData.append("packIndex", data.packIndex);
    formData.append("descriptionIndex", data.descriptionIndex);
    formData.append("priceIndex", data.priceIndex);

    uploadDoc(formData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box>
        <BaseButton
          text="Back To Vendors"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />
      </Box>

      <div className="importVendorPriceFormWrapper">
        <ImportantInfo />
        <FormProvider {...methods}>
          <Grid sx={{ width: "100%" }} container spacing={2}>
            <Grid size={2}></Grid>
            <Grid size={8}>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <BaseAutoComplete
                    options={vendors || []}
                    defaultOptions={[]}
                    loading={isFetching}
                    handleSearch={handleSearch}
                    formSelectProps={{
                      props: {
                        label: "Vendor",
                        id: "vendorId",
                        name: "vendorId",
                      },
                      validationProps: {
                        required: "Vendor is required",
                      },
                    }}
                  />
                </Grid>
                {VENDOR_PRICE_IMPORT_FIELDS.formSelectProps.map((data) => {
                  return (
                    <Grid key={data?.props?.name} size={3}>
                      {/* <BaseAutoComplete
                        options={indexOptions}
                        loading={false}
                        control={control}
                        formSelectProps={data}
                        errors={errors}
                      /> */}
                      <BaseSelect
                        name={data?.props?.name}
                        id={data?.props?.name}
                        label={data?.props?.label}
                        validationProps={data?.validationProps}
                        options={INDEX_OPTIONS}
                        placeholder={data?.props?.placeholder}
                      />
                    </Grid>
                  );
                })}
                <Grid size={12}>
                  <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    color={errors?.file ? "error" : "primary"}
                    startIcon={
                      <span className="material-icons">
                        {uploadedFile ? "description" : "border_clear"}
                      </span>
                    }
                    sx={{ marginRight: "1rem" }}
                  >
                    {uploadedFile ? `Selected File: ` : `Select File`}

                    <input
                      type="file"
                      accept=".xls, .xlsx"
                      hidden
                      onChange={handleFileUpload}
                    />
                    {fileName}
                  </Button>
                  {errors?.file && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.file?.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid size={2}></Grid>
          </Grid>
        </FormProvider>
      </div>
      <Grid container spacing={2}>
        <Grid size={2}></Grid>
        <Grid size={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color={"primary"}
              onClick={handleSubmit(onSubmit)}
              startIcon={
                uploadLoading === true ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }
            >
              Save
            </Button>
          </Box>
          <ValidationErrorSummary errors={error} />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Box>
  );
}

const ImportantInfo = () => {
  return (
    <div className="importantInfoWrapper">
      <p>Important:</p>
      <ul style={{ marginLeft: "20px" }}>
        <li>
          The data on the imported spreadsheet should start on row/line 2.
        </li>
        <li>
          The vendor number for each item must already exist in the database.
        </li>
        <li>Any data that is not well formatted will be discarded.</li>
        <li>Pack size must not have changed.</li>
        <li>
          File to be imported must be an XLS (pre-Excel 2007) or XLSX (Excel
          2007 and later) file.
        </li>
      </ul>
    </div>
  );
};

export default ImportVendorPrice;
