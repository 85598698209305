/* eslint-disable */
import React from "react";
import { FormHelperText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { addVendorFields } from "./forms.constants";
import { EditSkeleton } from "components/admin/admin.overlays";

export default function VendorIngredientInfoForm({
  control,
  errors,
  getValue,
  vendorListLoading,
  unitListLoading,
}) {
  const computedCostValue = getValue("calculatedCostPerIngredientBaseId");
  const problems = getValue("problems");
  const computedCost =
    computedCostValue === undefined ? 0.0 : computedCostValue;
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const loading = vendorListLoading && unitListLoading;

  return (
    <>
      {loading ? (
        <EditSkeleton />
      ) : (
        <Grid container spacing={2} style={{ marginTop: 2, marginLeft: 2 }}>
          <GeneratedForm
            oldGrid={false}
            control={control}
            list={addVendorFields}
            errors={errors}
          />

          <Grid item size={{ xs: 12 }}>
            <Typography
              variant="p"
              component={"p"}
              sx={{
                fontWeight: 200,
              }}
            >
              Computed Base Cost: {currencyFormatter.format(computedCost)}
            </Typography>
          </Grid>
          <Grid item size={{ xs: 12 }}>
            {problems == 1 && (
              <FormHelperText error>
                Base cost cannot be computed due to a mismatch of Units.
              </FormHelperText>
            )}
            {problems == 2 && (
              <FormHelperText error>
                Base cost cannot be computed because no Price was entered.
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
