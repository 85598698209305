import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import {
  INVALID_NAME_VALIDATION,
  MIN_LENGTH_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const ADD_USER_FORM = [
  {
    props: {
      name: "userId",
      id: "userId",
      label: "User",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
      pattern: INVALID_NAME_VALIDATION(),
      minLength: MIN_LENGTH_VALIDATION("Name", 4),
    },
    endpoint: "getUserSelectBox",
    type: FORM_TYPES.AutocompleteAPI,
    size: 6,
  },
];
