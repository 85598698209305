import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import {
  matchPath,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import OutletContainer from "components/shared/styledComponents/OutletContainer";
import {
  IngredientMenuElements,
  IngredientMenuElement,
} from "./ingredientmenu.constants";
import "./Ingredient.css";
import "../../../shared/AdminTabs.css";
import {
  useGetIngredientByIdQuery,
  useRecalculateIngredientMutation,
} from "store/apis/IngredientAPIs";
import { theme } from "styles/theme";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";

export default function IngredientMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [value, setValue] = useState(1);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [menuItems, setMenuItems] = useState(IngredientMenuElements);

  const { data: currentIngredient, isSuccess: currentSuccess } =
    useGetIngredientByIdQuery(id, { skip: !id });

  const [recalculateIngredient, { isLoading }] =
    useRecalculateIngredientMutation();

  useEffect(() => {
    let currentLink;
    if (pathname) {
      currentLink = menuItems.find((x) =>
        matchPath({ path: x.Link, end: false }, pathname)
      );
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      IngredientMenuElements.indexOf(
        IngredientMenuElements.find(
          (res) =>
            matchPath({ path: res.Link, end: false }, pathname) &&
            res.Name !== "Back"
        )
      ) || 1;
    setValue(currentIndex !== -1 ? currentIndex : 1);
    setMenuItems(
      IngredientMenuElements.map((res) => {
        if (matchPath({ path: res.Link, end: false }, pathname)) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
    // Check if the current path is /IngredientDetails/addIngredient and id is null or undefined
    if (
      matchPath(
        { path: "/admin/recipes/IngredientDetails/addIngredient", end: false },
        pathname
      ) &&
      (!id || id === null)
    ) {
      setMenuItems(IngredientMenuElement); // Use IngredientMenuElement instead of IngredientMenuElements
    }
  }, [pathname]);

  return (
    <Box
      id="community-outlet-container"
      sx={{
        margin: -1,
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "self-end",
          }}
        >
          <Tabs
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
            }}
            value={value}
            aria-label="Community Tabs"
          >
            {menuItems.map((data, index) => {
              return (
                <LinkTab
                  key={index}
                  value={index}
                  id={id}
                  label={`${
                    data.Name === "Details" && currentSuccess
                      ? currentIngredient?.name
                      : data.Name
                  }`}
                  isTitle={data.Name === "Details"}
                  href={`${data.Link}?id=${id}`}
                  link={data.Link}
                  name={currentIngredient?.name}
                  navigate={navigate}
                />
              );
            })}
          </Tabs>
          {pathname.includes("addIngredient") && (
            <BaseSubmitButton
              sx={{
                background: theme.palette.primary.main,
                color: "white !important",
              }}
              text="Recalculate"
              onClick={() => recalculateIngredient(currentIngredient?.id)}
              disabled={isLoading}
              isSubmitting={isLoading}
            />
          )}
        </Box>
      </Box>

      <OutletContainer id="community-outlet">
        <Outlet />
      </OutletContainer>
    </Box>
  );
}

function LinkTab({ label, id, isTitle, link, name, navigate, ...rest }) {
  return (
    <Tab
      component={NavLink}
      className={
        label === "Back"
          ? "backTab"
          : isTitle
          ? "ingredientDetailTab"
          : "adminTabs"
      }
      to={label === "Back" ? "" : `${link}?id=${id}&name=${name}`}
      onClick={(event) => {
        if (label === "Back" && !link) {
          event.preventDefault();
          navigate("/admin/recipes/ingredients");
          return;
        }
      }}
      icon={label === "Back" ? <KeyboardArrowLeft /> : null}
      label={label === "Back" ? "Back" : label}
      {...rest}
    />
  );
}
