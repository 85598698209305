import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { VENDORS, VENDOR_PRODUCTS, VENDOR_IMPORT_DATA } = API_ENDPOINTS;

export const VendorApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVendorList: builder.query({
      query: (query) => `/${VENDORS}?searchText=${query}`,
      transformResponse: (response) => response?.data?.items,
      providesTags: (response) =>
        response
          ? [...response.map(({ id }) => ({ type: VENDORS, id })), VENDORS]
          : [VENDORS],
    }),
    getPagedVendorList: builder.query({
      query: ({ query, skip, take }) =>
        `/${VENDORS}?searchText=${query}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.items.map(({ id }) => ({ type: VENDORS, id })),
              VENDORS,
            ]
          : [VENDORS],
    }),
    getVendorSelectList: builder.query({
      query: (query) => `/${VENDORS}?searchText=${query}`,
      transformResponse: (response) =>
        response?.data?.items?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.items.map(({ id }) => ({ type: VENDORS, id })),
              VENDORS,
            ]
          : [VENDORS],
    }),
    getVendorSelectBox: builder.query({
      query: (query) => {
        const searchText = typeof query === "object" ? "" : query;
        return `/${VENDORS}?searchText=${searchText}&skip=0&take=1000`;
      },
      transformResponse: (response) =>
        response?.data?.items?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: `${res?.city} ${res?.state}`,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.items.map(({ id }) => ({ type: VENDORS, id })),
              VENDORS,
            ]
          : [VENDORS],
    }),
    getVendorById: builder.query({
      query: (id) => `/${VENDORS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: VENDORS, id: response.id }, VENDORS] : [VENDORS],
    }),
    addVendor: builder.mutation({
      query: (data) => ({
        url: `/${VENDORS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: VENDORS, id: result?.id }],
    }),
    updateVendor: builder.mutation({
      query: (data) => ({
        url: `/${VENDORS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: VENDORS, id: result.id }],
    }),
    deleteVendor: builder.mutation({
      query: (id) => ({
        url: `/${VENDORS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: VENDORS, id: arg.id }],
    }),
    uploadVendorDocument: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_PRODUCTS}/UploadGenericDocument`,
        method: "POST",
        body: data,
        formData: true,
      }),
      transformResponse: (response) => response?.data,
      // async queryFn(data, _queryApi, _extraOptions, fetchWithBQ) {
      //   const response = await fetchWithBQ(
      //     {
      //       url: `/${VENDOR_PRODUCTS}/UploadGenericDocument`,
      //       method: "POST",
      //       body: data,
      //     },
      //     _queryApi,
      //     _extraOptions
      //   );
      //   if (response.error) throw response.error;
      //   return response.data
      //     ? { data: response.data }
      //     : { error: response.error };
      // },
      invalidatesTags: (result) => [{ type: VENDORS, id: result.data.id }],
    }),
    getVendorImportData: builder.query({
      query: (vendorId) =>
        `/${VENDOR_PRODUCTS}/GetImportData?vendorId=${vendorId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: VENDOR_IMPORT_DATA, id })),
              VENDOR_IMPORT_DATA,
            ]
          : [VENDOR_IMPORT_DATA],
    }),
    updateVendorImportData: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_PRODUCTS}/UpdateImportDataAsync`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VENDOR_IMPORT_DATA, id: arg.id },
      ],
    }),
    getVendorProducts: builder.query({
      query: ({ vendorId, searchText }) =>
        `/${VENDOR_PRODUCTS}/GetVendorProducts?vendorId=${vendorId}&searchText=${searchText}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: VENDOR_PRODUCTS, id })),
              VENDOR_PRODUCTS,
            ]
          : [VENDOR_PRODUCTS],
    }),
    copyVendorProducts: builder.mutation({
      query: ({ vendorFromId, vendorToId }) => ({
        url: `/${VENDORS}/CopyVendorProducts?vendorFromId=${vendorFromId}&vendorToId=${vendorToId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VENDOR_PRODUCTS, id: arg.id },
      ],
    }),
    updateMultipleVendorProducts: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_PRODUCTS}/UpdateVendorProducts`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VENDOR_PRODUCTS, id: arg.id },
      ],
    }),
    updateSingleVendorProduct: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_PRODUCTS}/UpdateVendorProduct`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VENDOR_PRODUCTS, id: arg.id },
      ],
    }),
    recalcVendorPricing: builder.mutation({
      query: ({ vendorId, menuId }) => ({
        url: `/${VENDORS}/RecalculatePricing?vendorId=${vendorId}&menuId=${menuId}`,
        method: "PUT",
      }),
    }),
    getVendorProductBackfeed: builder.query({
      query: ({ vendorId, searchTerm, take, skip }) => ({
        url: `/${VENDOR_PRODUCTS}/getProductBackfeed?vendorId=${vendorId}&take=${take}&searchText=${searchTerm}&skip=${skip}`,
        method: "GET",
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetVendorByIdQuery,
  useGetVendorSelectBoxQuery,
  useUploadVendorDocumentMutation,
  useGetVendorImportDataQuery,
  useUpdateVendorImportDataMutation,
  useGetVendorProductsQuery,
  useCopyVendorProductsMutation,
  useUpdateMultipleVendorProductsMutation,
  useUpdateSingleVendorProductMutation,
  useRecalcVendorPricingMutation,
  useGetVendorListQuery,
  useGetPagedVendorListQuery,
  useGetVendorSelectListQuery,
  useAddVendorMutation,
  useUpdateVendorMutation,
  useGetVendorProductBackfeedQuery,
} = VendorApis;
