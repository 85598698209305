/* eslint-disable */
import React, { useMemo, useState } from "react";
import {
  useRecalculateNutrientAnalysisMutation,
  useRecipeNutrientAnalysisQuery,
} from "../../../../../store/apis/RecipeAPIs";
import { useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { Card, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";
import { useGetHepContributionsByRecipeQuery } from "store/apis/MenuApis";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function RecipeNutrientAnalysis() {
  // eslint-disable-next-line no-undef

  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const [showMore, setShowMore] = useState(false);
  const {
    data: results,
    isFetching: loading,
    // isError,
  } = useRecipeNutrientAnalysisQuery(recipeId, {
    skip: !recipeId,
  });

  const { data: hepContributions, isLoading: hepLoading } =
    useGetHepContributionsByRecipeQuery(recipeId, {
      skip: !recipeId,
    });

  const load = loading || hepLoading;

  const [recalculate, { isLoading: isSubmitting }] =
    useRecalculateNutrientAnalysisMutation();

  const nutrients = results?.data?.nutrients?.map((item) => ({
    ...item,
    id: item?.nutrientId,
  }));

  const nutrientsDetails = results?.data?.nutrientDetails?.reduce(
    (acc, item) => {
      const existingItem = acc.find((x) => x.id === item.ingredientId);
      if (existingItem) {
        existingItem.problems = existingItem.problems
          ? `${existingItem.problems}; ${item.problems}`
          : item.problems;
      } else {
        acc.push({
          id: item.ingredientId,
          problems: item.problems,
        });
      }
      return acc;
    },
    []
  );

  const nutrientsColumn = useMemo(() => {
    if (results?.data?.nutrients) {
      let columns = [
        {
          field: "yield",
          headerName: "% Yield",
          minWidth: 65,
        },
        {
          field: "ingredient",
          headerName: "INGREDIENT",
          renderCell: (params) => {
            return (
              <Box display={"flex"} gap={0.5}>
                {nutrientsDetails.find((x) => x.id === params.row.ingredientId)
                  ?.problems?.length > 0 ? (
                  <Tooltip
                    title={`${
                      nutrientsDetails.find(
                        (x) => x.id === params.row.ingredientId
                      )?.problems
                    }`}
                  >
                    <span
                      style={{
                        // marginRight: "-18px",
                        color: "#DE6D00",
                      }}
                      className="material-icons"
                    >
                      report
                    </span>
                  </Tooltip>
                ) : null}
                <Typography>{params?.row?.ingredient}</Typography>
              </Box>
            );
          },
          minWidth: 300,
        },
      ];
      const dynamicColumns = results?.data?.nutrients?.map((nutrient) => {
        return {
          field: `nutrient_${nutrient.nutrientId}`,
          headerName: (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div>
                {nutrient.nutrient.abbreviation ==
                nutrient.nutrient.unitOfMeasure ? (
                  nutrient.nutrient.abbreviation
                ) : (
                  <>
                    {nutrient.nutrient.abbreviation == "Carbohydrates"
                      ? "Carbs"
                      : nutrient.nutrient.abbreviation == "NA"
                      ? "Sodium"
                      : nutrient.nutrient.abbreviation == "Fe"
                      ? "Iron"
                      : nutrient.nutrient.abbreviation}
                    <div>{nutrient.nutrient.unitOfMeasure}</div>
                  </>
                )}
              </div>
            </Box>
          ),
          headerAlign: "center",
          align: "center",
          valueFormatter: (value) => {
            return value ? value.toFixed(2) : "0.00";
          },
          minWidth: 110,
        };
      });

      return [...columns, ...dynamicColumns];
    }
    return [
      {
        field: "yield",
        headerName: "% Yield",
        minWidth: 100,
      },
      {
        field: "ingredient",
        headerName: "INGREDIENT",
        minWidth: 100,
      },
    ];
  }, [results?.data?.nutrientDetails]);

  const ingredientNutrients = useMemo(() => {
    if (results?.data?.nutrientDetails && results?.data?.ingredients) {
      let ingredientData = [];

      results?.data?.ingredients?.forEach((ingredient) => {
        let ingredientObj = {
          id: ingredient.id,
          ingredientId: ingredient.ingredientId,
          ingredient: ingredient.name,
          yield: `${ingredient.percentToIncludeInAnalysis || 0}`,
          sortOrder: ingredient.sortOrder,
          problems: 0,
        };
        results?.data?.nutrientDetails
          ?.filter((x) => x.ingredientId == ingredient.ingredientId)
          ?.forEach((detail) => {
            ingredientObj[`nutrient_${detail.nutrientId}`] =
              detail.nutrientQuantity;
          });
        results?.data?.nutrients
          ?.filter((x) => x.nutrientId == ingredient.ingredientId)
          ?.forEach((detail) => {
            ingredientObj[`nutrient_${detail.nutrientId}`] =
              detail.nutrientQuantity;
          });
        ingredientData.push(ingredientObj);
      });

      results?.data?.nutrients?.forEach((nutrient) => {
        ingredientData.forEach((ingredient) => {
          ingredient.problems = nutrient.problems;
        });
      });

      if (nutrientsColumn.length > 2) {
        let totalsRow = {
          id: 0,
          nutrient: "",
          ingredient: <strong>Total</strong>,
          problems: 0,
        };
        results?.data?.nutrients?.forEach((nutrient) => {
          totalsRow[`nutrient_${nutrient.nutrientId}`] = nutrient.quantity;
        });

        ingredientData = ingredientData.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        ingredientData.push(totalsRow);
      }

      return ingredientData;
    }

    return [];
  }, [results?.data?.nutrientDetails]);

  //  split array by half
  const splitNutrientsByMacroMicro = (array) => {
    const macros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === true
    );
    const micros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === false
    );
    const truncatedMicros = micros?.slice(0, 9);
    return [macros, showMore ? micros : truncatedMicros];
  };

  const splitNutrientDetailsByMacroMicro = (array) => {
    const macros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === true)
    );
    const micros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === false)
    );
    return [macros, micros];
  };

  const nutrientsData = splitNutrientsByMacroMicro(nutrients);

  const detailedNutrients = results?.data?.nutrientDetails?.map((item) => ({
    ...item,
    id: `${item?.nutrientId}_${item?.ingredientId}_${crypto.randomUUID()}`,
  }));
  // Group objects by sortOrder and store it in array
  const groupByNutrientId = (array) => {
    return array?.reduce((acc, item) => {
      const { sortOrder } = item;
      if (!acc[sortOrder]) {
        acc[sortOrder] = [];
      }
      acc[sortOrder].push(item);
      return acc;
    }, {});
  };
  const groupedNutrients = Object.values(
    groupByNutrientId(detailedNutrients) || []
  );

  const nutrientsDetailsData =
    splitNutrientDetailsByMacroMicro(groupedNutrients);

  const incompleteNutritionalAnalysisBanner = (
    <div
      style={{
        fontWeight: "800",
        fontSize: "20px",
        color: "#DE6D00",
        backgroundColor: "#DE6D001A",
        padding: "16px",
        margin: "8px",
        borderRadius: "4px",
      }}
    >
      Nutritional Analysis is incomplete due to missing information in some
      ingredients in this recipe.
    </div>
  );

  const hepColumns = [
    {
      field: "hepTextName",
      headerName: "HEP Category Name",
      flex: 1.8,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (params) => (
        <Box display={"flex"} justifyContent={"left"}>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {params?.row?.hepTextName}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.row?.description}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "hepFinalContributions",
      headerName: "HEP Equivalent",
      headerAlign: "center",
      valueFormatter: (value) => {
        return value?.toFixed(2);
      },
      flex: 1,
    },
  ];

  return (
    <>
      {load ? (
        <EditSkeleton />
      ) : (
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              padding: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            ></Box>
            {nutrientsDetails?.some((obj) => obj.problems.length > 0)
              ? incompleteNutritionalAnalysisBanner
              : null}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                <BaseButton
                  text={"Recalculate"}
                  isSubmitting={isSubmitting}
                  onClick={() => recalculate(recipeId)}
                  sx={{ backgroundColor: "#008385" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  color: DMStyles.Black,
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "#C8DCE3",
                }}
              >
                Details By Nutrients
              </Typography>
              <Box paddingY={1}>
                <BaseDataGrid
                  rows={ingredientNutrients}
                  columns={CreateBaseColumns(nutrientsColumn)}
                  height={"50vh"}
                  pagination={false}
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterPagination
                  // hideFooterRowCount
                  hideFooterSelectedRowCount
                  initialState={{
                    pinnedColumns: { left: ["yield", "ingredient"] },
                  }}
                  pinnedRows={{
                    bottom: ingredientNutrients.find((x) => x.id == 0)
                      ? [ingredientNutrients.find((x) => x.id == 0)]
                      : [],
                  }}
                  getCellClassName={(params) => {
                    if (params.row.id === 0) {
                      return "font-weight-bold";
                    }
                    return "";
                  }}
                  sx={{
                    "& .font-weight-bold": {
                      fontWeight: "bold",
                      backgroundColor: "#C8DCE3",
                    },
                  }}
                />
              </Box>
              <Box paddingY={1}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "700",
                    color: DMStyles.Black,
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: "#C8DCE3",
                  }}
                >
                  Healthy Eating Patterns
                </Typography>
              </Box>
              <BaseDataGrid
                rows={hepContributions || []}
                columns={hepColumns}
                isLoading={hepLoading}
                autoHeight={false}
                height={"40vh"}
                getRowId={(row) => row.hepCategoryId}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
