import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import React from "react";
import {
  CustomErrorOverlay,
  CustomNoRowsOverlay,
} from "../../admin/admin.overlays";
import "./BaseDataGrid.css";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseDataGrid.md)
 */
export default function BaseDataGrid({
  rows,
  loading,
  columns,
  error,
  defaultPageSize,
  width,
  height,
  errorMessage,
  useContainer = true,
  EmptyRowMessage,
  containerStyles,
  density = "compact",
  slots = {},
  initialState,
  // autoHeight = true,
  ...rest
}) {
  function CustomErrorMessageOverlay() {
    return <CustomErrorOverlay errorMessage={errorMessage} />;
  }
  columns?.forEach((col) => {
    col && typeof col === "object" ? (col.description = col?.headerName) : null;
  });

  function CustomNoRowsOverlayMsg() {
    return <CustomNoRowsOverlay msg={EmptyRowMessage} />;
  }

  const isError = error || !!errorMessage;

  const dataGridProps = {
    disableRowSelectionOnClick: true,
    rows: rows || [],
    // autoHeight,
    className: loading ? "loadingDataGrid" : "",
    slots: {
      noRowsOverlay: isError
        ? CustomErrorMessageOverlay
        : CustomNoRowsOverlayMsg,
      noResultsOverlay: isError
        ? CustomErrorMessageOverlay
        : CustomNoRowsOverlayMsg,
      ...slots,
    },
    slotProps: {
      loadingOverlay: {
        variant: "linear-progress",
        noRowsVariant: "skeleton",
      },
    },
    initialState: {
      pagination: {
        paginationModel: {
          pageSize: defaultPageSize || 100,
          page: 0,
        },
      },
      ...initialState,
    },
    border: 0,
    loading,
    columns: columns || [],
    headerHeight: 66,
    rowHeight: 66,
    disableSelectionOnClick: true,
    pageSizeOptions: [10, 25, 50, 100],
    pagination: true,
    cellClassName: "dataCells",
    error: error || undefined,
    density: density,
    ...rest,
  };

  return (
    <>
      {useContainer ? (
        <Box
          id="base-data-grid-container"
          {...containerStyles}
          sx={[
            {
              display: "block",
              flexGrow: 1,
              height: height || "90%",
              width: width || "100%",
              overflow: rest?.overflow || "auto",
              "& .super-app-theme--header": {
                backgroundColor: rest?.headerColor,
              },
            },
            ...(Array.isArray(containerStyles?.sx)
              ? containerStyles.sx
              : [containerStyles?.sx]),
          ]}
        >
          <DataGridPro {...dataGridProps} />
        </Box>
      ) : (
        <DataGridPro {...dataGridProps} />
      )}
    </>
  );
}
