import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton, Typography } from "@mui/material";
import { BaseButton } from "components/shared/BaseButton";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import CloseIcon from "@mui/icons-material/Close";

// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  minWidth: "600px",
};

function ApproveMyMenuModal({
  open,
  onClose,
  setChange,
  approveMenuData,
  menuId,
  weekNumber,
}) {
  // states here
  const [approveMenuSuccess, setApproveMenuSuccess] = useState(false);
  const [hepMenuMealsValidationDTO, setHepMenuMealsValidationDTO] =
    useState(false);
  const [nutritionalAnalysisForMenuDTO, setNutritionalAnalysisForMenuDTO] =
    useState(false);
  const [hepContributionsDTO, setHepContributionsDTO] = useState(false);

  const hepMenuMealsValidationDTOColumns = [
    {
      field: "menuWeek",
      headerName: "Week",
      flex: 1,
      minWidth: 20,
    },
    {
      field: "menuDayId",
      headerName: "Day",
      flex: 1,
      minWidth: 20,
    },
    {
      field: "name",
      headerName: "Meal",
      flex: 1,
      minWidth: 20,
    },
    {
      field: "entreeCount",
      headerName: "Missing Category",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) =>
        params === 0 ? "Entree" : "Appetizer, Side, or Bread",
    },
  ];

  const nutritionalAnalysisForMenuDTOColumns = [
    {
      field: "week",
      headerName: "Week",
      flex: 1,
    },
    {
      field: "dietName",
      headerName: "Diet",
      flex: 1,
    },
  ];

  const hepContributionsDTOColumns = [
    {
      field: "menuWeekId",
      headerName: "Week",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "hepTextName",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "hepServingContributionAvg",
      headerName: "Reason",
      flex: 1,
      minWidth: 20,
    },
  ];

  useEffect(() => {
    if (approveMenuData) {
      if (
        !approveMenuData.hepMenuMealsValidationDTO &&
        !approveMenuData.hepContributionsDTO &&
        !approveMenuData.nutritionalAnalysisForMenuDTO
      ) {
        setApproveMenuSuccess(true);
      }
      if (
        approveMenuData?.hepMenuMealsValidationDTO?.length > 0 &&
        !approveMenuData?.hepContributionsDTO &&
        !approveMenuData?.nutritionalAnalysisForMenuDTO
      ) {
        setHepMenuMealsValidationDTO(true);
      }
      if (
        !approveMenuData.hepMenuMealsValidationDTO &&
        !approveMenuData.hepContributionsDTO &&
        approveMenuData?.nutritionalAnalysisForMenuDTO?.length > 0
      ) {
        setNutritionalAnalysisForMenuDTO(true);
      }
      if (
        !approveMenuData.hepMenuMealsValidationDTO &&
        approveMenuData.hepContributionsDTO?.length > 0 &&
        !approveMenuData?.nutritionalAnalysisForMenuDTO
      ) {
        setHepContributionsDTO(true);
      }
    }
  }, [approveMenuData]);

  const handleClose = () => {
    onClose();
    setChange(false); // Set change to false when modal closes
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        {approveMenuSuccess ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor={"primary.main"}
            >
              <Typography variant="h6" fontWeight="bold" color="white" p={1}>
                Success
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="white" />
              </IconButton>
            </Box>
            <Box
              sx={{
                marginTop: 1.5,
                padding: 1,
              }}
            >
              <Typography variant="p">
                Your selections on your menu have been automatically approved.
              </Typography>
            </Box>
            <Box
              sx={{
                paddingX: 1,
              }}
            >
              <Typography variant="p">
                {!approveMenuData?.hepMenuHouseRecipeDTO
                  ? "A dietitian signature has been applied."
                  : "A dietitian needs to approve your house recipes and sign your menu."}
              </Typography>
            </Box>
            <Box textAlign="center" marginY={1}>
              <BaseButton text="OK" onClick={handleClose} />
            </Box>
          </>
        ) : hepMenuMealsValidationDTO ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor="#FFA500"
            >
              <Typography variant="h6" fontWeight="bold" color="white" p={1}>
                Review Menu Planner - Missing Meal Category
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="white" />
              </IconButton>
            </Box>
            <Box
              sx={{
                padding: 1,
              }}
            >
              <Typography variant="p">
                Below Alerts Require Review Before Menu Approval
              </Typography>
            </Box>
            <Box
              sx={{
                paddingX: 1,
              }}
            >
              <BaseDataGrid
                rows={
                  approveMenuData?.hepMenuMealsValidationDTO?.map(
                    (row, index) => ({
                      ...row,
                      id: index, // Ensure each row has a unique id
                    })
                  ) ?? []
                }
                columns={CreateBaseColumns(hepMenuMealsValidationDTOColumns)}
                height={"30vh"}
                getRowId={(row) => row.id} // Use the unique id for each row
              />
            </Box>
            <Box textAlign="center" marginY={1}>
              <BaseButton text="OK" onClick={handleClose} />
            </Box>
          </>
        ) : nutritionalAnalysisForMenuDTO ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor="#FFA500"
            >
              <Typography variant="h6" fontWeight="bold" color="white" p={1}>
                Review Menu Planner - Nutrition Analysis
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="white" />
              </IconButton>
            </Box>
            <Box
              sx={{
                padding: 1,
              }}
            >
              <Typography variant="p">
                Below Alerts Require Review Before Menu Approval
              </Typography>
            </Box>
            <Box
              sx={{
                paddingX: 1,
              }}
            >
              <BaseDataGrid
                rows={approveMenuData?.nutritionalAnalysisForMenuDTO ?? []}
                columns={CreateBaseColumns(
                  nutritionalAnalysisForMenuDTOColumns
                )}
                height={"30vh"}
              />
            </Box>
            <Box textAlign="center" marginY={1}>
              <BaseButton
                text="See Report"
                To={`/plateFul/nutrientAnalysisMenu/NutrientAnalysisMenuResults?menuId=${menuId}&week=${weekNumber}`}
              />
            </Box>
          </>
        ) : hepContributionsDTO ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor="#FFA500"
            >
              <Typography variant="h6" fontWeight="bold" color="white" p={1}>
                Review Menu Planner - Healthy Eating Pattern
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="white" />
              </IconButton>
            </Box>
            <Box
              sx={{
                padding: 1,
              }}
            >
              <Typography variant="p">
                Below Alerts Require Review Before Menu Approval
              </Typography>
            </Box>
            <Box
              sx={{
                paddingX: 1,
              }}
            >
              <BaseDataGrid
                rows={approveMenuData?.hepContributionsDTO ?? []}
                columns={CreateBaseColumns(hepContributionsDTOColumns)}
                height={"30vh"}
              />
            </Box>
            <Box textAlign="center" marginY={1}>
              <BaseButton
                text="See Report"
                To={`/plateFul/hep/HepResults?menuId=${menuId}&week=${weekNumber}`}
              />
            </Box>
          </>
        ) : null}
      </Box>
    </Modal>
  );
}

export default ApproveMyMenuModal;
