import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetNutrientAnalysisDetailsForMenuQuery,
  useGetNutrientAnalysisMenuContributionsQuery,
} from "../../../../store/apis/MenuApis";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import { EditSkeleton } from "components/admin/admin.overlays";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function NutrientAnalysisMenuResults() {
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get("menuId");
  const week = searchParams.get("week");
  const facilityId = useSelector(selectFacilityId);

  const {
    data: NutrientAnalysisMenuData,
    isFetching: NutrientAnalysisMenuLoading,
    isSuccess: NutrientAnalysisMenuSuccess,
  } = useGetNutrientAnalysisMenuContributionsQuery(
    { menuId: menuId, facilityId: facilityId, week: week },
    {
      refetchOnMountOrArgChange: true,
      skip: !menuId, // Skip the initial request
    }
  );

  const {
    data: NutrientAnalysisDetailsForMenuData,
    isFetching: NutrientAnalysisDetailsForMenuLoading,
    isSuccess: NutrientAnalysisDetailsForMenuSuccess,
  } = useGetNutrientAnalysisDetailsForMenuQuery(
    { menuId: menuId, facilityId: facilityId, week: week },
    {
      refetchOnMountOrArgChange: true,
      skip: !menuId, // Skip the initial request
    }
  );

  const nutrientAnalysisMenuColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 250,
    },
    {
      field: "week",
      headerName: "Week",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 5,
      rowSpanValueGetter: () => null,
    },
    {
      field: "kCalQuantity",
      headerName: "Calories",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.kCalRange}>
          <Typography
            sx={{
              color: !row.kCalWithinRange ? "red" : "",
              cursor: "pointer",
            }}
          >
            {row.kCalQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "proteinQuantity",
      headerName: "Protein",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.proteinRange}>
          <Typography
            sx={{
              color: !row.proteinWithinRange ? "red" : "",
              cursor: "pointer",
            }}
          >
            {row.proteinQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "carbohydrateQuantity",
      headerName: "Carbs",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.carbohydrateRange}>
          <Typography
            sx={{
              color: row.carbohydrateWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.carbohydrateQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "fatQuantity",
      headerName: "Fat",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.fatRange}>
          <Typography
            sx={{
              color: row.fatWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.fatQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "fiberQuantity",
      headerName: "Fiber",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.fiberRange}>
          <Typography
            sx={{
              color: row.fiberWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.fiberQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "cholesterolQuantity",
      headerName: "Cholesterol",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.cholesterolRange}>
          <Typography
            sx={{
              color: row.cholesterolWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.cholesterolQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "calciumQuantity",
      headerName: "Calcium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.calciumRange}>
          <Typography
            sx={{
              color: row.calciumWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.calciumQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "sodiumQuantity",
      headerName: "Sodium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.sodiumRange}>
          <Typography
            sx={{
              color: row.sodiumWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.sodiumQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "potassiumQuantity",
      headerName: "Potassium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.potassiumRange}>
          <Typography
            sx={{
              color: row.potassiumWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.potassiumQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "phosphorusQuantity",
      headerName: "Phosphorus",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.phosphorusRange}>
          <Typography
            sx={{
              color: row.phosphorusWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.phosphorusQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "ironQuantity",
      headerName: "Iron",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.ironRange}>
          <Typography
            sx={{
              color: row.ironWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.ironQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminAQuantity",
      headerName: "Vit A",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.vitaminARange}>
          <Typography
            sx={{
              color: row.vitaminAWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.vitaminAQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "thiamineQuantity",
      headerName: "Thiamin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.thiamineRange}>
          <Typography
            sx={{
              color: row.thiamineWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.thiamineQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "folateQuantity",
      headerName: "Folate",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.folateRange}>
          <Typography
            sx={{
              color: row.folateWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.folateQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "niacinQuantity",
      headerName: "Niacin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.niacinRange}>
          <Typography
            sx={{
              color: row.niacinWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.niacinQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminCQuantity",
      headerName: "Vit C",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.vitaminCRange}>
          <Typography
            sx={{
              color: row.vitaminCWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.vitaminCQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminB6Quantity",
      headerName: "Vit B6",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.vitaminB6Range}>
          <Typography
            sx={{
              color: row.vitaminB6WithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.vitaminB6Quantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "riboflavinQuantity",
      headerName: "Riboflavin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.riboflavinRange}>
          <Typography
            sx={{
              color: row.riboflavinWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.riboflavinQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
  ];

  const nutrientAnalysisMenuDetailsColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 250,
      cellClassName: "z-index-high",
    },
    {
      field: "week",
      headerName: "Week",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 5,
      rowSpanValueGetter: () => null,
    },
    {
      field: "day",
      headerName: "Day",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 5,
      rowSpanValueGetter: () => null,
    },
    {
      field: "kCalQuantity",
      headerName: "Calories",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.kCalRange}>
          <Typography
            sx={{
              color: !row.kCalWithinRange ? "red" : "",
              cursor: "pointer",
            }}
          >
            {row.kCalQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "proteinQuantity",
      headerName: "Protein",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.proteinRange}>
          <Typography
            sx={{
              color: !row.proteinWithinRange ? "red" : "",
              cursor: "pointer",
            }}
          >
            {row.proteinQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "carbohydrateQuantity",
      headerName: "Carbs",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.carbohydrateRange}>
          <Typography
            sx={{
              color: row.carbohydrateWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.carbohydrateQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "fatQuantity",
      headerName: "Fat",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.fatRange}>
          <Typography
            sx={{
              color: row.fatWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.fatQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "fiberQuantity",
      headerName: "Fiber",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.fiberRange}>
          <Typography
            sx={{
              color: row.fiberWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.fiberQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "cholesterolQuantity",
      headerName: "Cholesterol",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.cholesterolRange}>
          <Typography
            sx={{
              color: row.cholesterolWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.cholesterolQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "calciumQuantity",
      headerName: "Calcium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.calciumRange}>
          <Typography
            sx={{
              color: row.calciumWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.calciumQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "sodiumQuantity",
      headerName: "Sodium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.sodiumRange}>
          <Typography
            sx={{
              color: row.sodiumWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.sodiumQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "potassiumQuantity",
      headerName: "Potassium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.potassiumRange}>
          <Typography
            sx={{
              color: row.potassiumWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.potassiumQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "phosphorusQuantity",
      headerName: "Phosphorus",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.phosphorusRange}>
          <Typography
            sx={{
              color: row.phosphorusWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.phosphorusQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "ironQuantity",
      headerName: "Iron",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.ironRange}>
          <Typography
            sx={{
              color: row.ironWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.ironQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminAQuantity",
      headerName: "Vit A",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.vitaminARange}>
          <Typography
            sx={{
              color: row.vitaminAWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.vitaminAQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "thiamineQuantity",
      headerName: "Thiamin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.thiamineRange}>
          <Typography
            sx={{
              color: row.thiamineWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.thiamineQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "folateQuantity",
      headerName: "Folate",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.folateRange}>
          <Typography
            sx={{
              color: row.folateWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.folateQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "niacinQuantity",
      headerName: "Niacin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.niacinRange}>
          <Typography
            sx={{
              color: row.niacinWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.niacinQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminCQuantity",
      headerName: "Vit C",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.vitaminCRange}>
          <Typography
            sx={{
              color: row.vitaminCWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.vitaminCQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminB6Quantity",
      headerName: "Vit B6",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.vitaminB6Range}>
          <Typography
            sx={{
              color: row.vitaminB6WithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.vitaminB6Quantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
    {
      field: "riboflavinQuantity",
      headerName: "Riboflavin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      renderCell: ({ row }) => (
        <Tooltip title={row.riboflavinRange}>
          <Typography
            sx={{
              color: row.riboflavinWithinRange ? "" : "red",
              cursor: "pointer",
            }}
          >
            {row.riboflavinQuantity}
          </Typography>
        </Tooltip>
      ),
      rowSpanValueGetter: () => null,
    },
  ];

  if (!NutrientAnalysisMenuSuccess && !NutrientAnalysisDetailsForMenuSuccess) {
    return (
      <Box>
        <EditSkeleton />
      </Box>
    );
  }

  return (
    <>
      <Box padding={1}>
        <Box>
          <Typography variant="h6">
            <Typography
              variant="h6"
              component="span"
              sx={{ fontWeight: "bold" }}
            >
              Nutrient Analysis Calculations Week:{" "}
              {NutrientAnalysisMenuData?.[0]?.week}
            </Typography>
            {/* {rowData[0]?.menuWeekId} */}
          </Typography>
        </Box>
        <Box>
          <Accordion defaultExpanded marginBottom={1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                bgcolor: "primary.main",
                borderRadius: "10px",
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white !important",
                }}
              >
                Weekly Average
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  height: "auto",
                  width: "100%",
                  "& .MuiInputBase-root": {
                    "& input[type=number]": {
                      textAlign: "right",
                    },
                  },
                  "& .super-app-theme--NotVendorItem": {
                    backgroundColor: "#a8000038",
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                      backgroundColor: "#a8000038",
                    },
                    ":hover": {
                      backgroundColor: "rgba(168, 0, 0, 0.22) !important",
                    },
                  },
                }}
              >
                <BaseDataGrid
                  rows={NutrientAnalysisMenuData || []}
                  columns={CreateBaseColumns(nutrientAnalysisMenuColumns)}
                  unstable_rowSpanning={true}
                  loading={NutrientAnalysisMenuLoading}
                  getRowHeight={() => "auto"}
                  height={"100%"}
                  width={"100%"}
                  EmptyRowMessage={"No Calculations found"}
                  autoHeight={true}
                  pinnedColumns={{ left: ["dietName"] }}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                  }}
                  getRowClassName={(params) =>
                    params.row.withinRange == undefined
                      ? ""
                      : params?.row?.withinRange == true
                      ? "super-app-theme--NotBackfeedItem"
                      : "super-app-theme--NotVendorItem"
                  }
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion marginBottom={0.5}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                bgcolor: "primary.main",
                borderRadius: "10px",
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white !important",
                }}
              >
                Daily Breakdown
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  height: "auto",
                  width: "100%",
                  "& .MuiInputBase-root": {
                    "& input[type=number]": {
                      textAlign: "right",
                    },
                  },
                  "& .super-app-theme--NotVendorItem": {
                    backgroundColor: "#a8000038",
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                      backgroundColor: "#a8000038",
                    },
                    ":hover": {
                      backgroundColor: "rgba(168, 0, 0, 0.22) !important",
                    },
                  },
                }}
              >
                <BaseDataGrid
                  rows={NutrientAnalysisDetailsForMenuData || []}
                  columns={CreateBaseColumns(
                    nutrientAnalysisMenuDetailsColumns
                  )}
                  unstable_rowSpanning={true}
                  loading={NutrientAnalysisDetailsForMenuLoading}
                  height={"100%"}
                  width={"100%"}
                  EmptyRowMessage={"No Calculations found"}
                  autoHeight={true}
                  pinnedColumns={{ left: ["dietName"] }}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                  }}
                  getRowClassName={(params) =>
                    params.row.withinRange == undefined
                      ? ""
                      : params?.row?.withinRange == true
                      ? "super-app-theme--NotBackfeedItem"
                      : "super-app-theme--NotVendorItem"
                  }
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}
