import {
  mealDate,
  mealHeaderName,
  offset6,
  printDay,
  printMealId,
  printSimplified,
  useLegalSize,
  printRecipeTagIcons,
  CustomformatTypeId,
} from "../form.constants";

export const CustomPostedMenuForms = {
  Category: true,
  DayForm: [
    mealDate,
    offset6,
    printDay,
    offset6,
    printMealId,
    offset6,
    mealHeaderName,
    offset6,
    printSimplified,
    offset6,
    useLegalSize,
    offset6,
    printRecipeTagIcons,
    offset6,
  ],
  Report: [CustomformatTypeId],
};
