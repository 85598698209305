import dayjs from "dayjs";
import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  RESIDENTS,
  DIET,
  TABLESIDE_HISTORIES,
  DINING_AREAS,
  RESIDENT_CHANGES,
  DINING_TABLES,
  RESIDENT_ALLERGIES,
  RESIDENT_BEVERAGES,
  RESIDENT_FOOD_TYPES,
  RESIDENT_MEAL_EXTRAS,
  RESIDENT_NOTES,
  RESIDENT_ADAPTIVE_EQUIPMENTS,
  RESIDENT_THICKENED_LIQUIDS,
  RESIDENT_SNACKS,
  RESIDENT_WEIGHT,
  MEAL_BY_ORDERS,
} = API_ENDPOINTS;

export const ResidentAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Resident
    getResident: builder.query({
      query: ({ searchTerm, facilityId, filterNPO, filterNoTrayCards }) =>
        `/${RESIDENTS}?FacilityId=${facilityId}&searchText=${searchTerm}&filterNoTrayCards=${filterNoTrayCards}&filterNPO=${filterNPO}`,
      transformResponse: (response) => response,
      providesTags: (response) => {
        return response
          ? [
              ...response.data.map(({ id }) => ({ type: RESIDENTS, id })),
              RESIDENTS,
            ]
          : [RESIDENTS];
      },
    }),
    getResidentSelectList: builder.query({
      query: ({ facilityId }) => `/${RESIDENTS}?FacilityId=${facilityId}`,
      transformResponse: (response) => {
        const baseResponse = response?.data?.map((res) => {
          return {
            id: res.id,
            label: `${res.lastName}, ${res.firstName}`,
          };
        });
        return [{ id: null, label: "All Residents" }, ...baseResponse];
      },
      keepUnusedDataFor: 0,
    }),
    getResidentDetail: builder.query({
      query: ({ residentId, facilityId }) =>
        `/${RESIDENTS}/${facilityId}/${residentId}`,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENTS, id: response.id }, RESIDENTS]
          : [RESIDENTS],
    }),
    addResident: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENTS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RESIDENTS, id: result?.id }],
    }),
    updateResident: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RESIDENTS, id: result?.id }],
    }),
    deleteResident: builder.mutation({
      query: (residentId) => ({
        url: `/${RESIDENTS}/${residentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: RESIDENTS, id: result?.id }],
    }),
    getTablesideHistory: builder.query({
      query: ({ residentId }) =>
        `/${TABLESIDE_HISTORIES}?residentId=${residentId}`,
      transformResponse: (response) => response.data,
      providesTags: (response) =>
        response
          ? [
              { type: TABLESIDE_HISTORIES, id: response.id },
              TABLESIDE_HISTORIES,
            ]
          : [TABLESIDE_HISTORIES],
    }),
    //add Residents Selects required
    residentDietSelect: builder.query({
      query: ({ facilityId, isTexture }) =>
        `/${DIET}/GetFacilityDiets?facilityId=${facilityId}&isTexture=${isTexture}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.dietId,
            label: res.dietName,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    residentDiningareaSelect: builder.query({
      query: ({ facilityId }) => `/${DINING_AREAS}?facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    residentDiningTableSelect: builder.query({
      query: ({ diningAreaId }) =>
        `/${DINING_TABLES}?diningAreaId=${diningAreaId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    residentDiningareaSelectAll: builder.query({
      query: ({ facilityId }) => `/${DINING_AREAS}?facilityId=${facilityId}`,
      transformResponse: (response) => {
        const baseResponse = response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        });
        return [{ id: null, label: "All Dining Areas" }, ...baseResponse];
      },
      keepUnusedDataFor: 0,
    }),
    // ResidentChange for change history for entire facility
    getResidentChangeByFacility: builder.query({
      query: ({ facilityId }) => `/${RESIDENT_CHANGES}/${facilityId}`,
      transformResponse: (response) => response?.data,
    }),
    // ResidentChange for change history
    getResidentChangeHistory: builder.query({
      query: ({ residentId }) =>
        `/${RESIDENT_CHANGES}?residentId=${residentId}`,
      transformResponse: (response) => response?.data,
    }),

    // ReservedList for mealCard
    getReservedList: builder.query({
      query: ({ facilityId, searchText }) =>
        `/${RESIDENTS}/GetReserveList?facilityId=${facilityId}&searchText=${searchText}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: RESIDENTS, id })), RESIDENTS]
          : [RESIDENTS];
      },
    }),
    moveResidentToReserve: builder.mutation({
      query: (residentId) => ({
        url: `/${RESIDENTS}/MoveToArchive?residentId=${residentId}`,
        method: "POST",
      }),
      invalidatesTags: (result) => [{ type: RESIDENTS, id: result?.id }],
    }),
    moveResidentToActive: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENTS}/MoveToActive`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RESIDENTS, id: result?.id }],
    }),
    DeleteReserved: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENTS}/DeleteReserved`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RESIDENTS, id: result?.id }],
    }),
    getResidentProfileReport: builder.query({
      query: (residentId) =>
        `/${RESIDENTS}/ProfileReport?residentId=${residentId}`,
      keepUnusedDataFor: 120,
      transformResponse: (response) => response?.data,
    }),
    getDiningAreaList: builder.query({
      query: ({ facilityId }) => `/${DINING_AREAS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: DINING_AREAS, id })),
              DINING_AREAS,
            ]
          : [DINING_AREAS];
      },
    }),
    getDiningAreaById: builder.query({
      query: (id) => `/${DINING_AREAS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: DINING_AREAS, id: response.id }, DINING_AREAS]
          : [DINING_AREAS],
    }),
    addDiningArea: builder.mutation({
      query: (data) => ({
        url: `/${DINING_AREAS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DINING_AREAS, id: result?.id }],
    }),
    updateDiningArea: builder.mutation({
      query: (data) => ({
        url: `/${DINING_AREAS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DINING_AREAS, id: result.id }],
    }),
    deleteDiningArea: builder.mutation({
      query: (id) => ({
        url: `/${DINING_AREAS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: DINING_AREAS, id: arg.id },
      ],
    }),

    getDiningAreaTableList: builder.query({
      query: ({ diningAreaId }) =>
        `/${DINING_TABLES}?diningAreaId=${diningAreaId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: DINING_TABLES, id })),
              DINING_TABLES,
            ]
          : [DINING_TABLES];
      },
    }),
    getDiningAreaTableById: builder.query({
      query: (id) => `/${DINING_TABLES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: DINING_TABLES, id: response.id }, DINING_TABLES]
          : [DINING_TABLES],
    }),
    addDiningAreaTable: builder.mutation({
      query: (data) => ({
        url: `/${DINING_TABLES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DINING_TABLES, id: result?.id }],
    }),
    updateDiningAreaTable: builder.mutation({
      query: (data) => ({
        url: `/${DINING_TABLES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DINING_TABLES, id: result.id }],
    }),
    deleteDiningAreaTable: builder.mutation({
      query: (id) => ({
        url: `/${DINING_TABLES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: DINING_TABLES, id: arg.id },
      ],
    }),
    getResidentNotes: builder.query({
      query: (ResidentId) => `/${RESIDENT_NOTES}?residentId=${ResidentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RESIDENT_NOTES, id })),
              RESIDENT_NOTES,
            ]
          : [RESIDENT_NOTES];
      },
    }),
    getResidentNoteById: builder.query({
      query: (id) => `/${RESIDENT_NOTES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_NOTES, id: response.id }]
          : [RESIDENT_NOTES],
    }),
    addResidentNote: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_NOTES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RESIDENT_NOTES],
    }),
    updateResidentNote: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_NOTES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_NOTES, id: arg.id },
      ],
    }),
    deleteResidentNote: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_NOTES}/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_NOTES, id: arg.id },
      ],
    }),
    residentBeverages: builder.query({
      query: (id) => `/${RESIDENT_BEVERAGES}?residentId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RESIDENT_BEVERAGES, id })),
              RESIDENT_BEVERAGES,
            ]
          : [RESIDENT_BEVERAGES];
      },
    }),
    residentBeveragesById: builder.query({
      query: (id) => `/${RESIDENT_BEVERAGES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_BEVERAGES, id: response.id }]
          : [RESIDENT_BEVERAGES],
    }),
    addResidentBeverage: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_BEVERAGES}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_BEVERAGES],
    }),
    updateResidentBeverage: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_BEVERAGES}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_BEVERAGES],
    }),
    deleteResidentBeverage: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_BEVERAGES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RESIDENT_BEVERAGES],
    }),
    //Resident allergy apis of mealCard
    getResidentAllergy: builder.query({
      query: (residentId) => `/${RESIDENT_ALLERGIES}?residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RESIDENT_ALLERGIES,
                id,
              })),
              RESIDENT_ALLERGIES,
            ]
          : [RESIDENT_ALLERGIES];
      },
    }),
    getResidentAllergyById: builder.query({
      query: (id) => `/${RESIDENT_ALLERGIES}/GetResidentAllergy?id=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_ALLERGIES, id: response.id }]
          : [RESIDENT_ALLERGIES],
    }),
    addResidentAllergy: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ALLERGIES}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_ALLERGIES],
    }),
    updateResidentAllergy: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ALLERGIES}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_ALLERGIES, id: arg.id },
      ],
    }),
    deleteResidentAllergy: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_ALLERGIES}/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_ALLERGIES, id: arg.id },
      ],
    }),
    getResidentAdaptiveEquipmentList: builder.query({
      query: (ResidentId) =>
        `/${RESIDENT_ADAPTIVE_EQUIPMENTS}?residentId=${ResidentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RESIDENT_ADAPTIVE_EQUIPMENTS,
                id,
              })),
              RESIDENT_ADAPTIVE_EQUIPMENTS,
            ]
          : [RESIDENT_ADAPTIVE_EQUIPMENTS];
      },
    }),
    getResidentAdaptiveEquipmentById: builder.query({
      query: (id) => `/${RESIDENT_ADAPTIVE_EQUIPMENTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: RESIDENT_ADAPTIVE_EQUIPMENTS, id: response.id },
              RESIDENT_ADAPTIVE_EQUIPMENTS,
            ]
          : [RESIDENT_ADAPTIVE_EQUIPMENTS],
    }),
    addResidentAdaptiveEquipment: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ADAPTIVE_EQUIPMENTS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_ADAPTIVE_EQUIPMENTS],
    }),

    updateResidentAdaptiveEquipment: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ADAPTIVE_EQUIPMENTS}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_ADAPTIVE_EQUIPMENTS, id: arg.id },
      ],
    }),
    deleteResidentAdaptiveEquipment: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_ADAPTIVE_EQUIPMENTS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_ADAPTIVE_EQUIPMENTS, id: arg.id },
      ],
    }),
    residentFoodTypes: builder.query({
      query: ({ id, like }) =>
        `/${RESIDENT_FOOD_TYPES}?residentId=${id}&like=${like}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: RESIDENT_FOOD_TYPES, id }],
    }),
    residentFoodTypesById: builder.query({
      query: (id) => `/${RESIDENT_FOOD_TYPES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_FOOD_TYPES, id: response.id }]
          : [RESIDENT_FOOD_TYPES],
    }),
    addResidentFoodType: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_FOOD_TYPES}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_FOOD_TYPES, id: arg.id },
      ],
    }),
    updateResidentFoodType: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_FOOD_TYPES}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_FOOD_TYPES],

      // invalidatesTags: (result, error, arg) => [
      //   { type: RESIDENT_FOOD_TYPES, id: arg.id },
      // ],
    }),
    deleteResidentFoodType: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_FOOD_TYPES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_FOOD_TYPES, id: arg.id },
      ],
    }),
    //Resident Liquid consistency apis of mealCard
    getResidentLiquid: builder.query({
      query: (residentId) =>
        `/${RESIDENT_THICKENED_LIQUIDS}?residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RESIDENT_THICKENED_LIQUIDS,
                id,
              })),
              RESIDENT_THICKENED_LIQUIDS,
            ]
          : [RESIDENT_THICKENED_LIQUIDS];
      },
    }),
    addResidentLiquid: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_THICKENED_LIQUIDS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_THICKENED_LIQUIDS],
    }),
    updateResidentLiquid: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_THICKENED_LIQUIDS}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_THICKENED_LIQUIDS, id: arg.id },
      ],
    }),
    deleteResidentLiquid: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_THICKENED_LIQUIDS}/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_THICKENED_LIQUIDS, id: arg.id },
      ],
    }),
    //Resident Weight Histpry apis of mealCard
    getResidentWeight: builder.query({
      query: (residentId) => `/${RESIDENT_WEIGHT}?residentId=${residentId}`,
      transformResponse: (response) =>
        response?.data?.residentWeights?.sort(
          (a, b) => Date.parse(b.weightDate) - Date.parse(a.weightDate)
        ),
      // providesTags: (response) =>
      //   response
      //     ? [{ type: RESIDENT_WEIGHT, id: response.id }, RESIDENT_WEIGHT]
      //     : [RESIDENT_WEIGHT],
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RESIDENT_WEIGHT,
                id,
              })),
              RESIDENT_WEIGHT,
            ]
          : [RESIDENT_WEIGHT];
      },
    }),
    addResidentWeight: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_WEIGHT}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RESIDENT_WEIGHT],
    }),
    updateResidentWeight: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_WEIGHT}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_WEIGHT, id: arg.id },
      ],
    }),
    deleteResidentWeight: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_WEIGHT}/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_WEIGHT, id: arg.id },
      ],
    }),
    //Resident Snacks apis of mealCard
    getResidentSnackList: builder.query({
      query: (residentId) => `/${RESIDENT_SNACKS}?residentId=${residentId}`,
      transformResponse: (response) => response?.data?.residentSnacksList,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RESIDENT_SNACKS,
                id,
              })),
              RESIDENT_SNACKS,
            ]
          : [RESIDENT_SNACKS];
      },
    }),
    getResidentSnack: builder.query({
      query: (snackId) => `/${RESIDENT_SNACKS}/${snackId}`,
      transformResponse: (response) => response?.data?.residentSnack,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_SNACKS, id: response.id }]
          : [RESIDENT_SNACKS],
    }),
    addResidentSnack: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_SNACKS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_SNACKS],
    }),
    updateResidentSnack: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_SNACKS}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_SNACKS, id: arg.id },
      ],
    }),
    deleteResidentSnack: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_SNACKS}/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: RESIDENT_SNACKS, id: arg.id },
      ],
    }),
    getResidentTablesideOrders: builder.query({
      query: (residentId) => `/${TABLESIDE_HISTORIES}?residentId=${residentId}`,
      transformResponse: (response) =>
        response?.data?.map((x) => {
          x["id"] = x.tableSideOrderItemId;
          return x;
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: TABLESIDE_HISTORIES,
                id,
              })),
              TABLESIDE_HISTORIES,
            ]
          : [TABLESIDE_HISTORIES];
      },
    }),
    deleteResidentTablesideOrders: builder.mutation({
      query: (id) => ({
        url: `/${TABLESIDE_HISTORIES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TABLESIDE_HISTORIES, id: arg.id },
      ],
    }),
    addResidentTableSideOrder: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDE_HISTORIES}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: TABLESIDE_HISTORIES, id: arg.id },
      ],
    }),
    updateResidentTableSideOrder: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDE_HISTORIES}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: (result, error, arg) => [
        { type: TABLESIDE_HISTORIES, id: arg.id },
      ],
    }),
    getAvailableTablesideOrders: builder.query({
      query: (residentId) =>
        `/${TABLESIDE_HISTORIES}/GetAvailable?residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: TABLESIDE_HISTORIES,
                id,
              })),
              TABLESIDE_HISTORIES,
            ]
          : [TABLESIDE_HISTORIES];
      },
    }),
    getAvailableTablesideOrdersSelectBox: builder.query({
      query: (residentId) =>
        `/${TABLESIDE_HISTORIES}/GetAvailable?residentId=${residentId}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({
          id: item?.id,
          label: item?.mealName,
          description: dayjs(item?.diningDate).format("MM-DD-YYYY hh:mm A"),
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: TABLESIDE_HISTORIES,
                id,
              })),
              TABLESIDE_HISTORIES,
            ]
          : [TABLESIDE_HISTORIES];
      },
    }),
    getMealByOrder: builder.query({
      query: ({ residentId, setupOrderId }) =>
        `/${TABLESIDE_HISTORIES}/GetMealByOrder?residentId=${residentId}&setupOrderId=${setupOrderId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: MEAL_BY_ORDERS,
                id,
              })),
              MEAL_BY_ORDERS,
            ]
          : [MEAL_BY_ORDERS];
      },
    }),
    getMealByOrderSelectBox: builder.query({
      query: ({ residentId, setupOrderId }) =>
        `/${TABLESIDE_HISTORIES}/GetMealByOrder?residentId=${residentId}&setupOrderId=${setupOrderId}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({
          id: item?.recipeID,
          label: item?.recipeName,
          description: `${item?.menuCategory} ${item?.portionSize}`,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: MEAL_BY_ORDERS,
                id,
              })),
              MEAL_BY_ORDERS,
            ]
          : [MEAL_BY_ORDERS];
      },
    }),
    addResidentPhoto: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENTS}/ResidentPhoto`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response?.data,
    }),
    deleteResidentPhoto: builder.mutation({
      query: (residentId) => ({
        url: `/${RESIDENTS}/ResidentPhoto/${residentId}`,
        method: "DELETE",
      }),
    }),
    residentMealExtras: builder.query({
      query: (id) => `/${RESIDENT_MEAL_EXTRAS}?residentId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: [RESIDENT_MEAL_EXTRAS],
      keepUnusedDataFor: 0,
    }),
    residentMealExtrasById: builder.query({
      query: (id) => `/${RESIDENT_MEAL_EXTRAS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_MEAL_EXTRAS, id: response.id }]
          : [RESIDENT_MEAL_EXTRAS],
    }),
    residentDietMealExtrasById: builder.query({
      query: ({ residentId, selectedMealExtraId }) =>
        `/${RESIDENT_MEAL_EXTRAS}/MealExtraAllowed?residentId=${residentId}&mealExtraId=${selectedMealExtraId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_MEAL_EXTRAS, id: response.id }]
          : [RESIDENT_MEAL_EXTRAS],
    }),
    addResidentMealExtras: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_MEAL_EXTRAS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_MEAL_EXTRAS],
    }),
    updateResidentMealExtras: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_MEAL_EXTRAS}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RESIDENT_MEAL_EXTRAS],
    }),
    deleteResidentMealExtras: builder.mutation({
      query: (id) => ({
        url: `/${RESIDENT_MEAL_EXTRAS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RESIDENT_MEAL_EXTRAS],
    }),
  }),
});
// export hooks for calling endpoints
export const {
  useGetResidentQuery,
  useGetResidentSelectListQuery,
  useGetResidentDetailQuery,
  useAddResidentMutation,
  useUpdateResidentMutation,
  useDeleteResidentMutation,
  useGetTablesideHistoryQuery,
  useResidentDietSelectQuery,
  useResidentDiningareaSelectQuery,
  useResidentDiningTableSelectQuery,
  useResidentDiningareaSelectAllQuery,
  useGetResidentChangeByFacilityQuery,
  useGetResidentChangeHistoryQuery,
  useGetReservedListQuery,
  useMoveResidentToReserveMutation,
  useMoveResidentToActiveMutation,
  useGetResidentProfileReportQuery,
  useGetDiningAreaListQuery,
  useGetDiningAreaByIdQuery,
  useAddDiningAreaMutation,
  useUpdateDiningAreaMutation,
  useDeleteDiningAreaMutation,
  useDeleteReservedMutation,
  useGetDiningAreaTableListQuery,
  useGetDiningAreaTableByIdQuery,
  useAddDiningAreaTableMutation,
  useUpdateDiningAreaTableMutation,
  useDeleteDiningAreaTableMutation,
  useGetResidentNotesQuery,
  useGetResidentNoteByIdQuery,
  useAddResidentNoteMutation,
  useUpdateResidentNoteMutation,
  useDeleteResidentNoteMutation,
  useResidentBeveragesQuery,
  useResidentBeveragesByIdQuery,
  useAddResidentBeverageMutation,
  useUpdateResidentBeverageMutation,
  useDeleteResidentBeverageMutation,
  useGetResidentAllergyQuery,
  useGetResidentAllergyByIdQuery,
  useAddResidentAllergyMutation,
  useUpdateResidentAllergyMutation,
  useDeleteResidentAllergyMutation,
  useGetResidentAdaptiveEquipmentListQuery,
  useGetResidentAdaptiveEquipmentByIdQuery,
  useAddResidentAdaptiveEquipmentMutation,
  useUpdateResidentAdaptiveEquipmentMutation,
  useDeleteResidentAdaptiveEquipmentMutation,
  useResidentFoodTypesQuery,
  useResidentFoodTypesByIdQuery,
  useAddResidentFoodTypeMutation,
  useUpdateResidentFoodTypeMutation,
  useDeleteResidentFoodTypeMutation,
  useGetResidentLiquidQuery,
  useAddResidentLiquidMutation,
  useUpdateResidentLiquidMutation,
  useDeleteResidentLiquidMutation,
  useGetResidentWeightQuery,
  useAddResidentWeightMutation,
  useUpdateResidentWeightMutation,
  useDeleteResidentWeightMutation,
  useGetResidentSnackListQuery,
  useGetResidentSnackQuery,
  useAddResidentSnackMutation,
  useUpdateResidentSnackMutation,
  useDeleteResidentSnackMutation,
  useGetResidentTablesideOrdersQuery,
  useDeleteResidentTablesideOrdersMutation,
  useAddResidentTableSideOrderMutation,
  useUpdateResidentTableSideOrderMutation,
  useGetAvailableTablesideOrdersQuery,
  useGetAvailableTablesideOrdersSelectBoxQuery,
  useGetMealByOrderSelectBoxQuery,
  useGetMealByOrderQuery,
  useAddResidentPhotoMutation,
  useDeleteResidentPhotoMutation,
  useResidentMealExtrasQuery,
  useResidentMealExtrasByIdQuery,
  useResidentDietMealExtrasByIdQuery,
  useAddResidentMealExtrasMutation,
  useUpdateResidentMealExtrasMutation,
  useDeleteResidentMealExtrasMutation,
} = ResidentAPIs;
