import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import {
  MAX_LENGTH_VALIDATION,
  POSITIVE_INTEGER_NUMBER,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const addDiningAreaFields = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Dining Area Name",
      placeholder: "Document Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Dining Room Name"),
      maxLength: MAX_LENGTH_VALIDATION("Dining Room Name", 50),
    },
    size: 7,
  },
  {
    props: {
      name: "sortOrder",
      id: "sortOrder",
      label: "Sort Order",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
      pattern: POSITIVE_INTEGER_NUMBER(),
      maxLength: MAX_LENGTH_VALIDATION("Sort Order", 2),
    },
    size: 7,
  },
  {
    props: {
      name: "includeInTableside",
      id: "includeInTableside",
      label: "Include in TableSide Program",
      defaultValue: true,
    },
    type: FORM_TYPES.Checkbox,
    size: 7,
  },
];
