import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { YARDI_MANAGEMENTS } = API_ENDPOINTS;

export const YardiManagementApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getYardiResidentList: builder.query({
      query: ({ facilityId, ehrId }) =>
        `/${YARDI_MANAGEMENTS}?facilityId=${facilityId}&ehrId=${ehrId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 5,
    }),
    updateYardiResidentList: builder.mutation({
      query: ({ facilityId, ehrId, data }) => ({
        url: `/${YARDI_MANAGEMENTS}?facilityId=${facilityId}&ehrId=${ehrId}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useGetYardiResidentListQuery, useUpdateYardiResidentListMutation } =
  YardiManagementApi;
