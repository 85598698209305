import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { REQUIRED_ERROR } from "utils/errorMessages";

export const UNIT_CONVERSION_LIST = [
  {
    props: {
      label: "Quantity 1",
      placeholder: "Quantity 1",
      id: "quantity",
      name: "quantity",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Quantity 1"),
    },
    size: 6,
  },
  {
    props: {
      label: "Unit 1",
      placeholder: "Unit 1",
      id: "unitId",
      name: "unitId",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Unit 1"),
    },

    type: FORM_TYPES.SelectAPI,
    endpoint: "getUnits",
    size: 6,
  },
  {
    props: {
      label: "Quantity 2",
      placeholder: "Quantity 2",
      id: "convertToQuantity",
      name: "convertToQuantity",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Quantity 2"),
    },

    size: 6,
  },
  {
    props: {
      label: "Unit 2",
      placeholder: "Unit 2",
      id: "convertToUnitId",
      name: "convertToUnitId",
    },
    validationProps: {
      required: REQUIRED_ERROR("Unit 2"),
    },

    type: FORM_TYPES.SelectAPI,
    endpoint: "getUnits",
    size: 6,
  },
];
