import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useGetVisitDetailsQuery } from "../../../store/apis/ConsultantApis";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { theme } from "styles/theme";
import BaseBanner from "components/shared/baseBanner/BaseBanner";

function HeaderBox({
  ScreensArray,
  visitPlanId,
  observerTextCheck,
  currentFacility,
  dateofConsult,
  hasVisitId = true,
}) {
  const { data: visitData } = useGetVisitDetailsQuery(visitPlanId, {
    skip: !hasVisitId,
  });
  const { dateOfVisit, community } = visitData || [];
  const communityName = community ? community : currentFacility?.name;

  const list = useMemo(() => {
    if (ScreensArray)
      return ScreensArray.map((x) => {
        return {
          id: x.id,
          title: x.screenName
            ? observerTextCheck?.name
              ? observerTextCheck?.name
              : x.screenName
            : communityName,
          loading: !communityName && !x.screenName,
          bgColor: x.screenName
            ? observerTextCheck?.color
              ? observerTextCheck?.color
              : x.bgColor
            : x.bgColor,
          fontWeight: observerTextCheck?.fontWeight
            ? observerTextCheck.fontWeight
            : x.fontWeight,
          color: x.color,
          marginBottom: x.marginBottom,
          fontSize: x.fontSize,
        };
      });
    return [];
  }, [visitData, dateOfVisit, community, communityName, currentFacility]);

  return (
    <Box>
      {list &&
        list.map((x) => {
          return (
            <BaseBanner
              key={x.id}
              title={x.title}
              loading={x.loading}
              bgColor={x.bgColor}
              fontWeight={x.fontWeight}
              color={x.color}
              marginBottom={x.marginBottom}
              fontSize={x.fontSize}
            />
          );
        })}
      <Box
        sx={{
          width: "max-content",
          p: "15px",
          marginLeft: "auto",
          bgcolor: theme.palette.yellow.main,
        }}
      >
        <Typography
          component={"p"}
          variant="p"
          sx={{
            width: "100%",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Date Of Consult:{" "}
          {dayjs(dateOfVisit ?? dateofConsult).format(
            SCREEN_CONSTANTS.Date_Format
          )}
        </Typography>
      </Box>
    </Box>
  );
}

export default HeaderBox;
