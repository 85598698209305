import React from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  useGetDiningAreaByIdQuery,
  useGetDiningAreaTableByIdQuery,
} from "store/apis/ResidentApis";
import { Error } from "@mui/icons-material";

// {breakfastDiningArea?.name}
// {breakfastTable?.name}

export default function DiningInformationSection({ residentId, resident }) {
  const {
    data: breakfastTable,
    isError: breakfastTableError,
    isFetching: breakfastTableLoading,
  } = useGetDiningAreaTableByIdQuery(resident.breakfastTableId, {
    skip: !resident.breakfastTableId,
  });
  const breakfastTableName = resident.breakfastDiningAreaId
    ? breakfastTable?.name
    : "";

  const { data: breakfastArea, isFetching: breakfastAreaLoading } =
    useGetDiningAreaByIdQuery(resident.breakfastDiningAreaId, {
      skip: !resident.breakfastDiningAreaId,
    });
  const breakfastDiningAreaName = resident.breakfastDiningAreaId
    ? breakfastArea?.name
    : "";

  const { data: lunchArea, isFetching: lunchAreaLoading } =
    useGetDiningAreaByIdQuery(resident.lunchDiningAreaId, {
      skip: !resident.lunchDiningAreaId,
    });
  const lunchDiningAreaName = resident.lunchDiningAreaId ? lunchArea?.name : "";

  const { data: supperArea, isFetching: supperAreaLoading } =
    useGetDiningAreaByIdQuery(resident.supperDiningAreaId, {
      skip: !resident.supperDiningAreaId,
    });
  const supperDiningAreaName = resident.supperDiningAreaId
    ? supperArea?.name
    : "";

  const {
    data: lunchTable,
    isError: lunchTableError,
    isFetching: lunchTableLoading,
  } = useGetDiningAreaTableByIdQuery(resident.lunchTableId, {
    skip: !resident.lunchTableId,
  });
  const lunchTableName = resident.lunchDiningAreaId ? lunchTable?.name : "";

  const {
    data: supperTable,
    isError: supperTableError,
    isFetching: supperTableLoading,
  } = useGetDiningAreaTableByIdQuery(resident.supperTableId, {
    skip: !resident.supperTableId,
  });
  const supperTableName = resident.supperDiningAreaId ? supperTable?.name : "";

  const isLoading =
    breakfastAreaLoading ||
    lunchAreaLoading ||
    supperAreaLoading ||
    breakfastTableLoading ||
    lunchTableLoading ||
    supperTableLoading;

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          mt: 1,
          borderRadius: "4px",
          marginLeft: 1,
          boxShadow: "0px 4px 4px 0px #00000040",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#C4E1E2",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span className="residentTitle" style={{ fontSize: "22px" }}>
              Dining Information
            </span>
            {isLoading ? <CircularProgress size={"20px"} /> : null}
          </Box>

          <Link
            to={`/MealCard/addResident?id=${residentId}&step=3&mode=only`}
            className="newEditButton"
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}
            >
              Edit
            </Typography>
          </Link>
        </Box>
        <TableContainer>
          <Table
            size="small"
            sx={{
              borderCollapse: "collapse",
              "& .MuiTableCell-root": { border: "none !important" },
              "& .MuiTableRow-root": {
                borderBottom: "1px solid #bdbdbd !important",
              },
            }}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <TableCell></TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Dining Area
                </TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Table
                </TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Seat
                </TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Fluid Restriction
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              xs={{
                borderBottom: "3px solid red !important",
              }}
            >
              <TableRow key={"breakfast"}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}
                >
                  Breakfast
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {breakfastDiningAreaName}
                </TableCell>
                <DiningTableCell
                  isError={breakfastTableError}
                  name={breakfastTableName}
                />
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.breakfastSeat}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.breakfastRestrictQty}
                </TableCell>
              </TableRow>
              <TableRow key={"lunch"}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}
                >
                  Lunch
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {lunchDiningAreaName}
                </TableCell>
                <DiningTableCell
                  isError={lunchTableError}
                  name={lunchTableName}
                />
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.lunchSeat}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.lunchRestrictQty}
                </TableCell>
              </TableRow>
              <TableRow key={"supper"}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}
                >
                  Dinner
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {supperDiningAreaName}
                </TableCell>
                <DiningTableCell
                  isError={supperTableError}
                  name={supperTableName}
                />
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.supperSeat}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.supperRestrictQty}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

function DiningTableCell({ name, isError }) {
  if (isError)
    return (
      <TableCell color="error" sx={{ fontSize: "1rem !important" }}>
        <Error />
        Error
      </TableCell>
    );
  return <TableCell sx={{ fontSize: "1rem !important" }}>{name}</TableCell>;
}
