/*eslint-disable */
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";

import { setErrorsForProperties } from "components/shared/utils/helpers";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { ADD_USER_FORM } from "./form.constans";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";
import { useAddFacilityUserMutation } from "store/apis/FacilityApis";

export default function AddAssignedUser() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState({});
  const [hasChange, setHasChange] = useState(false);
  const id = searchParams.get("id");

  const [addFacilityUser, { isError, error, isSuccess, isLoading }] =
    useAddFacilityUserMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    setError,
    watch,
    handleSubmit,
    getValues,
    formState: { isDirty, dirtyFields },
  } = methods;
  useEffect(() => {
    if (isError) {
      setErrorsForProperties({
        responseError: error,
        setError,
      });
    }
  }, [isError, error]);

  if (isSuccess) {
    navigate(-1);
  }

  const onSubmit = (data) => {
    setHasChange(false);
    data.facilityId = id;
    addFacilityUser(data);
  };

  return (
    <>
      <BaseBlockerModal2
        // initialValues={initialValues}
        // watch={watch}
        // getValues={getValues}
        hasChange={hasChange}
        setHasChange={setHasChange}
        // setInitialValues={setInitialValues}
        dirtyFields={dirtyFields}
        isDirty={isDirty}
      />

      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <GeneratedForm oldGrid={false} list={ADD_USER_FORM} />
        </Grid>
        <BaseSubmitButton
          fullWidth
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isLoading}
        />
      </FormProvider>
    </>
  );
}
