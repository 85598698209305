import React, { useMemo } from "react";
import { FIELD_DEFAULTS, FIELD_FORM } from "./forms.constants";
import FieldOption from "./FieldOption";
import GenericFieldForm from "./GenericFieldForm";
import { useSearchParams } from "react-router-dom";
import { useGetVisitReportTypeByIdQuery } from "store/apis/VisitReportAPIs";

export default function Field({ nestIndex, sectionIndex, fieldGroupIndex }) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const isEdit = !!id;

  const { data, isLoading } = useGetVisitReportTypeByIdQuery(id, {
    skip: !id,
  });

  const triggerValues = useMemo(() => {
    if (!isEdit || isLoading) return [];

    // console.log(
    //   data?.sectionGroups[sectionIndex]?.sections[fieldGroupIndex]?.fieldGroups[
    //     nestIndex
    //   ]?.fields
    // );
    return data?.sectionGroups[sectionIndex]?.sections[
      fieldGroupIndex
    ]?.fieldGroups[nestIndex]?.fields?.map((field) => ({
      label: `${field.id?.toString()}-${field.label}`,
      value: field.id?.toString(),
      description: field.options
        ?.map((option) => `${option.value} - ${option.label}`)
        .join("\n"),
    }));
  }, [isEdit, data, isLoading]);

  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${nestIndex}].fields`}
      fieldList={FIELD_FORM}
      appendObjectExample={FIELD_DEFAULTS}
      nameOfObject={"Field"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${nestIndex}].fields`}
      ChildComponent={FieldOption}
      triggerValues={triggerValues}
      childProps={{
        fieldGroupIndex,
        fieldIndex: nestIndex,
        sectionIndex,
      }}
      isSortOrder={false}></GenericFieldForm>
  );
}
