import { React } from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { ResidentInformationFields } from "./forms.constants";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useFormContext } from "react-hook-form";

export default function MetricsForm({ isEdit, facilityLoading, canEdit }) {
  const { register } = useFormContext();
  const { formTextFields2, formTextFields3, formSelectProps2 } =
    ResidentInformationFields;

  const usualBodyWeightSource = [
    {
      id: 1,
      label: "Per Resident",
    },
    {
      id: 2,
      label: "Per Family",
    },
    {
      id: 3,
      label: "Other",
    },
  ];

  const StyledBaseInput = styled(BaseInput)`
    .MuiInputBase-root {
      background-color: ${({ ...props }) =>
        (props.checkEMREdit ? canEdit : true) ? undefined : "lightgrey"};
    }
  `;

  return (
    <>
      {isEdit && (
        <input
          hidden
          {...register("id", { required: REQUIRED_ERROR("Id") })}
        ></input>
      )}
      {formTextFields3.map((data, index) => {
        const props = data?.props;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item size={{ xs: 5 }} />;
          default:
            return (
              <Grid key={index} item size={{ xs: 5 }}>
                <StyledBaseInput
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  disabled={props.checkEMREdit ? !canEdit : false}
                />
              </Grid>
            );
        }
      })}

      <Grid item size={{ xs: 5 }}>
        <BaseSelect
          options={usualBodyWeightSource}
          loading={facilityLoading}
          validationProps={formSelectProps2?.props?.validationProps}
          label={formSelectProps2?.props?.label}
          name={formSelectProps2?.props?.name}
          id={formSelectProps2?.props?.id}
        />
      </Grid>

      {formTextFields2.map((data, index) => {
        const props = data?.props;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item size={{ xs: 5 }} />;
          default:
            return (
              <Grid key={index} item size={{ xs: 5 }}>
                <BaseInput
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                />
              </Grid>
            );
        }
      })}

      <Grid item size={{ xs: 8 }}>
        <BaseCheckBox
          name="isWeeklyWeightResident"
          id="isWeeklyWeightResident"
          label="Is Weekly Weight Resident"
        />
      </Grid>
    </>
  );
}
