import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentNoteMutation,
  useGetResidentNoteByIdQuery,
  useUpdateResidentNoteMutation,
} from "../../../../../store/apis/ResidentApis";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { notesList } from "./form.constants";

export default function AddResidentNotes() {
  const [searchParams] = useSearchParams();
  const [hasChange, setHasChange] = useState(false);
  const navigate = useNavigate();

  const id = searchParams.get("noteId");
  const residentId = searchParams.get("id");

  const isEdit = !!id;

  const {
    data: currentNote,
    isFetching,
    isSuccess: isNoteSuccess,
  } = useGetResidentNoteByIdQuery(id, { skip: !id });

  const isEditLoading = isFetching && isEdit;

  const [
    addResidentNote,
    { isLoading: addResidentNoteSubmitting, isSuccess: addResidentNoteSuccess },
  ] = useAddResidentNoteMutation();

  const [
    updateResidentNote,
    {
      isLoading: updateResidentNoteSubmitting,
      isSuccess: updateResidentNoteSuccess,
    },
  ] = useUpdateResidentNoteMutation();

  const isSubmitting =
    addResidentNoteSubmitting || updateResidentNoteSubmitting;
  const isSuccess = addResidentNoteSuccess || updateResidentNoteSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty },
  } = methods;
  useEffect(() => {
    if (isNoteSuccess && !isFetching) {
      // setInitialValues(currentNote);
      reset(currentNote);
    }
  }, [isNoteSuccess, isFetching]);

  !isSubmitting && isSuccess ? navigate(-1) : null;

  function onSubmit(data) {
    setHasChange(false);
    data["residentId"] = residentId;
    if (isEdit) {
      data["id"] = id;
      updateResidentNote(data);
      return;
    }
    addResidentNote(data);
  }

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal2
            hasChange={hasChange}
            setHasChange={setHasChange}
            dirtyFields={dirtyFields}
            isDirty={isDirty}
          />
          <BackButton To={-1} sx={{ marginLeft: "5px" }} />
          <Box
            sx={{
              padding: "5px",
              marginTop: "5px",
            }}
          >
            <FormProvider {...methods}>
              <Grid
                container
                spacing={1.5}
                sx={{
                  marginTop: "10px",
                }}
              >
                <GeneratedForm oldGrid={false} list={notesList} />
              </Grid>
              <BaseSubmitButton
                sx={{ marginTop: "10px" }}
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </FormProvider>
          </Box>
        </>
      )}
    </>
  );
}
