import GeneratedForm, {
  FORM_TYPES,
} from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function Field({ field, isNotApplicable }) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { size, id, label, orientation, type, options } = field;
  const [show, setShow] = React.useState(true);

  useEffect(() => {
    if (field.triggers) {
      if (field.triggerValue === watch(`field-${field.triggers}`)) {
        setShow(true);
      } else setShow(false);
    }
  }, [watch(`field-${field.triggers}`)]);

  if (!show) return null;

  return (
    <GeneratedForm
      control={control}
      errors={errors}
      disabledList={isNotApplicable ? ["field-" + id] : []}
      list={[
        {
          props: {
            name: `field-${id}`,
            label: label !== "-" ? label : "",
            rows: orientation === "vertical" ? "column" : "row",
          },
          type: type ? FORM_TYPES[type] : FORM_TYPES.Text,
          size: size,
          options: options,
        },
      ]}
    />
  );
}
