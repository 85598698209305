import React, { useEffect, useState } from "react";
// MUI Imports
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  Typography,
  Modal,
  Button,
  Checkbox,
  Card,
} from "@mui/material";
// Router Imports
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
// Constant Imports
import {
  PlateRecipesOptionsList,
  PlateRecipesOptions2List,
} from "../plateFul.constant";
// Query Imports
import {
  // useGetRecipeByIdQuery,
  useGetRecipeByIdUserFacilityQuery,
  useCopyRecipeMutation,
} from "../../../../store/apis/RecipeAPIs";

import "../PlateFul.css";
import BaseContent from "../../../shared/baseContent/BaseContent";
import { Roles } from "../../../shared/constants";
// Slice Imports
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import { useSelector } from "react-redux";
import useMsalAccount from "utils/useMsalAccount";

export const OptionsDetails = () => {
  // Local States Declaration
  const [openModal, setOpenModal] = useState(false);
  const [openModalName, setOpenModalName] = useState("");
  const [openModalId, setOpenModalId] = useState();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const menuId = searchParams.get("menuId");
  const { pathname } = currentLocation;
  const backToMenuPlanner = pathname.includes("/Recipes") && menuId;
  // Slice Declaration
  const { userId, roles: userRoles } = useMsalAccount();
  const facilityId = useSelector(selectFacilityId);

  const recipeCategory = useSelector(
    (state) => state.recipeCategory.recipeCategory
  );

  const location = useLocation();
  const { state } = location;
  // Menu Permissions
  const isAdmin = () => {
    const userAllowedWithRoles = [Roles.Admin];
    const roleAllows = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );

    return roleAllows;
  };

  const [menuItems, setMenuItems] = useState(() => {
    const baseItems =
      isAdmin() || recipeCategory === "House Recipes"
        ? PlateRecipesOptionsList
        : PlateRecipesOptionsList.filter(
            (item) => item.optionName !== "Change History"
          );

    const additionalItems = isAdmin() ? PlateRecipesOptions2List : [];

    return [
      ...baseItems,
      ...additionalItems,
      {
        id: 13,
        optionName: "Print",
        Link: "/plateFul/Recipes/Print",
        isActive: false,
      },
    ];
  });

  // Query Section
  const {
    data: currentRecipe,
    // isLoading: currentRecipeLoading,
    isSuccess: currentRecipeSuccess,
  } = useGetRecipeByIdUserFacilityQuery(
    {
      recipeId: recipeId,
      userId: userId,
      facilityId: facilityId,
    },
    { skip: !recipeId || !userId || !facilityId }
  );

  // UseEffect Section
  useEffect(() => {
    let currentLink;
    if (currentLocation) {
      currentLink = menuItems.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      menuItems.indexOf(
        menuItems.find(
          (res) =>
            currentLocation.pathname.includes(res.Link) &&
            res.optionName !== "Back"
        )
      ) || 0;
    setValue(currentIndex !== -1 ? currentIndex : 0);
    setMenuItems(
      menuItems.map((res) => {
        if (currentLocation.pathname.includes(res.Link)) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
  }, [currentLocation]);

  // UI Section
  return (
    <>
      <BaseContent
        headerText={`Recipe - ${currentRecipe?.name || ""}`}
        backLink={
          backToMenuPlanner
            ? `/plateFul/Menus/MenuPlanner?menuId=${menuId}`
            : "/plateFul"
        }
        breadcrumbs={[
          { name: "Recipes", link: "/plateFul" },
          { name: `Recipe - ${currentRecipe?.name || ""}` },
        ]}
        backText="Back"
        disableBack={backToMenuPlanner ? true : false}
        disableHeight={false}
        // transparent={true}
        cardMarginTop={"5px"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Tabs
            id="recipe-navigation"
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
              marginX: "0px",
              marginTop: "0px",
              minHeight: "fit-content !important",
            }}
            value={value}
            aria-label="recipe nav tabs "
          >
            {menuItems.map((data, index) => {
              // if (data.optionName === "Copy This Recipe") {
              //   return null; // If the optionName is ""Copy This Recipe"", skip rendering this item
              // }
              return (
                <LinkTab
                  sx={{
                    marginLeft: "0px",
                    marginBottom: "0px",
                  }}
                  key={index}
                  value={index}
                  id={recipeId}
                  menuId={menuId}
                  name={currentRecipe?.name}
                  canEdit={currentRecipe?.canEdit}
                  setOpenModalName={setOpenModalName}
                  setOpenModalId={setOpenModalId}
                  setOpenModal={setOpenModal}
                  label={`${
                    data.optionName === "Details" && currentRecipeSuccess
                      ? currentRecipe.name
                      : data.optionName
                  }`}
                  // href={
                  //   data.optionName === "Copy This Recipe"
                  //     ? null
                  //     : `${data.Link}?recipeId=${recipeId}`
                  // }
                  link={
                    data.optionName === "Copy This Recipe" ? null : data.Link
                  }
                  state={state}
                  isTitle={data.optionName === "Details"}
                  navigate={navigate}
                  disabled={
                    currentLocation.pathname.includes("Print") && !recipeId
                  }
                />
              );
            })}
          </Tabs>
          <Card
            id="plateful-card"
            sx={{
              flexGrow: 1,
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Card>
          {openModal && (
            <CopyModal
              open={openModal}
              close={() => setOpenModal(false)}
              cgName={openModalName}
              facilityName={openModalName}
              id={openModalId}
              userId={userId}
              facilityId={facilityId}
            />
          )}
        </Box>
      </BaseContent>
    </>
  );
};

// Tabs Ui
function LinkTab({
  label,
  name,
  id,
  menuId,
  link,
  isTitle,
  navigate,
  canEdit,
  setOpenModalName,
  setOpenModalId,
  setOpenModal,
  state,
  ...rest
}) {
  return (
    <Tab
      disabled={rest.disabled}
      component="a"
      className={isTitle ? "recipeDetailTab" : "adminTabs"}
      onClick={(event) => {
        event.preventDefault();

        {
          if (label === "Back") {
            navigate(-1);
          } else if (label === "Copy This Recipe") {
            setOpenModalName(name);
            setOpenModalId(id);
            setOpenModal(true);
          } else {
            if (canEdit != undefined)
              navigate(
                {
                  pathname: link,
                  search: menuId
                    ? `?recipeId=${id}&menuId=${menuId}`
                    : `?recipeId=${id}`,
                },
                { state: { canEdit: canEdit, ...state } }
              );
          }
        }
      }}
      label={label === "Back" ? "< Back" : label}
      {...rest}
    />
  );
}

// Copy Modal Function
function CopyModal({ facilityName, id, userId, facilityId, open, close }) {
  const [isChecked, setIsChecked] = useState(true);
  const [recipeId] = useState(id);
  const navigate = useNavigate();
  const [copyRecipe, { data: copyRecipeData, isLoading: copyRecipeLoading }] =
    useCopyRecipeMutation({
      refetchOnMountOrArgChange: true,
    });

  useEffect(() => {
    if (!copyRecipeLoading && copyRecipeData) {
      navigate(
        `/plateFul/Recipes/addRecipe?recipeId=${copyRecipeData?.id}&Message=CopyRecipe`,
        {
          state: {
            previousLink: `/plateFul/Recipes/RecipeDetail?recipeId=${copyRecipeData?.id}`,
          },
        }
      );
      close();
    }
  }, [copyRecipeData]);

  const copyRecipesData = () => {
    copyRecipe({ recipeId, userId, facilityId, isChecked });
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box className="modalContent">
            <p>
              <span className="material-icons modalIcon" color={"warning"}>
                report
              </span>
            </p>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                sx={{ textAlign: "center" }}
                className="modalTitle"
              >
                Copy Recipe
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{ mt: 2, textAlign: "center" }}
              >
                {facilityName}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(event) => setIsChecked(event.target.checked)}
                  />
                }
                label="Copy Spreads along with the Recipe"
              />
            </Box>
          </Box>
          <Divider sx={{ my: 0.5 }} />
          <Box
            className="deleteActions"
            sx={{
              flex: true,
            }}
          >
            <Button
              className="baseButton"
              variant="contained"
              onClick={close}
              color={"warning"}
            >
              Cancel
            </Button>
            <Button
              onClick={copyRecipesData}
              sx={{ marginLeft: "10px" }}
              className="baseButton"
              variant="contained"
              component={Link}
              color={"error"}
              disabled={copyRecipeLoading}
              startIcon={
                copyRecipeLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }
            >
              Copy
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
