import { Grid, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetResidentAdaptiveEquipmentByIdQuery,
  useAddResidentAdaptiveEquipmentMutation,
  useUpdateResidentAdaptiveEquipmentMutation,
} from "../../../../../store/apis/ResidentApis";

import { useResidentFacilityAdaptiveEquipmentsQuery } from "../../../../../store/apis/FacilityApis";

import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";

import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import { positiveIntegerNumber } from "../../../../../utils/ValidationRegex";

export default function AddAdaptiveEquipment() {
  const [searchParams] = useSearchParams();
  const [isChange, setIsChange] = useState(false);
  const facilityId = useSelector(selectFacilityId);
  const aeid = searchParams.get("aeid");

  const [initialValues, setInitialValues] = useState({});
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const [newFacilityAdaptiveEquipments, setNewFacilityAdaptiveEquipments] =
    React.useState([]);

  const {
    data: availableFacilityAdaptiveEquipments,
    isLoading: availableFacilityAdaptiveEquipmentsLoading,
    isSuccess: availableFacilityAdaptiveEquipmentsSuccess,
  } = useResidentFacilityAdaptiveEquipmentsQuery(facilityId, {
    refetchOnMountOrArgChange: true,
    skip: !facilityId,
  });

  const {
    data: currentResidentAdaptiveEquipment,
    isLoading: currentResidentAdaptiveEquipmentLoading,
    isSuccess: currentResidentAdaptiveEquipmentSuccess,
  } = useGetResidentAdaptiveEquipmentByIdQuery(aeid, { skip: !aeid });

  const [
    addResidentAdaptiveEquipment,
    {
      isLoading: addResidentAdaptiveEquipmentSubmitting,
      isSuccess: addResidentAdaptiveEquipmentSuccess,
    },
  ] = useAddResidentAdaptiveEquipmentMutation();

  const [
    updateResidentAdaptiveEquipment,
    {
      isLoading: updateResidentAdaptiveEquipmentSubmitting,
      isSuccess: updateResidentAdaptiveEquipmentSuccess,
    },
  ] = useUpdateResidentAdaptiveEquipmentMutation();

  const isSubmitting =
    addResidentAdaptiveEquipmentSubmitting ||
    updateResidentAdaptiveEquipmentSubmitting;

  const isSuccess =
    addResidentAdaptiveEquipmentSuccess ||
    updateResidentAdaptiveEquipmentSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
  } = methods;
  // console.log("🚀 ~ AddAdaptiveEquipment ~ isDirty:", valuesChange);

  const OtherField = watch("adaptiveEquipmentId");

  const isEdit = !!aeid;

  const isEditLoading = isEdit && currentResidentAdaptiveEquipmentLoading;

  useEffect(() => {
    if (
      currentResidentAdaptiveEquipmentSuccess &&
      !currentResidentAdaptiveEquipmentLoading
    ) {
      setNewFacilityAdaptiveEquipments([
        ...newFacilityAdaptiveEquipments,
        {
          id: currentResidentAdaptiveEquipment.adaptiveEquipmentId,
          label: currentResidentAdaptiveEquipment.name,
        },
      ]);
      setValue(
        "adaptiveEquipmentId",
        currentResidentAdaptiveEquipment.adaptiveEquipmentId
      );
      reset(currentResidentAdaptiveEquipment);
      setInitialValues(currentResidentAdaptiveEquipment);

      if (
        !availableFacilityAdaptiveEquipments?.some(
          (x) => x.id == currentResidentAdaptiveEquipment.adaptiveEquipmentId
        )
      ) {
        setValue("adaptiveEquipmentId", "Other");
        setValue("description", currentResidentAdaptiveEquipment.description);
      }
    }
  }, [
    currentResidentAdaptiveEquipmentSuccess,
    currentResidentAdaptiveEquipment,
    currentResidentAdaptiveEquipmentLoading,
  ]);

  useEffect(() => {
    if (
      availableFacilityAdaptiveEquipments &&
      !availableFacilityAdaptiveEquipmentsLoading
    ) {
      setNewFacilityAdaptiveEquipments([
        ...newFacilityAdaptiveEquipments,
        ...availableFacilityAdaptiveEquipments,
      ]);
    }
  }, [
    availableFacilityAdaptiveEquipmentsSuccess,
    availableFacilityAdaptiveEquipments,
    availableFacilityAdaptiveEquipmentsLoading,
  ]);

  if (!isSubmitting && isSuccess) {
    navigate(-1);
  }

  const onSubmit = (data) => {
    setIsChange(false);
    data.residentId = id;
    data.name = "";
    data.quantity = 0;

    !data.description ? (data["description"] = "") : null;

    if (isEdit) {
      data["id"] = aeid;
      updateResidentAdaptiveEquipment(data);
      return;
    } else {
      addResidentAdaptiveEquipment(data);
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            watch={watch}
            hasChange={isChange}
            setHasChange={setIsChange}
            getValues={getValues}
            dirtyFields={dirtyFields}
          />
          <Box
            sx={{
              padding: "5px",
              marginTop: "10px",
            }}
          >
            <BackButton To={-1} />
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "5px",
              }}
            >
              <Grid item md={6}>
                <BaseSelect
                  name="adaptiveEquipmentId"
                  id="adaptiveEquipmentId"
                  label="Adaptive Equipment"
                  placeholder="Select an Adaptive Equipment"
                  control={control}
                  validationProps={{
                    required: REQUIRED_ERROR("Adaptive Equipment"),
                  }}
                  errors={errors}
                  options={newFacilityAdaptiveEquipments || []}
                  loading={availableFacilityAdaptiveEquipmentsLoading}
                />
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  display: OtherField == "-1" ? "block" : "none",
                }}
              />
              <Grid
                item
                md={6}
                sx={{
                  display: OtherField == "-1" ? "block" : "none",
                }}
              >
                <BaseInput
                  name="description"
                  id="description"
                  label="Description"
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={6}>
                <BaseInput
                  name="breakfastQuantity"
                  id="breakfastQuantity"
                  label="Breakfast Quantity"
                  type="number"
                  defaultValue={1}
                  validationProps={{
                    required: REQUIRED_ERROR("Breakfast Quantity"),
                    min: {
                      value: 0,
                      message: "Value must be at least 0",
                    },
                    max: {
                      value: 99,
                      message: "Value must be less than 99.",
                    },
                    pattern: {
                      value: positiveIntegerNumber,
                      message: "Please enter a positive whole number",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={6}>
                <BaseInput
                  name="lunchQuantity"
                  id="lunchQuantity"
                  label="Lunch Quantity"
                  type="number"
                  defaultValue={1}
                  validationProps={{
                    required: REQUIRED_ERROR("Lunch Quantity"),
                    min: {
                      value: 0,
                      message: "Value must be at least 0",
                    },
                    max: {
                      value: 99,
                      message: "Value must be less than 99.",
                    },
                    pattern: {
                      value: positiveIntegerNumber,
                      message: "Please enter a positive whole number",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={6}>
                <BaseInput
                  name="supperQuantity"
                  id="supperQuantity"
                  label="Dinner Quantity"
                  type="number"
                  defaultValue={1}
                  validationProps={{
                    required: REQUIRED_ERROR("Dinner Quantity"),
                    min: {
                      value: 0,
                      message: "Value must be at least 0",
                    },
                    max: {
                      value: 99,
                      message: "Value must be less than 99.",
                    },
                    pattern: {
                      value: positiveIntegerNumber,
                      message: "Please enter a positive whole number",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={6}>
                <BaseInput
                  name="snackQuantity"
                  id="snackQuantity"
                  label="Snack Quantity"
                  type="number"
                  defaultValue={"0"}
                  validationProps={{
                    required: REQUIRED_ERROR("Snack Quantity"),
                    min: {
                      value: 0,
                      message: "Value must be at least 0",
                    },
                    max: {
                      value: 99,
                      message: "Value must be less than 99.",
                    },
                    pattern: {
                      value: positiveIntegerNumber,
                      message: "Please enter a positive whole number",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={6}>
                <BaseSubmitButton
                  fullWidth
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Save" : "Create"}
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
