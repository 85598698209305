export const MenuOptionsList = [
  {
    id: 1,
    optionName: "Edit Menu Name",
    Link: "/plateFul/Menus/AddMenus",
    isActive: false,
  },
  // {
  //   id: 4,
  //   optionName: "Menu Planner",
  //   Link: "/plateFul/Menus/MenuPlanner",
  //   IsActive: false,
  // },
  {
    id: 5,
    optionName: "Change History",
    Link: "/plateFul/Menus/ChangeHistory",
    IsActive: false,
  },
];

export const MenuOptionsList2 = [
  {
    id: 2,
    optionName: "Meals/Days",
    Link: "/plateFul/Menus/MenuDays",
    isActive: false,
  },
  {
    id: 7,
    optionName: "Corporate Group Access",
    Link: "/plateFul/Menus/corporateGroup",
    isActive: false,
  },
  {
    id: 9,
    optionName: "Missing Vendor Items",
    Link: "/plateFul/Menus/MissingVendor",
    isActive: false,
  },
];

export const Labels = {
  EMPTY_STRING: "",
  EMPTY_ARRAY: [],
};
