import {
  includeWaagMorningSnacks,
  includeWaagAfternoonSnacks,
  includeWaagEveningSnacks,
  includeCalories,
  includeCarbohydrateCount,
  includeCost,
  includePortionSize,
  includeSodium,
  showDiabeticChoice,
  showHeartHealthy,
  showIDDSIAbbreviations,
  startingDay,
  offset6,
  endingDay,
  reportDateOptionId,
  useLegalSize,
  reportFooterText,
  alternateMenuItemtext,
  formatTypeId,
  reportFooter,
  lastDate,
  firstDate,
  offset12,
} from "../form.constants";

export const WeekAtAGlanceForms = {
  Category: true,
  DayForm: [
    {
      ...startingDay,
      props: { ...startingDay.props, min: 1 },
      validationProps: {
        ...startingDay.validationProps,
        min: { value: 1, message: "Minimum value is 1" },
        validate: (value, formValues) =>
          parseInt(value) > parseInt(formValues.endingDay)
            ? "Starting day should not be more than the ending day"
            : null,
      },
    },
    offset6,
    {
      ...endingDay,
      props: { ...endingDay.props, min: 1 },
      validationProps: {
        ...endingDay.validationProps,
        min: { value: 1, message: "Minimum value is 1" },
        validate: (value, formValues) =>
          parseInt(value) < parseInt(formValues.startingDay)
            ? "Ending day should not be less than the starting day"
            : null,
      },
    },
    offset6,
    includeWaagMorningSnacks,
    offset6,
    includeWaagAfternoonSnacks,
    offset6,
    includeWaagEveningSnacks,
    offset6,
    includeCost,
    offset6,
    includeCalories,
    offset6,
    includePortionSize,
    offset6,
    includeCarbohydrateCount,
    offset6,
    includeSodium,
    offset6,
    showHeartHealthy,
    offset6,
    showDiabeticChoice,
    offset6,
    showIDDSIAbbreviations,
    offset6,
  ],
  Diet: true,
  SnackMeal: [
    reportDateOptionId,
    offset6,
    firstDate,
    offset6,
    lastDate,
    offset12,
    useLegalSize,
    offset6,
    reportFooterText,
    offset6,
    reportFooter,
    offset6,
    alternateMenuItemtext,
  ],
  Report: [formatTypeId],
};
