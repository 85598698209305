export const CalculationElements = [
  {
    Name: "Healthy Eating Pattern Calculation",
    Link: "/plateFul/hep/HepResults",
    IsActive: false,
  },
  {
    Name: "Nutrition Analysis Calculation",
    Link: "/plateFul/nutrientAnalysisMenu/NutrientAnalysisMenuResults",
    IsActive: false,
  },
];
