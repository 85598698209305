import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useAddVendorCostMutation } from "../../../../../../store/apis/RecipeAPIs";
import { Button, Link, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { VendorCostFields, vendorCostDefaults } from "./form.constants";
import { FormProvider, useForm } from "react-hook-form";
import { setErrorsForProperties } from "../../../../../shared/utils/helpers";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";

export default function AddVendorCost() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: vendorCostDefaults,
  });

  const { handleSubmit, setError } = methods;

  const [
    addVendorCost,
    { isLoading: isSubmitting, error: errorResponse, isSuccess },
  ] = useAddVendorCostMutation();

  const onSubmit = (data) => {
    data["recipeId"] = recipeId;
    addVendorCost(data);
  };

  useEffect(() => {
    if (errorResponse && errorResponse?.status === 400) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
      });
    }
  }, [errorResponse]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 1,
      }}
    >
      <Box>
        <Button
          component={Link}
          color={"primary"}
          className="baseButton"
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={
            <span className="material-icons">keyboard_arrow_left</span>
          }
        >
          {"Back"}
        </Button>
      </Box>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <GeneratedForm list={VendorCostFields} oldGrid={false} />
          <Grid size={6}>
            <BaseSubmitButton
              isSubmitting={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              className="baseButton"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
}
