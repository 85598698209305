import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { FormProvider, useForm } from "react-hook-form";
import { useGetAvailableSeasonSelectBoxQuery } from "../../../../../../../store/apis/SeasonApis";
import { useGetMenusForTableSideQuery } from "../../../../../../../store/apis/MenuApis";
import { Box } from "@mui/system";
import {
  useDeleteFacilityTableSideAutomationMutation,
  useGetFacilityTableSideAutomationQuery,
  useUpdateFacilityTableSideAutomationMutation,
} from "../../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { REQUIRED_ERROR } from "utils/errorMessages";
// import { SCREEN_CONSTANTS } from "components/shared/constants";
import { positiveNumber } from "utils/ValidationRegex";
import { BaseButton } from "components/shared/BaseButton";

export default function AutomaticSetup() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data: seasons, isFetching: isFetchingSeasons } =
    useGetAvailableSeasonSelectBoxQuery({
      take: 3,
      skip: 0,
      forTableSide: true,
    });

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();

  const seasonId = watch("seasonId");

  const menuStartDate = watch("menuStartDate");
  // const startDay = watch("menuStartDay") || 0;
  // const daysAhead = watch("createDaysAhead") || 1;

  const [
    updateAutomation,
    { isLoading: isfetchingAutomation, isSuccess: issuccessAutomation },
  ] = useUpdateFacilityTableSideAutomationMutation();
  const [deleteAutomation, { isLoading: isDeleteAutomation }] =
    useDeleteFacilityTableSideAutomationMutation();

  const {
    data: currentData,
    isFetching: isFetchingCurrentData,
    isSuccess: isSuccessCurrentData,
  } = useGetFacilityTableSideAutomationQuery(id, { skip: !id });

  useEffect(() => {
    if (currentData && !isFetchingCurrentData && isSuccessCurrentData) {
      reset(currentData?.tableSideMenuAutomation);
      if (!currentData?.tableSideMenuAutomation?.menuStartDate) {
        setValue("menuStartDate", dayjs().format("YYYY-MM-DD"));
      }
      currentData?.tableSideMenuAutomation?.automationStartTimes?.map(
        (item) => {
          switch (item?.mealId) {
            case 1:
              setValue(
                `breakfast-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
            case 2:
              setValue(
                `lunch-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
            case 3:
              setValue(
                `dinner-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
          }
        }
      );
    }
  }, [currentData, isFetchingCurrentData, isSuccessCurrentData]);

  useEffect(() => {
    if (!isfetchingAutomation && issuccessAutomation) {
      navigate(-1);
    }
  }, [issuccessAutomation, isfetchingAutomation]);

  const onSubmit = (data) => {
    data["facilityId"] = id;

    data["saveData"] = "";

    for (let i = 0; i < 7; i++) {
      // dayjs(`2022-04-17T${value}`)

      var breakfast = data["breakfast-" + i];
      var lunch = data["lunch-" + i];
      var dinner = data["dinner-" + i];

      if (!dayjs(breakfast).isValid()) {
        breakfast = dayjs(`2022-04-17T${breakfast}`);
      }

      if (!dayjs(lunch).isValid()) {
        lunch = dayjs(`2022-04-17T${lunch}`);
      }

      if (!dayjs(dinner).isValid()) {
        dinner = dayjs(`2022-04-17T${dinner}`);
      }

      data.saveData += `${i + 1}_1=${dayjs(breakfast).format("HH:mm")};`;
      data.saveData += `${i + 1}_2=${dayjs(lunch).format("HH:mm")};`;
      data.saveData += `${i + 1}_3=${dayjs(dinner).format("HH:mm")};`;
    }

    // var myreturn = true;

    // if (myreturn) {
    //   return;
    // }

    updateAutomation({
      ...data,
      dateEndTemporary: dayjs(data?.dateEndTemporary).isValid()
        ? dayjs(data?.dateEndTemporary).format("YYYY-MM-DD")
        : null,
    });
  };

  const { data: menus, isFetching: isFetchingMenus } =
    useGetMenusForTableSideQuery(
      {
        seasonId: seasonId,
        facilityId: id,
      },
      { skip: !id || !seasonId }
    );

  const deleteAutomaticAutomation = () => {
    deleteAutomation({
      facilityId: id,
      menuStartDate: dayjs(
        currentData?.tableSideMenuAutomation?.menuStartDate
      ).format("YYYY-MM-DD"),
      saveData: "",
    });
  };
  return (
    <>
      {isFetchingCurrentData ? (
        <EditSkeleton />
      ) : (
        <FormProvider {...methods}>
          <>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={4}
              sx={{
                marginTop: "5px",
              }}
            >
              <Typography
                variant="p"
                component={"p"}
                align="left"
                sx={{
                  fontWeight: "600",
                }}
              >
                {`Timezone setup is ${
                  currentData?.tableSideMenuAutomation?.timeZoneName
                    ? currentData?.tableSideMenuAutomation?.timeZoneName
                    : "not set."
                }`}
              </Typography>
              <BaseButton
                isSubmitting={isDeleteAutomation}
                disabled={!currentData?.tableSideMenuAutomation?.menuStartDate}
                text={"Disable Automatic Meal Start"}
                onClick={deleteAutomaticAutomation}
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "4px",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <BaseDatePicker
                  name="menuStartDate"
                  label="Menu Start Date"
                  id="menuStartDate"
                  defaultValue={dayjs().format("YYYY-MM-DD")}
                  validationProps={{
                    required: REQUIRED_ERROR("Menu Start Date"),
                  }}
                />
              </Grid>
              <Grid item size={{ md: 3 }}>
                <BaseInput
                  name="menuStartDay"
                  label="Menu Start Day"
                  id="menuStartDay"
                  type="number"
                  defaultValue={0}
                  validationProps={{
                    required: REQUIRED_ERROR("Menu Start Day"),
                    maxLength: {
                      value: 3,
                      message: "Please enter 3 numbers or less",
                    },
                    pattern: {
                      value: positiveNumber,
                      message: "Please enter positive number",
                    },
                  }}
                />
              </Grid>
              <Grid item size={{ md: 3 }}>
                <BaseInput
                  name="createDaysAhead"
                  label="Days Ahead to Create"
                  id="createDaysAhead"
                  type="number"
                  control={control}
                  min={1}
                  defaultValue={1}
                  errors={errors}
                  validationProps={{
                    required: REQUIRED_ERROR("Days Ahead to Create"),
                    maxLength: {
                      value: 3,
                      message: "Please enter 3 numbers or less",
                    },
                    pattern: {
                      value: positiveNumber,
                      message: "Please enter positive number",
                    },
                  }}
                />
              </Grid>
              <Grid item size={{ md: 6 }}>
                <BaseSelect
                  name="seasonId"
                  id="seasonId"
                  label="Season"
                  options={seasons || []}
                  loading={isFetchingSeasons}
                  validationProps={{
                    required: REQUIRED_ERROR("Season"),
                  }}
                />
              </Grid>
              <Grid item size={{ md: 6 }}>
                <BaseSelect
                  name="menuId"
                  id="menuId"
                  label="Menu"
                  options={menus || []}
                  loading={isFetchingMenus}
                  validationProps={{
                    required: REQUIRED_ERROR("Menu"),
                  }}
                />
              </Grid>
              {Array.from({ length: 7 }).map((it, index) => (
                <Grid item size={{ md: 3 }} key={index}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: 1,
                      borderColor: "black",
                      p: 2,
                    }}
                  >
                    <Typography
                      align="center"
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      {dayjs(menuStartDate).add(index, "days").format("dddd")}
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginTop: "2px",
                      }}
                    >
                      <Grid item size={{ md: 12 }}>
                        <BaseDatePicker
                          name={"breakfast-" + index}
                          label="Breakfast"
                          id={"breakfast-" + index}
                          defaultValue={"06:30"}
                          type="time"
                          timeFormat="HH:mm"
                          validationProps={{
                            required: REQUIRED_ERROR("Breakfast Time"),
                          }}
                        />
                      </Grid>
                      <Grid item size={{ md: 12 }}>
                        <BaseDatePicker
                          name={"lunch-" + index}
                          label="Lunch"
                          id={"lunch-" + index}
                          type="time"
                          timeFormat="HH:mm"
                          defaultValue={"11:00"}
                          validationProps={{
                            required: REQUIRED_ERROR("Lunch Time"),
                          }}
                        />
                      </Grid>
                      <Grid item size={{ md: 12 }}>
                        <BaseDatePicker
                          name={"dinner-" + index}
                          label="Dinner"
                          id={"dinner-" + index}
                          type="time"
                          timeFormat="HH:mm"
                          defaultValue={"16:30"}
                          validationProps={{
                            required: REQUIRED_ERROR("Dinner Time"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
              <Grid item size={{ md: 12 }}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    isSubmitting={isfetchingAutomation}
                    onClick={handleSubmit(onSubmit)}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        </FormProvider>
      )}
    </>
  );
}
