import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const BaseMain = styled(Box)(({ theme }) => ({
  height: "100dvh",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  flexGrow: 1,
  paddingTop: theme.spacing(3),
}));
