import React, { useState, useMemo, useEffect } from "react";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import { debounce } from "lodash";
import {
  useGetSubRecipeListQuery,
  useGetSubRecipeSearchListQuery,
  useAddSubRecipeMutation,
} from "../../../../../store/apis/PlateFulSubRecipeApis";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import BaseAutoComplete from "../../../../shared/baseForm/BaseAutoComplete";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
// import { DMStyles } from "../../../../../styles/Styles";

export default function SubRecipe() {
  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");

  const {
    data: subRecipe,
    isError: hasError,
    isLoading: loading,
  } = useGetSubRecipeListQuery(
    { recipeId: recipeId },
    { skip: !recipeId, refetchOnMountOrArgChange: true }
  );

  const [addSubRecipe, { isError: addError, isLoading: addLoading }] =
    useAddSubRecipeMutation();

  const isError = hasError || addError;
  const isLoading = loading || addLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,

    watch,
    reset,
  } = methods;

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [Search, setSearchdata] = useState();
  const { data: searchData, isLoadingtest } = useGetSubRecipeSearchListQuery(
    {
      key: debouncedSearch,
    },
    {
      skip: !debouncedSearch,
    }
  );
  //below code is added to filter the sub Recipes Search from Added Sub Recipe Data.
  useEffect(() => {
    if (searchData?.length > 0) {
      if (subRecipe) {
        setSearchdata(
          searchData?.filter(
            (item) => !subRecipe.some((item2) => item2.idSubRecipe === item.id)
          )
        );
      } else {
        setSearchdata(searchData);
      }
    }
  }, [subRecipe, searchData, debouncedSearch]);
  //End code.
  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );

  const SubRecipeColumns = [
    {
      field: "subRecipe",
      headerName: "Name",
      flex: 4,
      renderCell: (params) => {
        return (
          <Link
            to={`/plateFul/Recipes/RecipeDetail?recipeId=${params.row.idSubRecipe}`}
            style={{ color: "rgba(0, 0, 0, 0.87)" }}
          >
            {params.row["subRecipe"]}
          </Link>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={subRecipe}
            hasDelete={canEdit}
            deleteProps={{
              entityName: params.row?.subRecipe,
              apiPath: "deleteSubRecipe",
              title: "Sub Recipe",
            }}
          />
        );
      },
    },
  ];

  const onSubmit = async (data) => {
    addSubRecipe({ ...data, parentId: parseInt(recipeId) });
    reset({ subRecipeId: "" });
    setResetSearch(!resetSearch);
  };

  return (
    <>
      <Box
        sx={{
          padding: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          flexGrow: 1,
          height: "100%",
        }}
      >
        <FormProvider {...methods}>
          {canEdit ? (
            <FlexBox justifyContent={"space-between"}>
              <Box sx={{ width: "320px" }}>
                <BaseAutoComplete
                  formSelectProps={{
                    props: {
                      name: "subRecipeId",
                      label: "Sub Recipe",
                      required: true,
                      size: "small",
                    },
                    validationProps: {},
                  }}
                  isLabelBold={true}
                  options={Search || []}
                  loading={isLoading}
                  defaultOptions={[]}
                  handleSearch={handleSearch}
                  resetSearch={resetSearch}
                />
              </Box>
              <Box>
                <BaseSubmitButton
                  disabled={!watch("subRecipeId")}
                  text="Add"
                  isSubmitting={isLoadingtest}
                  onClick={handleSubmit(onSubmit)}
                />
              </Box>
            </FlexBox>
          ) : null}
        </FormProvider>
        <BaseDataGrid
          rows={subRecipe}
          columns={SubRecipeColumns}
          loading={isLoading}
          error={isError}
          height={"100%"}
        />
      </Box>
    </>
  );
}
