import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import {
  useGetIngredientListQuery,
  useGetPagedIngredientsQuery,
} from "../../../../../store/apis/IngredientAPIs";
import { Button, CircularProgress, MenuItem } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSnackbar } from "notistack";
import { BaseButton } from "components/shared/BaseButton";
import BaseMenu from "components/shared/BaseMenu";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { AddShoppingCart, Check, Close } from "@mui/icons-material";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function Ingredients() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [isRecipe, setIsRecipe] = useState(
    searchParams.get("isrecipe") || false
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: ingredients,
    isError: hasError,
    isLoading: loading,
  } = useGetPagedIngredientsQuery({
    searchTerm: debouncedValue || "",
    isRecipe: isRecipe,
    skip: paginationModel.page * paginationModel.pageSize,
    take: paginationModel.pageSize,
  });

  const ingredientsData = ingredients?.items || [];

  const isError = hasError;
  const isLoading = loading;

  const [fetchReport, setFetchReport] = useState(false);
  const {
    data: reportData,
    isFetching: reportFetching,
    isSuccess: reportSuccess,
  } = useGetIngredientListQuery(undefined, {
    skip: !fetchReport,
  });

  useEffect(() => {
    if (reportSuccess) {
      DownloadLinkHelper(reportData?.data);
      enqueueSnackbar(`${reportData?.messages[0]}`, {
        variant: "success",
      });
    }
  }, [reportData?.data]);

  const IngredientOptions = ({ index, id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <BaseMenu index={index}>
          <MenuItem
            component={Link}
            to={`/admin/recipes/IngredientDetails/UnitConversion?id=${id}&name=${name}`}
            disableRipple
          >
            Unit Conversion
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/admin/recipes/IngredientDetails/addHep?id=${id}&name=${name}`}
            disableRipple
          >
            HEP
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/admin/recipes/IngredientDetails/VendorIngredient?id=${id}&name=${name}`}
            disableRipple
          >
            Vendors
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/admin/recipes/IngredientDetails/IngredientAllergy?id=${id}&name=${name}`}
            disableRipple
          >
            Allergies
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/admin/recipes/IngredientDetails/IngredientUsage?id=${id}&name=${name}`}
            disableRipple
          >
            Recipe Usage
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
            }}
            className="menuDeleteButton"
            disableRipple
          >
            Delete
          </MenuItem>
        </BaseMenu>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteIngredient"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const IngredientColumns = [
    {
      field: "name",
      headerName: "Ingredient Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={ingredientsData}
            hasEdit={true}
            editProps={{
              To: `/admin/recipes/IngredientDetails/addIngredient?id=${params.row.id}`,
              state: {
                title: "Edit Ingredient",
                data: ingredientsData.find((row) => row.id === params.row.id),
              },
              title: "Ingredient",
            }}
            childrenLocation={"end"}
          >
            <IngredientOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row["name"] || ""}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        mb={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        <FlexBox justifyContent={"start"}>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            setPaginationModel={setPaginationModel}
          />
          <BaseButton
            text="Add Ingredient"
            To="/admin/recipes/IngredientDetails/addIngredient"
            endIcon={<AddShoppingCart />}
          />
          <BaseButton
            text="Show Pureed Recipe Ingredients"
            startIcon={isRecipe ? <Check /> : <Close />}
            onClick={() => {
              setIsRecipe(!isRecipe);
              setSearchParams({
                search: debouncedValue,
                isrecipe: !isRecipe,
              });
            }}
          />
        </FlexBox>
        <Button
          id="report-button"
          className="baseButton"
          color={"error"}
          disableElevation
          onClick={() => {
            if (reportSuccess) {
              DownloadLinkHelper(reportData?.data);
              enqueueSnackbar(`${reportData?.messages[0]}`, {
                variant: "success",
              });
            }
            setFetchReport(true);
          }}
          variant={"contained"}
          startIcon={
            reportFetching && <CircularProgress color="warning" size={"1rem"} />
          }
        >
          Report
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={ingredientsData}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        rowCount={ingredients?.totalCount || 0}
        paginationModel={paginationModel}
        columns={CreateBaseColumns(IngredientColumns)}
        loading={isLoading}
        error={isError}
        autoHeight={false}
      />
    </>
  );
}
