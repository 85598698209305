import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import Fade from "@mui/material/Fade";
import FlexBox from "./styledComponents/FlexBox";

const ErrorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  margin: "auto 0",
}));

const ErrorTitle = styled(Typography)(({ theme }) => {
  return { fontWeight: "bold", marginTop: theme.spacing(2) };
});

const ErrorMessage = styled(Typography)({
  textAlign: "center",
});

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/NotFound.md)
 */
export default function FullScreenMessage({
  verticallyCenter = false,
  message,
  title,
  icon,
  code,
  color,
  instructions,
}) {
  const titleMessage = code ? `${code} | ${title}` : title;
  return (
    <Fade in={true} timeout={500}>
      <FlexBox
        id="full-screen-message"
        gap={"0"}
        direction="column"
        justifyContent={"center"}
        margin="auto 0"
        alignItems={"center"}
      >
        <ErrorContainer>
          {icon ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {icon}
            </Box>
          ) : null}
          <Typography
            variant="h2"
            sx={{
              color: (theme) =>
                theme.palette[color]?.main || theme.palette.primary.main,
              textAlign: "center",
              marginTop: "0px",
              fontSize: (theme) =>
                verticallyCenter
                  ? {
                      md: theme.typography.h2.fontSize,
                      xs: "1.5rem",
                    }
                  : theme.typography.h2.fontSize,
            }}
          >
            {titleMessage}
          </Typography>
          <ErrorTitle>{message}</ErrorTitle>
          <ErrorMessage>
            {instructions ||
              "Please contact your administrator for more details."}
          </ErrorMessage>
        </ErrorContainer>
      </FlexBox>
    </Fade>
  );
}
