/*eslint-disable*/
import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { SymbolsDetails, categoryColorStrips } from "./constants";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ReactComponent as AppleIcon } from "../../../../../assets/imgs/apple-icon.svg";
import { useFormContext, useWatch } from "react-hook-form";
import BaseRadio from "components/shared/baseForm/BaseRadio";
import { useDispatch } from "react-redux";
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";
export const RecipeCard = ({
  dbClickNav = false,
  isDescription,
  isCost,
  data,
  keyIndex,
  setOpenViewModal,
  setRecipeData,
  rows,
  column,
  moveCard,
  index,
  categoryStrips = false,
  selectedRecipes,
  setSelectedRecipes,
  thisMeal,
  holdingAreaAndThisMeal,
  isHoldingArea,
  searchKey,
  setSearchKey,
  supportMultiSelect,
  menuPlannerData,
  setMenuPlannerData,
  holdingAreaClone,
  updateLocalStorage,
}) => {
  const finalData = data;
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [bgColor, setBgColor] = useState("");
  const { control, setValue } = useFormContext();
  const showSelection = useWatch({ control, name: "showSelection" });
  const isMain = useWatch({
    control,
    name: `radio_${
      finalData?.menuRecipeId || finalData?.recipeId
    }_${rows}_${column}`,
  });

  useEffect(() => {
    if (finalData?.isMain !== undefined) {
      setValue(
        `radio_${
          finalData?.menuRecipeId || finalData?.recipeId
        }_${rows}_${column}`,
        finalData?.isMain ? 1 : 0
      );
    }
  }, [finalData]);

  useEffect(() => {
    const menuPlanner = structuredClone(menuPlannerData);
    const updatedRecipes = structuredClone(
      menuPlannerData?.resultSet[rows]?.days[column]?.recipes
    );
    // Find the index of the recipe in the array
    const recipeIndex = updatedRecipes?.findIndex(
      (recipe) => recipe?.recipeId === finalData?.recipeId
    );

    // Only proceed if recipe was found
    if (recipeIndex !== -1 && recipeIndex !== undefined) {
      // Check if the value is actually different from the current value
      const currentIsMain = updatedRecipes[recipeIndex].isMain;
      const newIsMain = isMain == 1;

      if (currentIsMain !== newIsMain) {
        // Only dispatch if the value was changed by user
        dispatch(setIsChangeTrue(true));
      }
      // Update the recipe's isAlternate property based on radio selection
      if (isMain == 1) {
        updatedRecipes[recipeIndex].isMain = true;
        updatedRecipes[recipeIndex].isHepEnabled = true;
      } else {
        updatedRecipes[recipeIndex].isMain = false;
        updatedRecipes[recipeIndex].isHepEnabled = false;
      }

      // Update the recipes array in the menuPlanner copy
      menuPlanner.resultSet[rows].days[column].recipes = updatedRecipes;
      // Update the state with the modified data
      setMenuPlannerData(menuPlanner);
    }
  }, [isMain]);
  useEffect(() => {
    if (isHoldingArea) {
      const initialIdx = holdingAreaClone[0].recipes.findIndex(
        (recipe) => recipe?.recipeId === finalData?.recipeId
      );
      if (initialIdx !== -1 && initialIdx !== undefined) {
        // Update the recipe's isAlternate property based on radio selection
        if (isMain == 1) {
          holdingAreaClone[0].recipes[initialIdx].isMain = true;
          holdingAreaClone[0].recipes[initialIdx].isHepEnabled = true;
        } else {
          holdingAreaClone[0].recipes[initialIdx].isMain = false;
          holdingAreaClone[0].recipes[initialIdx].isHepEnabled = false;
        }
        updateLocalStorage([holdingAreaClone[0]]);
      }
    }
  }, [isMain]);

  const RadioGroup = [
    { value: 1, label: "Main" },
    { value: 0, label: "Alternate" },
  ];

  // hook to control drop functionality
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    canDrop(item) {
      // Add proper null checks to prevent "Cannot read properties of null" error
      return item && Array.isArray(item) && item[0] && item[0].canDrop;
    },
    hover(items, monitor) {
      //only fires when type is card for selected items
      //which is only currently set to list length === 1
      if (!ref.current) {
        return;
      }
      const dragIndex = items[0].index;
      let hoverIndex = index;
      // Don't replace items with themselves if in same row/column
      if (
        dragIndex === hoverIndex &&
        items[0]?.rows === rows &&
        items[0]?.column === column
      ) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = hoverBoundingRect.height / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // If we made it past all these checks, see if we need to update hoverIndex.
      // Downward moves between columns because the hover for the next box may not be hit.
      // So hoverIndex is still stuck on last item but is more than halfway to be place below when dropped
      // This resulted in popping above the element where the user thinks it should go
      if (dragIndex == hoverIndex && hoverClientY > hoverMiddleY) {
        hoverIndex++;
      }
      if (!isUndefined(items?.[0]?.rows) && !isUndefined(rows)) {
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex, items[0]);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      items[0].index = hoverIndex;
    },
  });
  const [{ isDragging }, dragRef, preview] = useDrag(
    {
      //permit multiple items only to delete drop zone for now, need to revisit to add other functionality later
      type: selectedRecipes?.length > 1 ? "delete" : "card",
      item: selectedRecipes
        ? [...selectedRecipes]
        : [
            {
              finalData,
              rows,
              column,
              index,
              thisMeal,
              isHoldingArea,
              canDrop: true,
            },
          ],
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [selectedRecipes, finalData, rows, column]
  );

  dragRef(drop(ref));

  let trueTags = [];

  // this filter find out the keys which have true value
  if (finalData) {
    trueTags = Object?.keys(finalData)?.filter(
      (key) => finalData[key] === true
    );
  } else {
    trueTags = [];
  }
  // filter which used to find out the right symbol
  const Trueicon = trueTags?.flatMap((data) =>
    SymbolsDetails?.filter((item) => item.label === data)
  );
  // find which used to find out the right color
  const categoryColor = categoryColorStrips.find(
    (item) => item.id === finalData?.menuCategoryId
  );

  // function which changes the background color and sets selectedRecipe array
  const handleClick = (event) => {
    // only trigger when not in MealViewModal
    if (!thisMeal && !isHoldingArea) {
      if (!isUndefined(rows)) {
        if (!event.ctrlKey) {
          setSelectedRecipes([
            {
              rows,
              column,
              index,
              finalData: finalData,
            },
          ]);
        } else {
          // if CTRL held and new item, add it to existing list
          if (
            !selectedRecipes?.some(
              (item) =>
                item.rows === rows &&
                item.column === column &&
                item.index === index &&
                item.finalData?.recipeId === finalData?.recipeId
            )
          ) {
            setSelectedRecipes((prev) => [
              ...prev,
              {
                rows,
                column,
                index,
                finalData: finalData,
              },
            ]);
          } else {
            //otherwise remove it from list
            const removeIndex = selectedRecipes.findIndex(
              (item) =>
                item.rows === rows &&
                item.column === column &&
                item.index === index &&
                item.finalData?.recipeId === finalData?.recipeId
            );
            if (removeIndex > -1) {
              selectedRecipes.splice(removeIndex, 1);
              setSelectedRecipes([...selectedRecipes]);
            }
          }
        }
      } else {
        setSearchKey(keyIndex);
        setSelectedRecipes([
          {
            rows,
            column,
            index,
            finalData: finalData,
          },
        ]);
      }
    }
  };

  // function which will pop modal on double click
  const doubleClick = () => {
    if (typeof setOpenViewModal === "function") {
      setOpenViewModal(true);
      setRecipeData({ finalData, index });
    }
  };

  const handlePointerDown = (event) => {
    // check if we need to shift over selected recipe list on pointer down when in actual menu,
    // so we can retain the selected array (mousedown on item in current list) or pick up a drag target if needed
    if (supportMultiSelect) {
      if (
        !event.ctrlKey &&
        !selectedRecipes?.some(
          (item) =>
            item.rows === rows &&
            item.column === column &&
            item.index === index &&
            item.finalData?.recipeId === finalData?.recipeId
        )
      ) {
        setSelectedRecipes([
          {
            rows,
            column,
            index,
            finalData: finalData,
          },
        ]);
      }
    } else if (!supportMultiSelect && !thisMeal && !isHoldingArea) {
      //otherwise pick up new recipe from search to allow drag, ignore when in MealViewModal
      setSearchKey(keyIndex);
      setSelectedRecipes([
        {
          rows,
          column,
          index,
          finalData: finalData,
        },
      ]);
    }
  };

  const renderIcons = () => {
    return Trueicon?.map((data, index) =>
      data.label === "fr" ||
      data.label === "isBaseRecipe" ||
      data.label === "substituteRecipeAvailable" ||
      data.label === "isSubstituteRecipe" ? (
        <span
          key={index}
          className="material-icons"
          style={{
            color: data?.color,
            fontSize: "16px",
          }}
        >
          {data?.icon}
        </span>
      ) : null
    );
  };

  // useEffect section
  useEffect(() => {
    setBgColor(() => {
      return (selectedRecipes?.length > 0 &&
        selectedRecipes?.some(
          (item) =>
            item.rows === rows &&
            item.column === column &&
            item.index === index &&
            item.finalData?.recipeId === finalData?.recipeId
        )) ||
        (isEqual(searchKey, keyIndex) && !isUndefined(searchKey))
        ? "RGBA(0, 188, 190, 0.3)"
        : null;
    });
  }, [selectedRecipes, searchKey, finalData?.recipeId]);

  // Prevents the default browser drag preview from showing
  // in favor of using the new RecipeCardDragLayer
  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  // UI Section
  return (
    <>
      <Box
        ref={ref}
        data-handler-id={handlerId}
        cursor="move"
        onClick={(event) => handleClick(event)}
        onPointerDown={(event) => handlePointerDown(event)}
        onDoubleClick={doubleClick}
        {...(dbClickNav
          ? {
              onDoubleClick: () =>
                window.open(
                  `/plateFul/Recipes/RecipeDetail?recipeId=${finalData?.recipeId}&menuId=-1`,
                  "_blank"
                ),
            }
          : null)}
        sx={{
          position: "relative",
          boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.25)", // Only bottom shadow
          // borderRadius: "8px",
          opacity: isDragging ? 0.5 : 1,
          backgroundColor:
            (selectedRecipes &&
              isEqual(selectedRecipes[0]?.recipeId, finalData?.recipeId)) ||
            isEqual(keyIndex, searchKey)
              ? bgColor
              : null,
        }}
      >
        <Box
          sx={{
            margin: { md: "3px 0px", xl: "5px 0px" },
            fontSize: { md: "12px", xl: "14px" },
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              paddingLeft: "2px",
              width: { md: "80%", xl: "84%" },
              display: "flex",
              alignItems: "center",
            }}
          >
            {renderIcons()}
            <span
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {finalData?.recipeName}
            </span>
          </Box>
          {holdingAreaAndThisMeal && (
            <Box width={"40%"} paddingBlock={0.5}>
              <BaseRadio
                id={`radio_${
                  finalData?.menuRecipeId || finalData?.recipeId
                }_${rows}_${column}`}
                name={`radio_${
                  finalData?.menuRecipeId || finalData?.recipeId
                }_${rows}_${column}`}
                // setValue={setValue}
                rows={"row"}
                options={RadioGroup}
                defaultValue={0}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "12px",
                  },
                  "& .MuiRadio-root": {
                    padding: "0.5px",
                  },
                }}
              />
            </Box>
          )}

          {isCost ? (
            <Box
              sx={{
                fontSize: { md: "8px", xl: "10px" },
                width: { md: "20%" },
                padding: "auto",
                alignItems: "center",
                fontWeight: "bold",
                marginRight: "3px",
              }}
            >
              {finalData?.costPerServing
                ? `$  ${finalData?.costPerServing?.toFixed(2)}`
                : "$0.00"}
            </Box>
          ) : null}
          <Box>
            {showSelection && finalData?.isHepEnabled ? (
              <AppleIcon
                width={"10px"}
                height={"10px"}
                style={{ marginRight: "5px" }}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: { md: "9px", xl: "11px" },
            marginLeft: "2px",
          }}
        >
          {isDescription && (
            <Typography sx={{ wordWrap: "break-word" }} variant="body">
              {finalData?.description}
            </Typography>
          )}
        </Box>
        {categoryStrips ? (
          <Box
            sx={{
              height: "100%",
              width: "3px",
              backgroundColor: categoryColor?.color,
              position: "absolute",
              top: "0px",
              right: "0px",
              borderRadius: "10px",
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
