/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  useDeleteMenuPrintStyleMutation,
  useGetMenuByIdQuery,
  useGetMenuCategoriesSelectBoxQuery,
  useGetMenuItemsQuery,
  useGetMenuPrintStylesQuery,
  useMenuPrintStylesMutation,
} from "../../../../store/apis/MenuApis";
import { useGetSeasonSelectBoxQuery } from "../../../../store/apis/SeasonApis";
import "./CustomizePostedMenu.css";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { FormProvider, useForm } from "react-hook-form";
import {
  COLUMN_IDS,
  GROUPING_DEFAULTS,
  ONLY_GROUPING_DEFAULTS,
} from "./customizedPostedMenu.constants";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../admin/admin.overlays";
import BaseContent from "../../../shared/baseContent/BaseContent";
import { MovableItem } from "./MovableItem";
import { Column } from "./Column";
import { positiveIntegerNumber } from "../../../../utils/ValidationRegex";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";
import FullScreenMessage from "components/shared/FullScreenMessage";
import { STATUS_MESSAGES } from "components/shared/utils/helpers";
// import BlockerModal from "components/shared/blockerModal/BlockerModal";

export default function CustomizePostedMenu() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("menuId");
  const location = useLocation();
  const title = location?.state?.title;
  const [isChangeAllowed, setIsChangeAllowed] = useState(true);
  const [isSaved, setIsSaved] = useState(true);
  // const [isChanged, setIsChanged] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  // const [originalValues, setOriginalValues] = useState({
  //   mealId: "",
  //   menuDay: "",
  // });
  const [initialValues, setInitialValues] = useState({});

  const { data: menuData } = useGetMenuByIdQuery(id, { skip: !id });

  const {
    data: menuCategories,
    isSuccess: mcSuccess,
    isFetching: mcLoading,
  } = useGetMenuCategoriesSelectBoxQuery();
  const {
    data: menuPrintStyles,
    isFetching: mpLoading,
    error: mpError,
  } = useGetMenuPrintStylesQuery(id, { skip: !id });

  const statusError =
    STATUS_MESSAGES[mpError?.status] ?? STATUS_MESSAGES.default;

  const [updatePrintStyles, { isLoading }] = useMenuPrintStylesMutation();
  const [deletePrintStyles, { isLoading: deleteLoading }] =
    useDeleteMenuPrintStyleMutation();
  const { data: seasons } = useGetSeasonSelectBoxQuery();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: GROUPING_DEFAULTS,
  });

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, dirtyFields, isDirty },
  } = methods;
  const mealId = watch("mealId");
  const menuDay = watch("menuDay");

  const {
    data: menuItems,
    isFetching: miLoading,
    isSuccess: miSuccess,
  } = useGetMenuItemsQuery(
    {
      menuId: id,
      mealId: mealId ?? 0,
      day: menuDay ?? 0,
    },
    { skip: !isChangeAllowed }
  );

  const [items, setItems] = useState([]);
  const isLoadingState = mcLoading || miLoading || mpLoading;
  useEffect(() => {
    reset({ ...getValues(), ...ONLY_GROUPING_DEFAULTS });
    let printStyles = [];

    if (menuCategories && mcSuccess && !mcLoading) {
      printStyles = [
        ...printStyles,
        ...menuCategories.map((element) => ({
          column: -1,
          name: element.label,
          categoryId: element.id,
          id: element.id,
        })),
      ];
    }

    if (menuItems && miSuccess && !miLoading) {
      printStyles = [
        ...printStyles,
        ...menuItems.flatMap((element) =>
          element.menuItems.map((item) => ({
            column: -1,
            name: item.recipeName,
            recipeId: item.recipeId,
            id: item.recipeId,
            sortOrder: item.sortOrder,
          }))
        ),
      ];
    }

    if (menuPrintStyles) {
      menuPrintStyles.forEach((item) => {
        if (item.mealId == (mealId ?? -1) && item.menuDay == (menuDay ?? -1)) {
          item.groupings.forEach((grouping) => {
            setValue(
              "groupingTitle" + grouping.sortOrder,
              grouping.name === "Name" ? "" : grouping.name
            );
            grouping.items.forEach((recipe) => {
              const recipeId = recipe?.recipeId ?? "";
              const categoryId = recipe?.categoryId ?? "";
              if (recipeId) {
                printStyles = printStyles.filter((x) => x.recipeId != recipeId);
              }
              if (categoryId) {
                printStyles = printStyles.filter(
                  (x) => x.categoryId != categoryId
                );
              }
              printStyles.push({
                column: grouping.sortOrder,
                name: recipe.recipeName || recipe.categoryName,
                id: recipeId || recipe.categoryId,
                categoryId: categoryId || undefined,
                recipeId: recipeId || undefined,
              });
            });
          });
        }
      });
    }
    setItems(printStyles);
    // setIsChanged(false);
    // setHasChange(false);
    // setOriginalValues({ mealId, menuDay });
  }, [
    menuCategories,
    mcLoading,
    mcSuccess,
    menuPrintStyles,
    menuItems,
    // miSuccess,
    miLoading,
    isLoadingState,
  ]);

  const moveCardHandler = (
    dragIndex,
    hoverIndex,
    sourceColumn,
    targetColumn
  ) => {
    setItems((prevState) => {
      // setIsDirty(true);

      const newItems = [...prevState];
      const sourceItems = newItems.filter(
        (item) => item.column === sourceColumn
      );
      const targetItems = newItems.filter(
        (item) => item.column === targetColumn
      );
      const [draggedItem] = sourceItems.splice(dragIndex, 1);
      draggedItem.column = targetColumn;

      if (sourceColumn === targetColumn) {
        sourceItems.splice(hoverIndex, 0, draggedItem);
      } else {
        targetItems.splice(hoverIndex, 0, draggedItem);
      }

      return newItems.map((item) => {
        if (item.column === sourceColumn) {
          return sourceItems.shift();
        } else if (item.column === targetColumn) {
          return targetItems.shift();
        }
        return item;
      });
    });
  };

  const returnItemsForColumn = (columnName) => {
    return items
      .filter((item) => item.column === columnName)
      .map((item, index) => (
        <MovableItem
          id={item.id}
          key={`${item.id}-${index}`}
          name={item.name}
          categoryId={item.categoryId}
          currentColumnName={item.column}
          setItems={setItems}
          index={index}
          moveCardHandler={moveCardHandler}
        />
      ));
  };

  const {
    UnGrouped,
    Grouping1,
    Grouping2,
    Grouping3,
    Grouping4,
    Grouping5,
    Grouping6,
  } = COLUMN_IDS;

  const onSubmit = (data) => {
    setHasChange(false);
    let groupingData = [];
    for (let i = 0; i < 6; i++) {
      groupingData.push({
        name: data["groupingTitle" + i],
        sortOrder: i,
        items: items.filter((x) => x.column == i),
      });
    }
    let newData = {
      menuDay: menuDay ?? -1,
      mealId: mealId ?? -1,
      menuId: id,
      groupings: groupingData,
    };
    updatePrintStyles(newData);
    setValue("mealId", -1);
    setValue("menuDay", 0);
    const updatedItems = items.map((item) => ({ ...item, column: -1 }));

    setItems(updatedItems);
  };

  const currentSeason = seasons?.find(
    (season) => season.id === menuData?.seasonId
  )?.label;

  const MealIds = [
    { id: 1, label: "Breakfast" },
    { id: 2, label: "Lunch" },
    { id: 3, label: "Dinner" },
  ];

  return (
    <>
      <BaseBlockerModal2
        // initialValues={initialValues}
        // watch={watch}
        // getValues={getValues}
        hasChange={hasChange}
        setHasChange={setHasChange}
        // setInitialValues={setInitialValues}
        dirtyFields={dirtyFields}
        isDirty={isDirty}
      />

      <BaseContent
        headerText={"Print Layout Helper"}
        backLink={"/plateful/Menus"}
        disableHeight={true}
      >
        <FormProvider {...methods}>
          <Box
            sx={{
              height: { md: "84.5vh", xl: "89vh" },
            }}
          >
            <Grid container spacing={1} marginBottom={1} padding={1}>
              <Grid item size={{ md: 6, lg: 6, xl: 6 }} fontWeight={"bold"}>
                Season: {currentSeason}
              </Grid>
              <Grid item size={{ md: 6, lg: 6, xl: 6 }} fontWeight={"bold"}>
                Menu: {title}
              </Grid>
              <Grid item size={{ md: 5, lg: 5, xl: 5 }}>
                <BaseSelect
                  size="small"
                  name="mealId"
                  id="mealId"
                  label="Meal"
                  defaultValue={MealIds?.find((x) => x.id == mealId)}
                  options={MealIds}
                />
              </Grid>
              <Grid item size={{ md: 5, lg: 5, xl: 5 }}>
                <BaseInput
                  size="small"
                  name="menuDay"
                  id="menuDay"
                  label="Day"
                  min="1"
                  validationProps={{
                    min: {
                      value: 0,
                      message: "Value must be greater than 0.",
                    },
                    pattern: {
                      value: positiveIntegerNumber,
                      message: "Please enter a positive whole number",
                    },
                  }}
                  type="number"
                />
              </Grid>
              <Grid item size={{ md: 2, lg: 2, xl: 2 }}>
                <BaseSubmitButton
                  isSubmitting={deleteLoading}
                  onClick={() => {
                    deletePrintStyles({
                      menuId: id,
                      mealId: mealId ?? -1,
                      day: menuDay ?? -1,
                    });
                  }}
                  text={"Remove Layout"}
                  color={"error"}
                  endIcon={<span className="material-icons">close</span>}
                />
              </Grid>
            </Grid>
            {mpError ? (
              <FullScreenMessage
                title={mpError.status}
                message={statusError?.message}
                instructions={statusError?.instructions}
                icon={React.createElement(statusError?.icon, {
                  color: "error",
                  style: { fontSize: "50px" },
                })}
              />
            ) : (
              <>
                {isLoadingState ? (
                  <EditSkeleton />
                ) : (
                  <div className="container ">
                    <Grid container spacing={2}>
                      <Grid item size={{ md: 6 }}>
                        <Card sx={{ padding: "24px", marginY: "8px" }}>
                          <Typography
                            variant="h5"
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Recipe/Category Grouping
                          </Typography>
                          <Column
                            name="groupingTitle0"
                            control={control}
                            errors={errors}
                            title={Grouping1}
                            setIsDirty={setHasChange}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(Grouping1)}
                          </Column>
                          <Column
                            name="groupingTitle1"
                            errors={errors}
                            setIsDirty={setHasChange}
                            control={control}
                            title={Grouping2}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(Grouping2)}
                          </Column>
                          <Column
                            name="groupingTitle2"
                            errors={errors}
                            setIsDirty={setHasChange}
                            control={control}
                            title={Grouping3}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(Grouping3)}
                          </Column>
                          <Column
                            name="groupingTitle3"
                            setIsDirty={setHasChange}
                            errors={errors}
                            control={control}
                            title={Grouping4}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(Grouping4)}
                          </Column>
                          <Column
                            name="groupingTitle4"
                            setIsDirty={setHasChange}
                            errors={errors}
                            control={control}
                            title={Grouping5}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(Grouping5)}
                          </Column>
                          <Column
                            name="groupingTitle5"
                            errors={errors}
                            setIsDirty={setHasChange}
                            control={control}
                            title={Grouping6}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(Grouping6)}
                          </Column>
                        </Card>
                      </Grid>
                      <Grid item size={{ md: 6 }}>
                        <Card sx={{ padding: "24px", marginY: "8px" }}>
                          <Typography
                            variant="h5"
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Recipes and Categories
                          </Typography>
                          <Column
                            title={UnGrouped}
                            setIsDirty={setHasChange}
                            className="styledColumn"
                          >
                            {returnItemsForColumn(UnGrouped)}
                          </Column>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </>
            )}
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isLoading}
            />
          </Box>
        </FormProvider>
      </BaseContent>
    </>
  );
}
