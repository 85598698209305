import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  Typography,
} from "@mui/material";
import { BaseButton } from "components/shared/BaseButton";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useAddFacilityDietitianMutation,
  useGetAssignmentDownloadQuery,
  useGetDietitianUserByUserIdQuery,
  useGetFacilitiesByDietitianQuery,
  useGetFacilityDietitianByIdQuery,
  useUpdateFacilityDietitianMutation,
} from "store/apis/DietitianAPis";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import BaseAutoComplete from "components/shared/baseForm/BaseAutoComplete";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { FormProvider, useForm } from "react-hook-form";
import BaseInput from "components/shared/baseForm/BaseInput";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { useGetByIdDefaultOptionQuery } from "store/apis/GenericApis";
import { useGetFacilitySelectBoxQuery } from "store/apis/FacilityApis";
import { useGetUserSelectBoxQuery } from "store/apis/UserApis";
import { COMMUNITY_DIETITIAN_DEFAULTS } from "./communityDietitian.constants";
import { Link, useSearchParams } from "react-router-dom";
// import DeleteButton from "components/shared/DeleteButton";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { API_ENDPOINTS } from "store/store.constants";
import { useSnackbar } from "notistack";
import NearbyDietitianModal from "./NearbyDietitianModal";
import { debounce } from "lodash";
import { positiveNumber } from "utils/ValidationRegex";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CommunityDietitian() {
  const containerRef = useRef(null);
  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  const dietitianId = searchParams.get("dietitianId");
  const facilityId = searchParams.get("facilityId");
  const dietitianFacilityId = searchParams.get("id");
  const isEdit = !!dietitianFacilityId;
  const [defaultDFUser, setDefaultDFUser] = useState([]);
  const [defaultDFFacility, setDefaultDFFacility] = useState([]);

  // enqueueSnackbar
  const { enqueueSnackbar } = useSnackbar();

  // useStates
  const [getAssignment, setGetAssignment] = useState(false);
  const [fileName, setFileName] = useState("");
  const [dietitiansId, setDietitiansId] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [oldSOW, setOldSOW] = useState(null);
  const [facilityDebouncedSearch, setFacilityDebouncedSearch] = useState();
  const [userDebouncedSearch, setUserDebouncedSearch] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [openNearbyModal, setOpenNearbyModal] = useState(false);
  const [defaultValues, setDefaultValues] = useState(
    COMMUNITY_DIETITIAN_DEFAULTS
  );

  // useForm here

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: defaultValues,
  });
  const { handleSubmit, watch, reset, setValue, clearErrors } = methods;

  // watch from useForm
  const watchDietitianId = watch("dietitianId");
  const watchFacilityId = watch("facilityId");
  const watchIsTemporary = watch("isTemporary");
  const watchMilageRate = watch("mileageRatePaidToDietitian");
  const watchHourlyRate = watch("hourlyRatePaidToDietitian");
  const isPositive = watchMilageRate >= 0 && watchHourlyRate >= 0;

  // APIs here
  const {
    data: assignmentURL,
    isFetching: assignmentLoading,
    isSuccess: assignmentSuccess,
  } = useGetAssignmentDownloadQuery(undefined, { skip: !getAssignment });

  const { data: currentDF, isSuccess: currentDFSuccess } =
    useGetFacilityDietitianByIdQuery(dietitianFacilityId, {
      skip: !dietitianFacilityId,
    });

  const {
    data: currentDietitan,
    isFetching: dietitianFetching,
    isError: dietitianError,
    isSuccess: dietitianSuccess,
  } = useGetDietitianUserByUserIdQuery(dietitianId, {
    skip: !dietitianId || dietitianFacilityId,
  });

  const { data: defaultUserOption, isSuccess: defaultUserSuccess } =
    useGetByIdDefaultOptionQuery(
      { url: "userLogin", id: currentDietitan?.dietitianId },
      {
        skip:
          dietitianError ||
          !dietitianSuccess ||
          dietitianFetching ||
          !currentDietitan,
      }
    );

  const { data: defaultFacilityOption, isSuccess: defaultFacilitySuccess } =
    useGetByIdDefaultOptionQuery(
      { url: `${API_ENDPOINTS.FACILITIES}`, id: facilityId },
      {
        skip: !facilityId || dietitianFacilityId,
      }
    );

  const {
    data: facilityList,
    isFetching: loadingFacilities,
    isError: facilitiesError,
  } = useGetFacilitySelectBoxQuery(facilityDebouncedSearch, {
    skip: !facilityDebouncedSearch,
  });

  const {
    data: userList,
    isFetching: loadingUser,
    isError: userError,
  } = useGetUserSelectBoxQuery(userDebouncedSearch, {
    skip: !userDebouncedSearch,
  });

  const [
    addFD,
    {
      isSuccess: addSuccess,
      isLoading: addLoading,
      isError: addIsError,
      error: addError,
    },
  ] = useAddFacilityDietitianMutation();

  const [
    updateFD,
    {
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      isError: updateIsError,
      error: updateError,
    },
  ] = useUpdateFacilityDietitianMutation();

  const {
    data: dFList,
    isFetching: dFListLoading,
    isError: dfError,
  } = useGetFacilitiesByDietitianQuery(dietitiansId, {
    skip: !dietitiansId || dietitiansId === 0 || dietitiansId === "",
    refetchOnMountOrArgChange: true,
  });

  // APIs related
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const isError = addIsError || updateIsError;
  const error = addError || updateError;

  // handleAssignmentDownload function here
  const handleAssignmentDownload = () => {
    if (assignmentSuccess) {
      DownloadLinkHelper(assignmentURL);
      return;
    }
    setGetAssignment(true);
  };

  // file handling here
  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
  };

  // handleEdit functionality
  function handleEdit(data) {
    reset(data);
    setOldSOW(data?.originalFileName);
    setSearchParams({ id: data?.id });
    setUserOptions([{ id: data?.dietitianId, label: data?.dietitianName }]);
    setFacilityOptions([{ id: data?.facilityId, label: data?.facilityName }]);

    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  // Currency formatting
  const currencyFormatter1 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  const currencyFormatter2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 3,
  });

  // DataGrid2 Data
  const dfColumns = [
    {
      field: "isTemporary",
      headerName: "Temp",
      isBoolean: true,
    },
    {
      field: "facilityName",
      headerName: "Community Name",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <div>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {params.row.facilityName}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {params.row.corporateGroup || ""} - {params.row.city} /{" "}
              {params.row.state}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "contractedHours",
      headerAlign: "center",
      headerName: "Contracted Hours/Freq",
      minWidth: 200,
      type: "number",
      renderCell: (params) => {
        return (
          <>{`${params.value.toFixed(2)} / ${
            params?.row?.contractMinimumPer
          }`}</>
        );
      },
    },
    {
      field: "mileageRatePaidToDietitian",
      headerAlign: "center",
      headerName: "Mileage",
      type: "number",
      valueFormatter: (value) => currencyFormatter2.format(value),
    },
    {
      field: "hourlyRatePaidToDietitian",
      headerAlign: "center",
      headerName: "Hourly",
      type: "number",
      valueFormatter: (value) => currencyFormatter1.format(value),
    },
    {
      field: "secureFileName",
      headerAlign: "center",
      headerName: "SOW",
      renderCell: (params) => {
        if (params.value === "") {
          return <></>;
        }
        return (
          <Link
            target="_blank"
            rel="noreferrer"
            // eslint-disable-next-line no-undef
            to={`${process.env.REACT_APP_STORAGE_URL}/dietitiansow/${params.value}`}
          >
            SOW
          </Link>
        );
      },
    },
    {
      field: "actions",
      minWidth: 50,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <BaseActionBox
              index={params.row.id || 0}
              data={dFList}
              hasEdit={true}
              hasDelete={true}
              onClick={() => handleEdit(params.row)}
              deleteProps={{
                entityName: params.row["facilityName"],
                apiPath: "deleteFacilityDietitian",
                title: "community",
              }}
            />
          </>
        );
      },
    },
  ];

  // submit function here
  const onSubmit = (data) => {
    data["facilityId"] = watchFacilityId;

    const data2 = new FormData();
    data2.append("facilityId", watchFacilityId);
    data2.append("dietitianId", watchDietitianId); // data?.dietitianId);
    data2.append(
      "mileageRatePaidToDietitian",
      data?.mileageRatePaidToDietitian ?? 0
    );
    data2.append(
      "hourlyRatePaidToDietitian",
      data?.hourlyRatePaidToDietitian ?? 0
    );
    data2.append("isTemporary", data?.isTemporary ?? false);
    if (data?.dateEndTemporary) {
      data2.append(
        "dateEndTemporary",
        dayjs(data?.dateEndTemporary).isValid()
          ? dayjs(data?.dateEndTemporary).format("YYYY-MM-DD")
          : null
      );
    }
    data2.append("file", uploadedFile);

    if (isEdit) {
      data2.append("id", data?.id);
      updateFD(data2);
    } else {
      addFD(data2);
    }
  };

  // useMemo

  const handleFacilitySearch = useMemo(
    () =>
      debounce((query) => {
        setFacilityDebouncedSearch(query);
      }, 400),
    []
  );
  const handleUserSearch = useMemo(
    () =>
      debounce((query) => {
        setUserDebouncedSearch(query);
      }, 400),
    []
  );

  // useEffect
  useEffect(() => {
    watchDietitianId ? setDietitiansId(watchDietitianId) : null;
  }, [watchDietitianId]);

  useEffect(() => {
    if (currentDFSuccess) {
      setDefaultValues(currentDF);
      reset(currentDF);
      setDefaultDFUser([
        { id: currentDF?.dietitianId, label: currentDF?.dietitianName },
      ]);
      setDefaultDFFacility([
        { id: currentDF?.facilityId, label: currentDF?.facilityName },
      ]);
      setUserOptions([
        { id: currentDF?.dietitianId, label: currentDF?.dietitianName },
      ]);
      setFacilityOptions([
        { id: currentDF?.facilityId, label: currentDF?.facilityName },
      ]);
      if (currentDF?.originalFileName && currentDF?.originalFileName !== "") {
        setOldSOW(currentDF?.originalFileName);
      }
    } else {
      if (defaultUserSuccess) {
        setDefaultDFUser(defaultUserOption);
        reset({ dietitianId: defaultUserOption[0]?.id });
      }
      if (defaultFacilitySuccess) {
        setDefaultDFFacility(defaultFacilityOption);
        setValue("facilityId", defaultFacilityOption[0]?.id);
      }
    }

    if (userList) {
      setUserOptions(userList?.filter((item) => item?.dietitian));
    }

    if (facilityList) {
      setFacilityOptions(facilityList);
    }
  }, [
    currentDFSuccess,
    defaultUserSuccess,
    defaultFacilitySuccess,
    userList,
    facilityList,
    currentDF,
    currentDietitan,
  ]);

  useEffect(() => {
    if (isSuccess && !isSubmitting) {
      setSearchParams({
        // id: null,
        // dietitianId: watchDietitianId,
        // facilityId: watchFacilityId,
      });
      reset(COMMUNITY_DIETITIAN_DEFAULTS);
      setUploadedFile(null);
      setFileName("");
      setOldSOW("");
      setDefaultValues({ id: 0, facilityId: 0 });

      setDefaultDFFacility([
        {
          name: "facilityId",
          option: [
            {
              id: null,
              label: null,
            },
          ],
        },
      ]);
      setDefaultDFUser([
        {
          name: "dietitianId",
          option: [
            {
              id: null,
              label: null,
            },
          ],
        },
      ]);
    }
  }, [isSuccess, isSubmitting]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Community Consultant ${isEdit ? "updated" : "created"} successfully.`,
        { variant: "success" }
      );
    }
  }, [isSuccess]);

  // Modal Functionality
  const handleDietitianChange = (params) => {
    if (!params) {
      return setDefaultDFUser([]);
    }
    setDefaultDFUser(() => [
      {
        id: params?.id,
        label: params?.firstName + " " + params?.lastName,
      },
    ]);
  };
  useEffect(() => {
    setValue("dietitianId", defaultDFUser[0]?.id);
  }, [defaultDFUser]);

  const handleUncheckedConsultant = () => {
    setDefaultDFUser([]);
  };

  const watchDietitanId = watch("dietitianId");
  useEffect(() => {
    if (watchDietitanId) {
      clearErrors("dietitianId");
    }
  }, [watchDietitanId]);

  return (
    <FlexBox
      sx={{
        flexDirection: "column",
        height: "100vh",
        maxHeight: "100vh",
        gap: 2,
      }}
      ref={containerRef}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          flexShrink: 0, // Prevents shrinking
          padding: "8px", // Adds space to prevent overlap
        }}
      >
        <BaseButton
          text="All Assignment List"
          startIcon="file_download"
          endIcon={
            assignmentLoading ? (
              <CircularProgress color="warning" size={"1rem"} />
            ) : null
          }
          onClick={handleAssignmentDownload}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          flexDirection: "column",
          flexShrink: 0,
          gap: 2,
        }}
      >
        <FormProvider {...methods}>
          <Grid2
            container
            spacing={1.5}
            sx={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid2 item size={{ xs: 12, sm: 12, md: 5, xl: 5 }}>
              <BaseAutoComplete
                options={facilityOptions || []}
                handleSearch={handleFacilitySearch}
                defaultOptions={defaultDFFacility}
                formSelectProps={{
                  props: {
                    label: "Community",
                    id: "facilityId",
                    name: "facilityId",
                  },
                  validationProps: {
                    required: REQUIRED_ERROR("Community"),
                  },
                }}
                loadingError={facilitiesError}
                loading={loadingFacilities}
              />
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 12, md: 2, xl: 2 }}>
              <BaseButton
                text="Nearby Consultants"
                onClick={() => setOpenNearbyModal(true)}
              />
              {openNearbyModal && (
                <NearbyDietitianModal
                  open={openNearbyModal}
                  close={() => {
                    setOpenNearbyModal(false);
                  }}
                  handleDietitianChange={handleDietitianChange}
                  facilityId={watchFacilityId}
                  selectedCheckBox={defaultDFUser}
                />
              )}
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 12, md: 5, xl: 5 }}>
              <BaseAutoComplete
                options={userOptions || []}
                handleSearch={handleUserSearch}
                defaultOptions={defaultDFUser}
                formSelectProps={{
                  props: {
                    label: "Consultant",
                    id: "dietitianId",
                    name: "dietitianId",
                  },
                  validationProps: {
                    required: REQUIRED_ERROR("Consultant"),
                  },
                }}
                loadingError={userError}
                loading={loadingUser}
                onClearFunction={handleUncheckedConsultant}
              />
            </Grid2>
          </Grid2>
          <Grid2 container spacing={1.5}>
            <Grid2 item size={{ xs: 12, sm: 12, md: 6, xl: 6 }}>
              <BaseInput
                type="number"
                label="Mileage Rate"
                name="mileageRatePaidToDietitian"
                id="mileageRatePaidToDietitian"
                validationProps={{
                  required: REQUIRED_ERROR("Mileage Rate"),
                  pattern: {
                    value: positiveNumber,
                    message: "Please enter positive number",
                  },
                  maxLength: {
                    value: 15,
                    message: "Maximum 15 digits allowed",
                  },
                }}
                unit={"$"}
                defaultValue={0}
                min={0}
              />
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 12, md: 6, xl: 6 }}>
              <BaseInput
                name="hourlyRatePaidToDietitian"
                id="hourlyRatePaidToDietitian"
                label="Hourly Rate"
                validationProps={{
                  required: REQUIRED_ERROR("Hourly Rate"),
                  pattern: {
                    value: positiveNumber,
                    message: "Please enter positive number",
                  },
                  maxLength: {
                    value: 15,
                    message: "Maximum 15 digits allowed",
                  },
                }}
                type={"number"}
                min={0}
                unit={"$"}
                defaultValue={0}
              />
            </Grid2>
          </Grid2>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BaseCheckBox label={"Is Temporary"} name={"isTemporary"} />

            {watchIsTemporary ? (
              <BaseDatePicker
                label={"Temporary End Date"}
                name={"dateEndTemporary"}
              />
            ) : null}
          </Box>
          <Grid2 container>
            <Grid2 item size={{ xs: 3 }}>
              <Button
                fullWidth
                component="label"
                variant="outlined"
                startIcon={
                  <span className="material-icons">
                    {uploadedFile ? "description" : "border_clear"}
                  </span>
                }
                sx={{ marginRight: "1rem" }}
              >
                {uploadedFile ? `Selected File: ` : `Select File`}
                <input
                  type="file"
                  accept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .png, .jpeg, .jpg"
                  hidden
                  onChange={handleFileUpload}
                />
                {fileName}
              </Button>
            </Grid2>
            <Grid2 item size={{ xs: 8 }}>
              {oldSOW !== null && oldSOW !== "" ? (
                <Typography variant="p">
                  {`Existing SOW file: ${oldSOW}`}
                </Typography>
              ) : null}
            </Grid2>
            <Grid2 item size={{ xs: 1 }}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <BaseSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  disabled={!isPositive}
                  sx={{ marginBottom: 1 }}
                />
              </Box>
            </Grid2>
            {isError ? (
              <Grid2 item size={{ xs: 12 }}>
                <Typography
                  variant={"p"}
                  sx={{
                    color: "error",
                  }}
                >
                  {error?.data?.messages}
                </Typography>
              </Grid2>
            ) : undefined}
          </Grid2>
        </FormProvider>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 550px)",
          flex: 1,
        }}
      >
        <BaseDataGrid
          rows={dFList}
          loading={dFListLoading}
          error={dfError}
          columns={CreateBaseColumns(dfColumns)}
          autoHeight={false}
        />
      </Box>
    </FlexBox>
  );
}
