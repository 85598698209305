import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPE_DIETS, RECIPES, FACILITY_DIETS } = API_ENDPOINTS;

export const PlateFulRecipeDietAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeDietList: builder.query({
      query: ({ recipeId, facilityId }) =>
        `${RECIPE_DIETS}?recipeId=${recipeId}&facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RECIPE_DIETS,
                id,
              })),
              RECIPE_DIETS,
            ]
          : [RECIPE_DIETS];
      },
    }),
    getRecipeDietById: builder.query({
      query: (dietId) => `/${RECIPE_DIETS}/${dietId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RECIPE_DIETS, id: response.id }, RECIPE_DIETS]
          : [RECIPE_DIETS],
    }),
    deleteRecipeDiet: builder.mutation({
      query: (id) => ({
        url: `/${RECIPE_DIETS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_DIETS, id: arg.id },
      ],
    }),
    addRecipeDiet: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_DIETS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RECIPE_DIETS, id: result?.id }],
    }),
    updateRecipeDiet: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_DIETS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RECIPE_DIETS, id: result?.id }],
    }),
    getAlternativeRecipeSearchList: builder.query({
      query: ({ userId, facilityId, searchText }) =>
        `${RECIPES}?userId=${userId}&facilityId=${facilityId}&skip=0&take=200&usedInMenuPlannerOnly=false&searchText=${searchText}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: res.description,
          };
        }),
      // providesTags: (response) =>
      //   response?.data
      //     ? [
      //         ...response.data.map(({ id }) => ({
      //           type: API_ENDPOINTS.RECIPES,
      //           id,
      //         })),
      //         API_ENDPOINTS.RECIPES,
      //       ]
      //     : [API_ENDPOINTS.RECIPES],
    }),
    getMealExtrasRecipeSearchList: builder.query({
      query: ({ userId, facilityId, searchText }) =>
        `${RECIPES}/SearchMealExtras?userId=${userId}&facilityId=${facilityId}&searchText=${searchText}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: res.description,
          };
        }),
    }),

    getFacilityDietsSelectBox: builder.query({
      query: ({ searchTerm, facilityId }) =>
        `/${FACILITY_DIETS}/search?search=${
          searchTerm || " "
        }&facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data.map((x) => ({
          label: `${x.dietName}${x.isTexture ? " (texture)" : ""}`,
          id: x.dietId,
          isTexture: x.isTexture,
          description: `${x.commonDietName}`,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: FACILITY_DIETS, id })),
              FACILITY_DIETS,
            ]
          : [FACILITY_DIETS];
      },
    }),
  }),
});
export const {
  useGetRecipeDietListQuery,
  useGetRecipeDietByIdQuery,
  useDeleteRecipeDietMutation,
  useAddRecipeDietMutation,
  useUpdateRecipeDietMutation,
  useGetAlternativeRecipeSearchListQuery,
  useGetMealExtrasRecipeSearchListQuery,
  useGetFacilityDietsSelectBoxQuery,
} = PlateFulRecipeDietAPIs;
