import { React } from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { ResidentInformationFields } from "./forms.constants";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useFormContext } from "react-hook-form";

export default function ResidentInformationForm({
  isEdit,
  facilityLoading,
  canEdit,
}) {
  const { register } = useFormContext();
  const { formTextFields, formTextFields4, formSelectProps } =
    ResidentInformationFields;
  const gender = [
    {
      id: "M",
      label: "Male",
    },
    {
      id: "F",
      label: "Female",
    },
  ];

  const StyledBaseInput = styled(BaseInput)`
    .MuiInputBase-root {
      background-color: ${({ ...props }) =>
        (props.checkEMREdit ? canEdit : true) ? undefined : "lightgrey"};
    }
  `;
  const StyledBaseSelect = styled(BaseSelect)`
    .MuiInputBase-root {
      background-color: ${({ ...props }) =>
        (props.checkEMREdit ? canEdit : true) ? undefined : "lightgrey"};
    }
  `;

  return (
    <>
      {isEdit && (
        <input
          hidden
          {...register("id", { required: REQUIRED_ERROR("Id") })}
        ></input>
      )}
      {formTextFields.map((data, index) => {
        const props = data?.props;
        const isChecked = (props.checkEMREdit ? canEdit : true)
          ? undefined
          : true;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item size={{ xs: 5 }} />;
          case "date":
            return (
              <Grid key={index} item size={{ xs: 5 }}>
                <BaseDatePicker
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  disabled={props.checkEMREdit ? !canEdit : false}
                  sx={{
                    background: (props.checkEMREdit ? canEdit : true)
                      ? undefined
                      : "lightgrey",
                    width: "100%",
                  }}
                />
              </Grid>
            );
          default:
            return (
              <Grid key={index} item size={{ xs: 5 }}>
                {isChecked ? (
                  <StyledBaseInput
                    {...props}
                    name={props?.name}
                    validationProps={data?.validationProps}
                    disabled={props.checkEMREdit ? !canEdit : false}
                  />
                ) : (
                  <BaseInput
                    {...props}
                    name={props?.name}
                    validationProps={data?.validationProps}
                    disabled={props.checkEMREdit ? !canEdit : false}
                  />
                )}
              </Grid>
            );
        }
      })}

      {formTextFields4.map((data, index) => {
        const props = data?.props;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item size={{ xs: 5 }} />;
          case "date":
            return (
              <Grid key={index} item size={{ xs: 5 }}>
                <BaseDatePicker
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                />
              </Grid>
            );
          default:
            return (
              <Grid key={index} item size={{ xs: 5 }}>
                <BaseInput
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                />
              </Grid>
            );
        }
      })}

      <Grid item size={{ xs: 5 }}>
        <StyledBaseSelect
          options={gender}
          loading={facilityLoading}
          validationProps={formSelectProps?.validationProps}
          label={formSelectProps?.props?.label}
          name={formSelectProps?.props?.name}
          id={formSelectProps?.props?.id}
          disabled={formSelectProps?.props?.checkEMREdit ? !canEdit : false}
        />
      </Grid>
    </>
  );
}
