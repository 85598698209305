/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Constants/TimeIntervalList.md)
 */
export const TIME_INTERVAL_LIST = [
  {
    label: "Week",
    id: "W",
    frequencyLabel: "Weekly",
  },
  {
    label: "Month",
    id: "M",
    frequencyLabel: "Monthly",
  },
  {
    label: "Quarter",
    id: "Q",
    frequencyLabel: "Quarterly",
  },
  {
    label: "Semi-Annual",
    id: "S",
    frequencyLabel: "Semi-Annual",
  },
  {
    label: "Annual",
    id: "A",
    frequencyLabel: "Annual",
  },
  {
    label: "Undefined",
    id: "U",
  },
];
