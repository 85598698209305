import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { MATRIX_MANAGEMENTS } = API_ENDPOINTS;

export const MatrixManagementApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatrixResidentList: builder.query({
      query: ({ facilityId }) =>
        `/${MATRIX_MANAGEMENTS}?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 30,
    }),
    updateMatrixResidentList: builder.mutation({
      query: ({ matrixFacilityId, facilityId, data }) => ({
        url: `/${MATRIX_MANAGEMENTS}?matrixFacilityId=${matrixFacilityId}&facilityId=${facilityId}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response?.data
    }),
  }),
});

export const { useGetMatrixResidentListQuery, useUpdateMatrixResidentListMutation } =
MatrixManagementApi;
