import React, { memo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const DietInformationSection = ({
  residentId,
  resident,
  facilityDiets,
  facilityTextures,
  residentDetails,
}) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: 1,
        borderRadius: "4px",
        marginLeft: 1,
        boxShadow: "0px 4px 4px 0px #00000040",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            backgroundColor: "#F8D061",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
            borderRadius: "4px 4px 0px 0px",
          }}
        >
          <span style={{ fontSize: "22px", fontWeight: "700" }}>
            Diet Information
          </span>
          <Link
            to={`/MealCard/addResident?id=${residentId}&step=2&mode=only`}
            className="newEditButton"
          >
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Edit
            </Typography>
          </Link>
        </Box>

        <Grid container spacing={1} sx={{ padding: "0.5rem 1rem" }}>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.5rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Diet:
            </Typography>
            <Typography>
              {facilityDiets
                ? facilityDiets.find((diet) => diet.dietId === resident.dietId)
                    ?.dietName
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.5rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Diet Texture:
            </Typography>
            <Typography>
              {facilityTextures
                ? facilityTextures.find(
                    (texture) => texture.dietId === resident.dietTextureId
                  )?.dietName
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.5rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Diet Other:
            </Typography>
            <Typography>{resident.dietOther}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.5rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Liquid Consistency:
            </Typography>
            <Typography>
              {residentDetails ? residentDetails.liquidConsistency : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ padding: "0rem 1rem" }}>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.7rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              NPO:
            </Typography>
            <Typography>{resident.isNpo ? "Yes" : "No"}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.7rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Tube Feeding:
            </Typography>
            <Typography>{resident.isTubeFeeding ? "Yes" : "No"}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.7rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Fluid Restriction:
            </Typography>
            <Typography>{resident.hasFluidRestrict ? "Yes" : "No"}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} sx={{ display: "flex", gap: "0.7rem" }}>
            <Typography component="span" sx={{ fontWeight: 700 }}>
              Fluid Restriction Quantity:
            </Typography>
            <Typography>{resident.restrictQty}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default memo(DietInformationSection);
