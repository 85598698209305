import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseCheckBox.md)
 */
export default function BaseCheckBox({
  errors,
  control,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  validationProps,
  size,
  fontSize,
  defaultValue,
  helperTextAdjust = false, //to realign when used inline with textfield+helperText, provides 1.25rem (20px) offset
  ...rest
}) {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });

  const { label, name, id } = rest;
  const handleKeyDown = (event, onChange, checked) => {
    if (event.key === " ") {
      event.preventDefault();
      onChange(!checked);
    }
  };

  const error = getNestedProperty(formErrors, name);

  const renderCheckbox = (checked, onChange) => (
    <Checkbox
      error={!!error?.message || ""}
      checked={checked}
      onChange={(event) => {
        onChange(event.target.checked);
      }}
      onKeyDown={(event) => handleKeyDown(event, onChange, checked)}
      size={size || "medium"}
      sx={{
        padding: 0,

        "& .MuiSvgIcon-root": { fontSize: fontSize },
      }}
      inputProps={{
        "aria-label": label,
      }}
      {...rest}
    />
  );

  const renderLabel = () => (
    <Typography sx={{ fontSize: fontSize }}>{label}</Typography>
  );

  return (
    <FormControl
      sx={{
        height: "100%",
        justifyContent: "center",
        paddingBottom: helperTextAdjust ? "1.25rem" : 0,
      }}
    >
      {controlMethod ? (
        <Controller
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={renderCheckbox(value, onChange)}
              label={renderLabel()}
              sx={{ fontSize: "10px", marginLeft: 0 }}
            />
          )}
          control={controlMethod}
          name={name || id}
          defaultValue={defaultValue || false}
          rules={validationProps}
        />
      ) : (
        <FormControlLabel
          control={renderCheckbox(rest?.checked, rest?.onChange)}
          label={label}
        />
      )}
    </FormControl>
  );
}
