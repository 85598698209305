import { REQUIRED_ERROR, MAX_LENGTH_VALIDATION } from "utils/errorMessages";
import { FORM_TYPES } from "../../../shared/generatedForm/GeneratedForm";

export const recipeCheckboxes = [
  {
    props: {
      id: "baseRecipe",
      name: "baseRecipe",
      label: "Base Recipe",
      fontSize: "16px",
    },
    size: 3,
    type: "checkbox",
  },
  {
    props: {
      name: "includeInMenuPlanning",
      id: "includeInMenuPlanning",
      label: "In Menu Planner",
      fontSize: "15px",
    },
    type: "checkbox",
    size: 3,
  },
  {
    props: {
      name: "bulkPrint",
      id: "bulkPrint",
      label: "Print Recipe",
      fontSize: "16px",
    },
    type: "checkbox",
    size: 3,
  },
  {
    props: {
      name: "isThinLiquid",
      id: "isThinLiquid",
      label: "Thin Liquid",
      fontSize: "16px",
    },
    type: "checkbox",
    size: 3,
  },
];

export const recipeFields = [
  {
    props: {
      name: "menuCategoryId",
      id: "menuCategoryId",
      label: "Menu Category",
    },
    validationProps: {
      required: REQUIRED_ERROR("Menu Category"),
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getMenuCategoriesSelectBox",
    size: 4,
  },
  {
    props: {
      name: "textureId",
      id: "textureId",
      label: "IDDSI Texture",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getTexturesSelectBox",
    size: 4,
  },
  {
    props: {
      name: "description",
      id: "description",
      label: "Recipe Description",
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Recipe Description", 150),
    },
    size: 12,
  },
  {
    props: {
      name: "portionSize",
      id: "portionSize",
      label: "Portion Size",
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Portion Size", 50),
    },
    size: 4,
  },
  {
    props: {
      name: "baseNumOfServings",
      id: "baseNumOfServings",
      label: "Number Served",
      type: "number",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Number Served"),
      min: {
        value: 1,
        message: "Base number of servings is required to be more than 0",
      },
    },
    size: 4,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 4,
  },
  {
    props: {
      name: "smallPortionSize",
      id: "smallPortionSize",
      label: "Small Portion",
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Small Portion Size", 50),
    },
    size: 4,
  },
  {
    props: {
      name: "smallPortionNumOfServings",
      id: "smallPortionNumOfServings",
      label: "Small Portion Number Served",
      type: "number",
      defaultValue: 0,
    },
    size: 4,
    validationProps: {
      required: REQUIRED_ERROR("Small Portion Number Served"),
    },
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 4,
  },
  {
    props: {
      name: "largePortionSize",
      id: "largePortionSize",
      label: "Large Portion",
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Large Portion Size", 50),
    },
    size: 4,
  },
  {
    props: {
      name: "largePortionNumOfServings",
      id: "largePortionNumOfServings",
      label: "Large Portion Number Served",
      type: "number",
      defaultValue: 0,
    },
    size: 4,
    validationProps: {
      required: REQUIRED_ERROR("Large Portion Number Served"),
    },
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 4,
  },
  {
    props: {
      name: "panSizeId",
      id: "panSizeId",
      label: "Cooking Appliance/Equipment",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getPanSizesSelectBox",
    size: 6,
  },
  {
    props: {
      name: "cookingTemperature",
      id: "cookingTemperature",
      label: "Cooking Temperature",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getCookingTempSelectBox",
    size: 6,
  },
  {
    props: {
      name: "advancedPreparation",
      id: "advancedPreparation",
      label: "Advanced Preparations",
      multiline: true,
      rows: 3,
    },
    size: 12,
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Advanced Preparations", 200),
    },
  },
  {
    props: {
      name: "variations",
      id: "variations",
      label: "Recipe Variations",
      multiline: true,
      rows: 2,
    },
    size: 12,
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Recipe Variations", 500),
    },
  },
  {
    props: {
      name: "isArchived",
      id: "isArchived",
      label: "Archived",
    },
    type: "checkbox",
    size: 12,
  },
];

export const recipeFieldsCol2 = [
  {
    props: {
      name: "directions",
      id: "directions",
      label: "Recipe Instruction",
      multiline: true,
      rows: 9,
    },
    size: 12,
  },
  {
    props: {
      name: "descriptionForPrintedMenus",
      id: "descriptionForPrintedMenus",
      label: "Description For Posted Menu",
      multiline: true,
      rows: 2,
    },
    size: 12,
  },
];

export const defaultRecipeFields = {
  description: "",
  isPureedRecipe: false,
  isThinLiquid: false,
  bulkPrint: false,
  includeInMenuPlanning: false,
  applicableToFluidRestriction: false,
  baseRecipe: false,
  menuCategoryId: "",
  textureId: 1,
  portionSize: "",
  baseNumOfServings: 0,
  smallPortionSize: "",
  smallPortionNumOfServings: 0,
  largePortionSize: "",
  largePortionNumOfServings: 0,
  panSizeId: "",
  cookingTemperature: "",
  variations: "",
  directions: "",
  descriptionForPrintedMenus: "",
  advancedPreparation: "",
  isArchived: false,
};
