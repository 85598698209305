import { Box, Button, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetVisitDetailsQuery,
  useGetVisitReportsQuery,
  useGenerateVisitReportMutation,
} from "../../../../../../store/apis/ConsultantApis";
import { isEmpty, isUndefined, size } from "lodash";
import { BaseButton } from "../../../../../shared/BaseButton";
import { SCREEN_CONSTANT } from "../../constants";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { Roles, SCREEN_CONSTANTS } from "components/shared/constants";
import { buttonsData } from "../visitReport/constants";
import { WarningText } from "components/shared/WarningText";
import useMsalAccount from "utils/useMsalAccount";
import { BackButton } from "components/shared/BackButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { Print } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { userAllowedWithRoles } from "./form.constants";
import { getLink } from "components/consultant/utils/linkHelper";
import HeaderBoxVisitDetails from "./shared/HeaderBoxVisitDetails";
import VisitHoursTable from "./shared/VisitHoursTable";
import VerifyHoursModal from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitDetails/shared/VerifyHoursModal";
import useModal from "components/shared/useModal";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export const VisitDetails = () => {
  // States
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userId, roles } = useMsalAccount();

  const { isOpen, openModal, closeModal } = useModal(false);
  const {
    isOpen: reportToDiningRDModal,
    openModal: openReportToDiningRDModal,
    closeModal: closeReportToDiningRDModal,
  } = useModal(false);

  const isAdmin = roles?.includes(Roles.Admin);
  const isConsultantManager = roles?.includes([
    Roles.Dietitian_Global_Admin,
    Roles.Dietitian_Regional_Admin,
  ]);

  const isAdminOrConsultantManager = isAdmin || isConsultantManager;

  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);

  // Api Section
  // api to get the visit Details
  const {
    data: visitData,
    isLoading: hasLoading,
    isError: hasError,
  } = useGetVisitDetailsQuery(visitPlanId);
  // Destructuring
  const {
    dietitianReportSendLog,
    visitHours,
    nextConsult,
    dateOfVisit,
    community,
    totalBillableHour,
    totalOtherQuantity,
    totalMileage,
    id,
    isFinal,
    isVisitFinished,
    isFinalDate,
    dateFinished,
    hasBeenExportedToSage,
  } = visitData || [];

  const editableRole = isFinal
    ? isFinal
    : isVisitFinished
    ? !roles.some((roleToCheck) => userAllowedWithRoles.includes(roleToCheck))
    : false;

  const {
    data: visitDetailsData,
    isLoading: visitLoading,
    isError: visitError,
  } = useGetVisitReportsQuery(id, {
    skip: !id,
  });
  const isSummaryReport = visitDetailsData?.some(
    (data) => data.visitReportTypeId === 1 || data.visitReportTypeId === 6
  );

  // Mutation Section
  // mutation to generate Report
  const [
    GenerateReport,
    {
      data: visitReport,
      isError: visitReportError,
      isSuccess: isVisitSuccess,
      isLoading: visitReportLoading,
      error: visitTypeError,
    },
  ] = useGenerateVisitReportMutation();

  // Function which will be called mutation Function
  const onSubmit = (ID, e) => {
    e.preventDefault();
    GenerateReport({
      id: ID,
    });
  };

  // Veriables
  const isError = visitError || hasError || visitReportError;
  const isLoading = visitLoading || visitReportLoading;
  const error = visitTypeError;
  // useEffect Section
  useEffect(() => {
    if (isVisitSuccess) {
      DownloadLinkHelper(visitReport?.data);
    }
    if (isError && error && error?.data?.messages) {
      enqueueSnackbar(`Report Does not Exist`, {
        variant: "warning",
      });
    }
  }, [isError, error, visitReport]);

  useEffect(() => {
    if (
      !isAdmin &&
      !isUndefined(visitData?.dietitianId) &&
      visitData?.dietitianId != userId
    )
      navigate("/forbidden", { state: { isAuthorized: false } });
  }, [userId, isAdmin, visitData]);

  // Column Section For Data Grid
  const viewReportColumns = [
    {
      field: "visitReportType",
      minWidth: 200,
      flex: 1,
      headerName: "Report Types",
    },
    {
      field: "hasAttachments",
      flex: 0.5,
      headerName: "Attachments?",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      isBoolean: true,
    },
    {
      field: "description",
      minWidth: 200,
      flex: 3,
      headerName: "Description",
    },

    {
      field: "actions",
      minWidth: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            childrenLocation={"start"}
            // data={MenusData}
            hasEdit={!editableRole}
            index={params.row.id}
            editProps={{
              title: "Visit Report",
              To: getLink(params, visitPlanId),
            }}
            hasDelete={!editableRole}
            deleteProps={{
              entityName: params.row?.visitReportType,
              apiPath: "deleteVisitReport",
            }}
          >
            <Button
              component={Link}
              color={"primary"}
              onClick={(e) => {
                e.preventDefault();
                onSubmit(params.row.id, e);
              }}
              variant="contained"
              endIcon={<Print />}
            >
              Print
            </Button>
          </BaseActionBox>
        );
      },
    },
  ];
  const viewReportColumn = [
    {
      field: "senderEmail",
      headerName: "From",
      flex: 2,
    },
    {
      field: "sentTo",
      flex: 3,
      headerName: "To",
      renderCell: ({ value }) => {
        const formattedEmails = value
          ?.split(",")
          .map((email) => email.trim())
          .join("\n");
        return (
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {formattedEmails}
          </div>
        );
      },
    },
    {
      field: "dateSent",
      headerName: "Date Sent",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const isDateValid = dayjs(row.dateSent).isValid();
        return isDateValid
          ? dayjs(row.dateSent).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "reportsSent",
      flex: 2,
      headerName: "Report Sent",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
  ];

  // Ui Section
  return (
    <>
      <Box>
        <BackButton To={"/Consultant/MyRosters"} iconDisabled={true} />
      </Box>
      <Box>
        <HeaderBoxVisitDetails
          community={community}
          dateOfVisit={dateOfVisit}
          isFinal={isFinal}
          isExported={hasBeenExportedToSage}
          isAdminOrConsultantManager={isAdminOrConsultantManager}
          visitPlanId={visitPlanId}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <VisitHoursTable
            hasLoading={hasLoading}
            visitHours={visitHours}
            nextConsult={nextConsult}
            totalMileage={totalMileage}
            totalBillableHour={totalBillableHour}
            totalOtherQuantity={totalOtherQuantity}
            submittedDate={isFinalDate}
            finishedDate={dateFinished}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: !isFinal ? "center" : "",
            }}
          >
            <Box
              sx={{
                width: "90%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: 4,
                alignItems: "center",
              }}
            >
              {visitDetailsData ? (
                !isFinal ? (
                  buttonsData?.map((data, index) =>
                    (data?.visitReportTypeId == 1 ||
                      data?.visitReportTypeId == 6) &&
                    isSummaryReport ? null : (
                      <Box
                        key={index}
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <BaseButton
                          colors={data.color}
                          startIcon={"add"}
                          text={data.text}
                          To={`${data.Link}?visitPlanId=${visitPlanId}&visitId=${id}&visitReportTypeId=${data?.visitReportTypeId}`}
                        />
                      </Box>
                    )
                  )
                ) : (
                  // <Box width={"100%"}>
                  <WarningText
                    margin={"0px"}
                    text={
                      "These reports have been submitted to DiningRD; no further changes can be made."
                    }
                    color={"warning"}
                  />
                  // </Box>
                )
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              marginY: "10px",
            }}
          >
            {!isUndefined(visitDetailsData) && !isEmpty(visitDetailsData) ? (
              <BaseDataGrid
                rows={visitDetailsData || []}
                columns={CreateBaseColumns(viewReportColumns)}
                loading={isLoading}
                error={isError}
                height={"100%"}
                getRowId={(row) => row.id}
              />
            ) : null}
          </Box>
          <Box
            sx={{
              marginY: "10px",
            }}
          >
            {!isEmpty(dietitianReportSendLog) ? (
              <BaseDataGrid
                rows={dietitianReportSendLog || []}
                columns={CreateBaseColumns(viewReportColumn)}
                loading={hasLoading}
                error={hasError}
                height={"100%"}
                getRowId={(row) => row.id}
                getRowHeight={() => "auto"}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: 1,
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: 2,
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: 3,
                  },
                }}
              />
            ) : null}
          </Box>
        </Box>
        {size(visitDetailsData) > 0 ? (
          <FlexBox mt={1} justifyContent={"flex-end"}>
            <Tooltip
              title={
                !isSummaryReport
                  ? "This report cannot be submitted until a Visit Summary has been created."
                  : null
              }
              placement="top"
            >
              <Box>
                <BaseButton
                  text={"Submit Report to Community"}
                  onClick={openModal}
                  disabled={!isSummaryReport}
                />
              </Box>
              <VerifyHoursModal
                isOpen={isOpen}
                closeModal={closeModal}
                visitHours={visitData?.visitHours}
                text="Send Reports To Community"
                link={`/Consultant/MyRosters/VisitDetails/SendReport?visitPlanId=${visitPlanId}&isEdit=true`}
                editLink={`/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${visitPlanId}&isEdit=${true}`}
                planDate={dateOfVisit}
              />
              <VerifyHoursModal
                isOpen={reportToDiningRDModal}
                closeModal={closeReportToDiningRDModal}
                visitHours={visitData?.visitHours}
                text="Submit Report to DiningRD"
                link={`/Consultant/MyRosters/VisitDetails/SubmitReportToHealthTech?visitPlanId=${visitPlanId}`}
                editLink={`/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${visitPlanId}&isEdit=${true}`}
                planDate={dateOfVisit}
              />
            </Tooltip>
            {!isFinal ? (
              <Tooltip
                title={
                  !isSummaryReport
                    ? "This report cannot be submitted until a Visit Summary has been created."
                    : null
                }
                placement="top"
              >
                <Box>
                  <BaseButton
                    text={"Submit Report to DiningRD"}
                    disabled={!isSummaryReport}
                    onClick={openReportToDiningRDModal}
                    toolTip={!isSummaryReport}
                  />
                </Box>
              </Tooltip>
            ) : null}
          </FlexBox>
        ) : null}
      </Box>
    </>
  );
};
