// Icons from here https://fonts.google.com/icons
export const ICONS_LIST = [
  { id: `restaurant`, icon: `restaurant`, label: `Restaurant` },
  { id: `favorite`, icon: `favorite`, label: `Heart` },
  { id: `dining`, icon: `dining`, label: `Dining` },
  { id: `flatware`, icon: `flatware`, label: `Utensils` },  
  { id: `local_hospital`, icon: `local_hospital`, label: `Medical` },
  { id: `lunch_dining`, icon: `lunch_dining`, label: `Lunch` },
  { id: `star`, icon: `star`, label: `Star` }, 
  { id: `yard`, icon: `yard`, label: `Plant` }, 
  { id: `eco`, icon: `eco`, label: `Eco` }, 
  { id: `egg`, icon: `egg`, label: `Egg` }, 
  // { id: `nutrition`, icon: `nutrition`, label: `Nutrition` }, 
  { id: `psychology`, icon: `psychology`, label: `Psychology` }, 
  { id: `free_breakfast`, icon: `free_breakfast`, label: `Breakfast Dining` }, 
  { id: `local_grocery_store`, icon: `local_grocery_store`, label: `Grocery` },
  { id: `mood`, icon: `mood`, label: `Mood` }, 
  { id: `cookie`, icon: `cookie`, label: `Cookie` }, 
  // { id: `metabolism`, icon: `metabolism`, label: `Metabolism` }, 
  // { id: `gastroenterology`, icon: `gastroenterology`, label: `Gastroenterology` }, 
  
];
