import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddFacilityConsultingTypeMutation,
  useFacilityConsultingTypeByIdQuery,
  useUpdateFacilityConsultingTypeMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { EditSkeleton } from "../../../../../admin.overlays";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { TextFieldsList } from "./formConstants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";

export default function AddCommunityConsulting() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const consultingTypeId = searchParams.get("consultingTypeId");

  const isEdit = !!consultingTypeId;

  const {
    data: facilityConsultingType,
    isFetching: isFetchingConsultingType,
    isSuccess: isSuccessConsultingType,
  } = useFacilityConsultingTypeByIdQuery(consultingTypeId, {
    skip: !isEdit,
  });

  const isEditLoading = isFetchingConsultingType && isEdit;

  const [
    addFacilityConsultingType,
    {
      isError: isAddError,
      isSuccess: isAddSuccess,
      isSubmitting: isAddSubmitting,
      error: addError,
    },
  ] = useAddFacilityConsultingTypeMutation();
  const [
    updateFacilityConsultingType,
    {
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      isSubmitting: isUpdateSubmitting,
      error: updateError,
    },
  ] = useUpdateFacilityConsultingTypeMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isSubmitting = isAddSubmitting || isUpdateSubmitting;
  const isError = isAddError || isUpdateError;
  const error = addError || updateError;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    isSuccess ? navigate(-1) : null;
  }, [isSuccess]);

  useEffect(() => {
    if (
      facilityConsultingType &&
      !isFetchingConsultingType &&
      isSuccessConsultingType
    ) {
      reset(facilityConsultingType);
    }
  }, [
    facilityConsultingType,
    isFetchingConsultingType,
    isSuccessConsultingType,
  ]);

  const onSubmit = (data) => {
    data.facilityId = id;

    if (isEdit) {
      data.id = consultingTypeId;
      updateFacilityConsultingType(data);
      return;
    }
    addFacilityConsultingType(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <React.Fragment>
          <Grid container spacing={1.5} sx={{ width: "60%" }}>
            <GeneratedForm
              oldGrid={false}
              list={TextFieldsList}
              control={control}
              errors={errors}
            />

            <Grid item size={{ xs: 10 }}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Grid>
            {isError ? (
              <Grid item size={{ sx: 12 }}>
                <Typography
                  sx={{
                    color: "error",
                  }}
                >
                  {error?.data?.messages}
                </Typography>
              </Grid>
            ) : undefined}
          </Grid>
        </React.Fragment>
      )}
    </>
  );
}
