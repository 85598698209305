import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function TableSideInformation({ residentId, resident }) {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: 1,
        borderRadius: "4px",
        marginLeft: 1,
        boxShadow: "0px 4px 4px 0px #00000040",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          style={{ borderRadius: "4px 4px 0px 0px" }}
          sx={{
            backgroundColor: "#C4E1E2",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <span style={{ fontSize: "22px", fontWeight: "700" }}>
            TableSide Information
          </span>
          <Link
            to={`/MealCard/addResident?id=${residentId}&step=3&mode=only`}
            className="newEditButton"
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}
            >
              Edit
            </Typography>
          </Link>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px 16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}
            >
              Monthly TableSide Allowance:
            </Typography>
            <Typography>
              {currencyFormatter.format(resident.monthlyTableSideBudget)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}
            >
              Monthly TableSide Balance:
            </Typography>
            <Typography>
              {currencyFormatter.format(resident.tableSideBalance)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}
            >
              Declining Balance Start Day:
            </Typography>
            <Typography>{resident.tableSideBudgetStartDay}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}
          ></Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
