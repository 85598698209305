import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  FormControl,
  Autocomplete,
  TextField,
  Typography,
  Box,
  InputAdornment,
} from "@mui/material";
import { Controller } from "react-hook-form";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";
import { isNil } from "lodash";

function AutocompleteInput({
  control,
  errors,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  validationProps,
  rest,
  options,
  defaultValue,
  // for auto selection when clicked TAB button
  setSelectedValue,

  variant = "outlined",
  bootstrap = false,
}) {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });

  const { name, disableClearable, loading } = rest;
  const error = getNestedProperty(formErrors, name);
  const isBootstrapStyle = bootstrap && variant === "standard";
  const article = rest.articleAn ? "an" : "a";

  const renderInput = (params) => {
    function GetIcon() {
      const selectedValue =
        options?.find(
          (x) =>
            x.id == params?.inputProps?.value ||
            x.label == params?.inputProps?.value
        ) || null;

      if (selectedValue?.icon) {
        if (typeof selectedValue?.icon === "string") {
          if (
            selectedValue?.icon.startsWith("http://") ||
            selectedValue?.icon.startsWith("https://")
          ) {
            return (
              <InputAdornment position="start">
                <img
                  src={selectedValue?.icon}
                  alt={params?.inputProps?.label}
                />
              </InputAdornment>
            );
          } else {
            return (
              <InputAdornment position="start">
                <span className="material-icons">{selectedValue?.icon}</span>
              </InputAdornment>
            );
          }
        } else {
          return (
            <InputAdornment position="start">
              <selectedValue.icon />
            </InputAdornment>
          );
        }
      }
      return null;
    }

    const handleKeyUp = (e) => {
      const selectValue =
        options?.find((x) => x.id === e.target.value) ||
        options?.find((x) => x.label === e.target.value) ||
        defaultValue;
      const filtered = options?.filter((option) =>
        option.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSelectedValue(filtered.length === 1 ? filtered[0] : selectValue);
    };

    return (
      <TextField
        {...params}
        label={rest.label}
        variant={variant}
        bootstrap={bootstrap.toString()}
        placeholder={
          rest?.placeholder || `Select ${article} ${rest?.label || name}`
        }
        error={!!error || false}
        helperText={error?.message || ""}
        // below onKeyUp code is for auto selection when clicked TAB button
        onKeyUp={handleKeyUp}
        slotProps={{
          input: {
            ...params.InputProps,
            startAdornment: <GetIcon />,
            disableUnderline: isBootstrapStyle,
          },
          inputLabel: isBootstrapStyle
            ? {
                shrink: true,
                variant: variant,
              }
            : { variant: variant },
        }}
      />
    );
  };

  const renderOption = (props, option, index) => {
    const { label, description, id: optionId, icon } = option;

    function RenderIcon() {
      if (icon) {
        if (typeof icon === "string") {
          if (icon.startsWith("http://") || icon.startsWith("https://")) {
            return <img src={icon} alt={label} />;
          }
          return <span className="material-icons">{icon}</span>;
        }
        return icon;
      }
      return null;
    }

    const key = `AutoComplete-${optionId}-${index?.index}`;
    return (
      <li {...props} key={key}>
        <Grid container direction={"column"}>
          <Grid
            sx={{
              width: "100%",
              wordWrap: "break-word",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <RenderIcon />
              <Typography
                variant="p"
                sx={{
                  fontWeight: description ? "bold" : "regular",
                }}
              >
                {label}
              </Typography>
            </Box>
            {description && (
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {description}
              </Typography>
            )}
          </Grid>
        </Grid>
      </li>
    );
  };

  if (controlMethod) {
    return (
      <Controller
        control={controlMethod}
        name={name}
        rules={validationProps}
        defaultValue={defaultValue?.id}
        render={({ field: { onChange, value } }) => (
          <>
            <Autocomplete
              disablePortal
              disableClearable={disableClearable || false}
              onChange={(_, item) => {
                onChange(isNil(item?.id) || item?.id === "" ? null : item?.id);
                if (!item) {
                  setSelectedValue({ id: null, label: "" });
                }
              }}
              getOptionDisabled={(option) => option.disabled}
              value={options?.find((option) => option.id === value) || null}
              loading={loading}
              options={options}
              getOptionLabel={(item) => item?.label}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || value.id === null
              }
              {...rest}
              // below onKeyUp code is for auto selection when clicked TAB button
              onKeyUp={(e) => {
                const item = options?.find(
                  (option) => option.label === e.target.value
                );

                if (item == undefined) {
                  const filtered = options?.filter((option) =>
                    option.label.toLowerCase().includes(e.target.value)
                  );

                  if (Object.entries(filtered).length == 1)
                    onChange(filtered[0]?.id);
                } else onChange(item?.id);
              }}
              renderInput={renderInput}
              renderOption={renderOption}
            />
          </>
        )}
      />
    );
  }

  return (
    <Autocomplete
      disableClearable={disableClearable || false}
      {...rest}
      fullWidth
      disablePortal
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  );
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseSelect.md)
 */
export default function BaseSelect({
  control,
  errors,

  options,
  defaultValue,
  // eslint-disable-next-line no-unused-vars
  validationProps,
  articleAn = false,
  ...props
}) {
  // below code is for auto selection when clicked TAB button
  const [selectedValue, setSelectedValue] = useState({ id: null, label: "" });

  useEffect(() => {
    const selectedValueChange = (value) => {
      const selectValue =
        options?.find((x) => x.id == value) ||
        options?.find((x) => x.label == value) ||
        defaultValue;
      setSelectedValue(selectValue);
    };
    selectedValueChange(defaultValue?.id);
  }, [options, defaultValue]);
  // ends code here Tab
  return (
    <FormControl
      sx={{
        ...props.sx,
        ...(props.hideOutline && {
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
        }),
      }}
      fullWidth
    >
      <AutocompleteInput
        control={control}
        errors={errors}
        rest={{ ...props, articleAn }}
        validationProps={validationProps}
        options={options}
        // below 2 code lines is for auto selection when clicked TAB button
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
}
