/* eslint-disable */
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect, useState } from "react";
import { userInformation } from "./forms.constants";
import { useFormContext } from "react-hook-form";
import { Grid, Grid2 } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export const UserInformationV2 = ({ defaultValues }) => {
  const [searchparams] = useSearchParams();
  const [hiddenList, setHiddenList] = useState([]);
  const editId = searchparams.get("id");

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const ssoValue = watch("sso");
  const userSSOId = watch("userSSOId");

  useEffect(() => {
    let hiddenFields = [];

    if (!!editId) {
      hiddenFields.push({ name: "password" });
    }

    if (!ssoValue) {
      hiddenFields.push({ name: "userSSOId" });
    }

    setHiddenList(hiddenFields);
  }, [editId, ssoValue]);

  useEffect(() => {
    if (userSSOId) {
      setValue("sso", true);
    }
  }, [userSSOId, setValue]);

  useEffect(() => {
    if (!ssoValue) {
      setValue("userSSOId", null);
    }
  }, [ssoValue, setValue]);

  return (
    <Grid container spacing={2}>
      <GeneratedForm
        list={userInformation}
        control={control}
        errors={errors}
        hiddenList={hiddenList}
        defaultOptions={defaultValues}
      />
    </Grid>
  );
};
