import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import {
  MAX_VALUE_VALIDATION,
  MIN_VALUE_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const addVendorFields = [
  {
    props: {
      name: "vendorId",
      id: "vendorId",
      label: "Vendor",
    },
    validationProps: {
      required: REQUIRED_ERROR("Vendor"),
    },
    size: 7,
    endpoint: "getVendorSelectBox",
    type: FORM_TYPES.SelectAPI,
  },
  {
    props: {
      name: "itemNumber",
      id: "itemNumber",
      label: "Vendor Item #",
    },
    validationProps: {
      required: REQUIRED_ERROR("Vendor Item #"),
      min: MIN_VALUE_VALIDATION("Vendor Item #", 1),
    },
    min: 1,
    size: 7,
    type: "number",
  },
  {
    props: {
      name: "price",
      id: "price",
      label: "Price",
      type: "number",
      defaultValue: 0,
    },
    validationProps: {
      min: MIN_VALUE_VALIDATION("Price", 0),
      max: MAX_VALUE_VALIDATION("Price", 1000),
    },

    unit: "$",
    unitPosition: "start",
    size: 7,
  },
  {
    props: {
      name: "packSize",
      id: "packSize",
      label: "Pack Size",
      placeholder: "Pack Size",
    },
    size: 7,
  },
  {
    props: {
      name: "quantity",
      id: "quantity",
      label: "Quantity",
      placeholder: "Quantity",
      type: "number",
      defaultValue: 0,
    },
    size: 7,
  },
  {
    props: {
      name: "unitId",
      id: "unitId",
      label: "Units",
    },
    validationProps: {
      required: REQUIRED_ERROR("Units"),
    },
    size: 7,
    endpoint: "getUnits",
    type: FORM_TYPES.SelectAPI,
  },
];
