import React, { useState } from "react";
import { Box } from "@mui/material";
import { useGetDietitianRegionListQuery } from "../../../../../store/apis/DietitianAPis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSearchParams } from "react-router-dom";
export default function DietitianRegion() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const {
    data: dietitianRegions,
    isLoading: isLoading,
    isError: isError,
  } = useGetDietitianRegionListQuery({
    search: debouncedValue,
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });
  const dietitianRegionsData = dietitianRegions?.items;
  // console.log("dietitianRegionsData", isLoading, dietitianRegionsData);

  const DietitianRegionColumns = [
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "regionName",
      headerName: "Primary Region",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Regional Manager",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row["regionManagerId"] > 0
          ? `${params.row["regionManagerLastName"]}, ${params.row["regionManagerFirstName"]}`
          : "Not Assigned";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.dietitianId}
            data={dietitianRegionsData}
            hasEdit={true}
            editProps={{
              To: `dietitianEdit?id=${params.row.dietitianId}`,
              title: "Dietitian Region",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <BaseSearch
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />
      </Box>
      <BaseDataGrid
        rows={dietitianRegionsData}
        loading={isLoading}
        columns={DietitianRegionColumns}
        error={isError}
        autoHeight={false}
        height={{ md: "58vh", xl: "60vh" }}
        rowCount={dietitianRegions?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
}
