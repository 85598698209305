import React from "react";
import { Modal, Box, Button, Typography, Divider, Fade } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import "@fontsource/material-icons";
import "./FormModal.css";
import GeneratedForm from "../generatedForm/GeneratedForm";
import BaseInput from "../baseForm/BaseInput";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { positiveDecimalInteger } from "utils/ValidationRegex";
import BaseSelect from "../baseForm/BaseSelect";

export default function FormModal({
  list,
  list2,
  units,
  defaultValues,
  id,
  open,
  close,
  isEdit,
  onClickBtn,
  HeaderTitle,
  editData,
}) {
  const renderQuantityUnitGroup = (
    quantityNum,
    defaultQty = 1,
    minValue = 0.1
  ) => (
    <>
      <Grid item size={{ xs: 3, md: 3, lg: 3, xl: 3 }}>
        <Typography variant="p" fontWeight="bold">
          Quantity {quantityNum}
        </Typography>
        <BaseInput
          name={`quantity${quantityNum === 2 ? "2" : ""}`}
          id={`quantity${quantityNum === 2 ? "2" : ""}`}
          type="number"
          defaultValue={defaultQty}
          validationProps={{
            required: REQUIRED_ERROR(`Quantity ${quantityNum}`),
            min: {
              value: minValue,
              message: `Value must be ${
                minValue === 0 ? "equal or " : ""
              }greater than ${minValue}`,
            },
            ...(quantityNum === 1 && {
              pattern: {
                value: positiveDecimalInteger,
                message: "Invalid number format",
              },
            }),
          }}
        />
      </Grid>
      <Grid item size={{ xs: 5 }}>
        <Typography variant="p" fontWeight="bold">
          Unit {quantityNum}
        </Typography>
        <BaseSelect
          name={`unitId${quantityNum === 2 ? "2" : ""}`}
          placeholder="Select a Unit"
          id={`unitId${quantityNum === 2 ? "2" : ""}`}
          validationProps={
            quantityNum !== 2
              ? {
                  required: REQUIRED_ERROR(`Unit ${quantityNum}`),
                }
              : undefined
          }
          options={units}
        />
      </Grid>
      <Grid item size={{ xs: 4 }} />
    </>
  );

  return (
    <Modal
      open={open}
      onClose={close}
      className="formModalBody"
      aria-labelledby={`delete-modal-title-${id}`}
      aria-describedby={`delete-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"formModal"}>
          <Box className="model-title">
            <Typography variant="h6" component="h2" className="modalTitle">
              {HeaderTitle}
            </Typography>
            <Link onClick={() => close(true)}>
              <span className="modalTitle material-icons">{"close"}</span>
            </Link>
          </Box>

          <Box className="formModalContent">
            <p className="modalTitle">{editData?.ingredient || ""}</p>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GeneratedForm
                oldGrid={false}
                list={list}
                defaultOptions={defaultValues}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {renderQuantityUnitGroup(1)}
              {renderQuantityUnitGroup(2, 0, 0)}
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GeneratedForm
                oldGrid={false}
                list={list2}
                defaultOptions={defaultValues}
              />
            </Grid>
          </Box>

          <Divider sx={{ my: 0.5 }} />

          <Box
            className="formActions"
            sx={{
              flex: true,
            }}
          >
            <Button
              className="cancelButton"
              variant="contained"
              onClick={() => close(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitButton"
              variant="contained"
              onClick={onClickBtn}
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
