import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  useGetFinishedReportsQuery,
  useUnlockFinishedReportsMutation,
} from "../../../../../store/apis/DietitianAPis";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import CreateBaseColumns, {
  COLUMN_DATE_FORMATTER,
  DATAGRID_COLUMN_TYPES,
} from "components/shared/CreateBaseColumn";

export default function RecipeCategories() {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const {
    data: finishedReports,
    isLoading: isLoading,
    isError: isGetError,
  } = useGetFinishedReportsQuery();

  const [
    unlockFinishedReports,
    { isError: isError, isLoading: isSubmitting, isSuccess },
  ] = useUnlockFinishedReportsMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  const onSubmit = () => {
    unlockFinishedReports(rowSelectionModel);
  };

  useEffect(() => {
    if (isSuccess) {
      setRowSelectionModel([]);
    }
  }, [isSuccess]);

  const FinishedReportColumns = [
    {
      field: "facilityName",
      headerName: "Community",
      flex: 3,
    },
    {
      field: "dietitianName",
      headerName: "Consultant",
      flex: 2,
    },
    {
      field: "dateOfVisit",
      headerName: "Visit Date",
      type: DATAGRID_COLUMN_TYPES.DATE,
      dateFormatter: COLUMN_DATE_FORMATTER.LOCALE_DATE,
    },
    {
      field: "dateFinished",
      headerName: "Date Finished",
      type: DATAGRID_COLUMN_TYPES.DATE,
      dateFormatter: COLUMN_DATE_FORMATTER.LOCALE_DATE,
    },
  ];

  return (
    <>
      <BaseDataGrid
        autoHeight={false}
        height={{ md: "54vh", xl: "55vh" }}
        rows={finishedReports}
        loading={isLoading}
        columns={CreateBaseColumns(FinishedReportColumns)}
        error={isGetError}
        checkboxSelection
        getRowId={(row) => row.visitId}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
      <Box sx={{ pt: 2 }}>
        <BaseSubmitButton
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          disabled={rowSelectionModel.length == 0}
          text={"Unlock Reports"}
        />
      </Box>
      {isError ? (
        <Typography
          sx={{
            color: "error",
          }}
        >
          There was an error submitting the form, please try again.
        </Typography>
      ) : undefined}
    </>
  );
}
