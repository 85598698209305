import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { DiningFields } from "./forms.constants";

import { useResidentDiningTableSelectQuery } from "store/apis/ResidentApis";
import { useFormContext } from "react-hook-form";

export default function DiningForm({ diningmeal, decliningBalanceEnabled }) {
  const { watch, setValue, getValues } = useFormContext();

  const { formTextFields, diningCheckBoxes } = DiningFields;

  const [menuChecked, setMenuChecked] = useState(
    diningCheckBoxes?.map((item) => {
      return getValues(item.props?.name);
    })
  );

  const breakfastDiningAreaId = watch("breakfastDiningAreaId");
  const lunchDiningAreaId = watch("lunchDiningAreaId");
  const supperDiningAreaId = watch("supperDiningAreaId");

  const [initialBreakfastAreaId, setInitialBreakfastAreaId] = useState(null);
  const [initialLunchAreaId, setInitialLunchAreaId] = useState(null);
  const [initialSupperAreaId, setInitialSupperAreaId] = useState(null);
  const { data: breakfastTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: breakfastDiningAreaId,
    },
    { skip: !breakfastDiningAreaId }
  );
  const { data: lunchTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: lunchDiningAreaId,
    },
    { skip: !lunchDiningAreaId }
  );

  const { data: supperTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: supperDiningAreaId,
    },
    { skip: !supperDiningAreaId }
  );

  // Reset table selection when dining area changes so that if no table is selected, a null value will be saved.
  useEffect(() => {
    if (breakfastDiningAreaId && !initialBreakfastAreaId) {
      setInitialBreakfastAreaId(breakfastDiningAreaId);
    }
    if (
      initialBreakfastAreaId &&
      breakfastDiningAreaId !== initialBreakfastAreaId
    ) {
      setValue("breakfastTableId", null);
      setValue("breakfastTable", "");
    }
  }, [breakfastDiningAreaId, initialBreakfastAreaId]);

  useEffect(() => {
    if (lunchDiningAreaId && !initialLunchAreaId) {
      setInitialLunchAreaId(lunchDiningAreaId);
    }
    if (initialLunchAreaId && lunchDiningAreaId !== initialLunchAreaId) {
      setValue("lunchTableId", null);
      setValue("lunchTable", "");
    }
  }, [lunchDiningAreaId, initialLunchAreaId]);

  useEffect(() => {
    if (supperDiningAreaId && !initialSupperAreaId) {
      setInitialSupperAreaId(supperDiningAreaId);
    }
    if (initialSupperAreaId && supperDiningAreaId !== initialSupperAreaId) {
      setValue("supperTableId", null);
      setValue("supperTable", "");
    }
  }, [supperDiningAreaId, initialSupperAreaId]);

  function handleMenuChange(event, index) {
    const { checked } = event.target;

    if (index === -1) {
      setMenuChecked(
        diningCheckBoxes?.map((item) => {
          setValue(item.props?.name, checked);
          return checked;
        })
      );
      return;
    }

    const newMenuChecked = [...menuChecked];
    newMenuChecked[index] = checked;
    setMenuChecked(newMenuChecked);
    setValue(diningCheckBoxes[index].props?.name, checked);
  }

  return (
    <>
      {decliningBalanceEnabled
        ? formTextFields.map((data, index) => {
            const props = data?.props;
            return (
              <Grid key={index} item size={{ xs: 8 }}>
                <BaseInput
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                />
              </Grid>
            );
          })
        : undefined}
      {diningCheckBoxes?.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item size={{ xs: 8 }}>
            <BaseCheckBox
              key={props?.name}
              {...props}
              checked={getValues(props?.name) ? true : false}
              onChange={() => handleMenuChange(event, index)}
              name={props?.name}
              validationProps={data?.validationProps}
            ></BaseCheckBox>
          </Grid>
        );
      })}

      <Grid item size={{ xs: 12 }}>
        <div className="TableRow dining-table">
          <Grid
            className="dining-table-column dining-table-bg-color"
            item
            size={{ xs: 2 }}
          >
            <Typography className="dining-table-cell"></Typography>
            <Typography className="dining-table-cell dining-table-bg-color">
              Breakfast
            </Typography>
            <Typography className="dining-table-cell dining-table-bg-color">
              Lunch
            </Typography>
            <Typography className="dining-table-cell dining-table-bg-color">
              Dinner
            </Typography>
          </Grid>
          <Grid className="dining-table-column" item size={{ xs: 3 }}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Dining Area
            </Typography>
            <BaseSelect
              options={diningmeal || []}
              name={"breakfastDiningAreaId"}
              id={"breakfastDiningAreaId"}
              placeholder="Choose"
              defaultValue={null}
              hideOutline={true}
            />
            <BaseSelect
              options={diningmeal || []}
              name={"lunchDiningAreaId"}
              id={"lunchDiningAreaId"}
              placeholder="Choose"
              defaultValue={null}
              hideOutline={true}
            />
            <BaseSelect
              options={diningmeal || []}
              name={"supperDiningAreaId"}
              id={"supperDiningAreaId"}
              placeholder="Choose"
              defaultValue={null}
              hideOutline={true}
            />
          </Grid>

          <Grid className="dining-table-column" item size={{ xs: 2 }}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Table
            </Typography>
            <BaseSelect
              options={breakfastDiningAreaId ? breakfastTables : []}
              name={"breakfastTableId"}
              id={"breakfastTableId"}
              placeholder="Select"
              defaultValue={null}
              hideOutline={true}
            />
            <BaseSelect
              options={lunchDiningAreaId ? lunchTables : []}
              name={"lunchTableId"}
              id={"lunchTableId"}
              placeholder="Select"
              defaultValue={null}
              hideOutline={true}
            />
            <BaseSelect
              options={supperDiningAreaId ? supperTables : []}
              name={"supperTableId"}
              id={"supperTableId"}
              placeholder="Select"
              defaultValue={null}
              hideOutline={true}
            />
          </Grid>
          <Grid className="dining-table-column" item size={{ xs: 2 }}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Seat
            </Typography>
            <BaseInput
              name={"breakfastSeat"}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 10,
                  message: "Breakfast Seat must be 10 characters or less",
                },
              }}
              hideOutline={true}
            />
            <BaseInput
              name={"lunchSeat"}
              defaultValue={""}
              hideOutline={true}
              validationProps={{
                maxLength: {
                  value: 10,
                  message: "Lunch Seat must be 10 characters or less",
                },
              }}
            />
            <BaseInput
              name={"supperSeat"}
              defaultValue={""}
              hideOutline={true}
              validationProps={{
                maxLength: {
                  value: 10,
                  message: "Dinner Seat must be 10 characters or less",
                },
              }}
            />
          </Grid>

          <Grid className="dining-table-column" item size={{ xs: 2 }}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Fluid Restriction
            </Typography>
            <BaseInput
              name={"breakfastRestrictQty"}
              defaultValue={""}
              hideOutline={true}
              validationProps={{
                maxLength: {
                  value: 20,
                  message:
                    "Breakfast Fluid Restriction Quantity must be less than 20 characters",
                },
              }}
            />
            <BaseInput
              name={"lunchRestrictQty"}
              defaultValue={""}
              hideOutline={true}
              validationProps={{
                maxLength: {
                  value: 20,
                  message:
                    "Lunch Fluid Restriction Quantity must be less than 20 characters",
                },
              }}
            />
            <BaseInput
              name={"supperRestrictQty"}
              defaultValue={""}
              hideOutline={true}
              validationProps={{
                maxLength: {
                  value: 20,
                  message:
                    "Dinner Fluid Restriction Quantity must be less than 20 characters",
                },
              }}
            />
          </Grid>
        </div>
      </Grid>
      <Grid item size={{ xs: 8 }}>
        <BaseCheckBox
          name="printTrayCard"
          id="printTrayCard"
          label="Print Meal Card"
          defaultValue={true}
        />
      </Grid>
    </>
  );
}
