import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  Modal,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useCopyToCorporateGroupMutation,
  useFacilityDietsQuery,
  useGetFacilityByIdQuery,
  useUpdateFacilityDietsIDDSIMutation,
  useFacilityFeatureByFlagQuery,
  useAddFacilityFeaturesMutation,
  useDeleteFacilityFeatureFlagMutation,
} from "../../../../../../store/apis/FacilityApis";

import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../../shared/YesNoDisplay";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { CopyAll, PostAdd, Report } from "@mui/icons-material";

export default function CommunityDiets() {
  const [isterminology, setIsterminology] = useState(false);
  const [isApproveMyMenu, setIsApproveMyMenu] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  const [addIDDSI] = useUpdateFacilityDietsIDDSIMutation();

  const [addApproveMyMenu] = useAddFacilityFeaturesMutation();
  const [deleteApproveMyMenu] = useDeleteFacilityFeatureFlagMutation();

  const {
    data: diets,
    isFetching: fetching,
    isError: hasError,
  } = useFacilityDietsQuery(id, { refetchOnMountOrArgChange: true, skip: !id });

  const { data: facilityFeature, isSuccess: featureSuccess } =
    useFacilityFeatureByFlagQuery(
      { facilityId: id, featureFlag: "ApproveMyMenu" },
      { skip: !id }
    );

  const onSubmit = (value) => {
    addIDDSI({ useIDDSITerminology: value, facilityId: id });
  };

  const onSubmitApproveMyMenu = (facilityFeature) => {
    if (facilityFeature != null && facilityFeature?.id > 0) {
      deleteApproveMyMenu(facilityFeature?.id);
    } else {
      addApproveMyMenu({
        id: 0,
        facilityId: id,
        featureFlag: "ApproveMyMenu",
        effectiveDate: null,
      });
    }
  };

  useEffect(() => {
    if (communitySuccess && community?.useIddsiterminology !== null) {
      setIsterminology(community?.useIddsiterminology);
    }
  }, [communitySuccess]);

  useEffect(() => {
    if (featureSuccess && facilityFeature !== null) {
      setIsApproveMyMenu(true);
    }
  }, [featureSuccess]);

  const dietColumns = [
    {
      field: "commonDietName",
      headerName: "Common Diet Name",
      flex: 1,
    },
    {
      field: "isTexture",
      headerName: "Is Texture",
      flex: 1,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.isTexture} />;
      },
    },
    {
      field: "dietName",
      headerName: "Community Diet Name",
      flex: 1,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 0.5,
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={diets}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addcommunitydiet?id=${id}&fdId=${params.row.id}`,
              title: params.row.dietName,
            }}
            deleteProps={{
              hintText: `Are you sure you want to delete "${params.row?.dietName}"?`,
              apiPath: "deleteFacilityDiet",
              entityName: params.row?.dietName,
              title: "Corporate Group",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={{ md: "center", xs: "start" }}
        paddingBottom={2}
      >
        <FlexBox
          justifyContent={"space-between"}
          alignItems={"center"}
          width={{ xs: "100%", md: "80%", lg: "60%" }}
        >
          <BaseButton
            text={"Add Community Diet"}
            endIcon={<PostAdd />}
            To={`addcommunitydiet?id=${id}`}
          />
          <Box width={"60%"} display={"flex"} justifyContent={"space-between"}>
            <div style={{ width: 250 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isApproveMyMenu}
                    onClick={() => {
                      setIsApproveMyMenu(!isApproveMyMenu);
                      onSubmitApproveMyMenu(facilityFeature);
                    }}
                  />
                }
                label="Approve My Menu"
              />
            </div>
            <div style={{ width: 250 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isterminology}
                    onClick={() => {
                      setIsterminology(!isterminology);
                      onSubmit(!isterminology);
                    }}
                  />
                }
                label="Use IDDSI Terminology"
              />
            </div>
          </Box>
        </FlexBox>
        <Box
          sx={{
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <BaseButton
            sx={{ width: { xs: "100%", sm: "auto" } }}
            text={"Copy to Corporate Group"}
            onClick={() => setOpenCopyModal(true)}
            endIcon={<CopyAll />}
            colors="error"
            marginLeft={"0px !important"}
          />
        </Box>
      </FlexBox>
      {openCopyModal && (
        <CopyToCorporateGroupModal
          open={openCopyModal}
          close={() => setOpenCopyModal(false)}
          cgName={communitySuccess && community?.corporateGroup}
          facilityName={communitySuccess && community?.name}
          id={id}
        />
      )}
      <BaseDataGrid
        rows={diets}
        loading={fetching}
        error={hasError}
        columns={dietColumns}
      />
    </>
  );
}

function CopyToCorporateGroupModal({ facilityName, cgName, id, open, close }) {
  const [startCopy, { isLoading, isSuccess }] =
    useCopyToCorporateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <FlexBox
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}
          >
            <Report color="error" sx={{ fontSize: "90px" }} />
            <Box>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h6"
                component="h2"
                className="modalTitle"
                sx={{
                  textAlign: "center",
                }}
              >
                {`Diets for "${facilityName}"`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                {`This will remove the diets for all communities that are linked to the "${cgName}" Corporate Group, and replace it with the diets that are currently setup for this community. Are you sure you want to continue?`}
              </Typography>
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox my={"10px"} px={"24px"} justifyContent={"end"}>
            <Button variant="contained" onClick={close} color={"warning"}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                startCopy(id);
              }}
              startIcon={
                isLoading ? <CircularProgress color="white" size={18} /> : ""
              }
              color={"error"}
            >
              Continue
            </Button>
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}
