import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPE_TAGS } = API_ENDPOINTS;

export const RecipeTagsAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeTagsList: builder.query({
      query: ({ id, facilityId }) => `/${RECIPE_TAGS}?recipeId=${id}&facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_TAGS, id })),
              RECIPE_TAGS,
            ]
          : [RECIPE_TAGS];
      },
    }),
    addRecipeTags: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_TAGS}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [{ type: RECIPE_TAGS, id: result?.id }],
    }),
    deleteRecipeTags: builder.mutation({
      query: (id) => ({
        url: `/${RECIPE_TAGS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_TAGS, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetRecipeTagsListQuery,
  useAddRecipeTagsMutation,
  useDeleteRecipeTagsMutation,
} = RecipeTagsAPIs;
