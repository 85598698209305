import { useMsal } from "@azure/msal-react";
import { AuthError, EventMessageUtils, EventType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "authConfig";

import { useEffect } from "react";
import { appInsights } from "utils/ApplicationInsightsService";

export const useMsalEvents = (pca) => {
  const { inProgress } = useMsal();

  useEffect(() => {
    if (pca === null) return;

    // Default to using the first account if no account is active on page load.
    const accounts = pca.getAllAccounts();

    const impersonatedAccount = accounts.find(
      (account) => !!account?.idTokenClaims?.impersonatedUser
    );

    const activeAccount =
      impersonatedAccount ||
      pca.getActiveAccount() ||
      (accounts.length > 0 ? accounts[0] : null);
    pca.setActiveAccount(activeAccount);

    // Optional - This will update account state if a user signs in from another tab or window
    pca.enableAccountStorageEvents();

    const callbackId = pca.addEventCallback(async (event) => {
      const { eventType, payload, error } = event;

      const status = EventMessageUtils.getInteractionStatusFromEvent(event);

      switch (eventType) {
        // When MSAL Instance is initialized check if the impersonated user is to be logged in.
        case EventType.INITIALIZE_END:
          // if (isImpersonated && !activeAccount) {
          //   const request = {
          //     authority: b2cPolicies.authorities.logonAs.authority,
          //     scopes: loginRequest.scopes,
          //     extraQueryParameters: {
          //       targetEmail: impersonatedUserName,
          //       // eslint-disable-next-line no-undef
          //       server: process.env.REACT_APP_BASE,
          //     },
          //   };

          // //   // If the error still occurs we can add an error handler for the loginPopup user cancel flow
          //   await pca.loginPopup(request).catch((error) => {
          //     console.error(error);
          //   });
          // }
          break;
        case EventType.ACCOUNT_REMOVED: {
          break;
        }
        case EventType.ACCOUNT_ADDED: {
          const pcaAccounts = pca.getAllAccounts();
          const impersonatedAccount = pcaAccounts.find(
            (account) => !!account?.idTokenClaims?.impersonatedUser
          );
          await pca.acquireTokenSilent({
            ...loginRequest,
            account: payload,
            // Remove this if breaks in other places.
            forceRefresh: !impersonatedAccount,
          });
          break;
        }
        case EventType.ACTIVE_ACCOUNT_CHANGED: {
          const pcaAccounts = pca.getAllAccounts();
          const impersonatedAccount = pcaAccounts.find(
            (account) => !!account?.idTokenClaims?.impersonatedUser
          );
          if (pcaAccounts?.length > 1) {
            await pca.acquireTokenSilent({
              ...loginRequest,
              account: impersonatedAccount || pcaAccounts[0],
              // Remove this if breaks in other places.
              forceRefresh: !impersonatedAccount,
            });
          }
          break;
        }
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
          if (payload?.account) {
            const { account } = payload;
            const { idTokenClaims } = account;

            if (
              idTokenClaims?.extension_DiningManagerUserId == -1 &&
              status === null
            ) {
              await pca.clearCache();

              await pca.acquireTokenRedirect({
                ...loginRequest,
                forceRefresh: true,
              });
              break;
            }

            pca.setActiveAccount(account);

            const email =
              idTokenClaims?.impersonatedUser ||
              idTokenClaims?.email ||
              idTokenClaims?.signInName;
            const userId = idTokenClaims?.extension_DiningManagerUserId
              ? idTokenClaims?.extension_DiningManagerUserId.toString()
              : null;

            // Setup appInsights
            appInsights.setAuthenticatedUserContext(email, userId, true);
          }
          break;

        case EventType.LOGOUT_SUCCESS:
          // Checking if this is a signin/out flow to avoid removing all accounts in case of impersonation.
          if (
            payload?.account?.tenantId ===
            b2cPolicies.names.signUpSignIn.toLowerCase()
          ) {
            await pca.clearCache();
            //Save SSO domain_hint value to replace after LS/SS clear
            const domain_hint = localStorage.getItem('domain_hint');
            
            window.localStorage.clear();
            window.sessionStorage.clear();

            if (domain_hint) {
              window.localStorage.setItem('domain_hint', domain_hint);
            }
          }
          await pca.clearCache({ account: payload?.account });
          break;

        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
          if (error?.errorMessage?.includes("AADB2C90118")) {
            await pca.loginRedirect({
              authority: b2cPolicies.authorities.forgotPassword.authority,
              scopes: [],
              forceRefresh: true,
            });
          } else if (error && error instanceof AuthError) {
            console.error("Login failure", error);
          } else {
            console.error("Login failure", error || "No error message");
          }

          break;
        case EventType.ACQUIRE_TOKEN_FAILURE:
          console.error("Failed trying to acquire token....retrying", error);
          await pca.loginRedirect(loginRequest);
          break;
      }
    });

    return () => {
      // This will be run on component unmount
      pca.disableAccountStorageEvents();
      if (callbackId) {
        pca.removeEventCallback(callbackId);
      }
    };
  }, [pca, inProgress]);
};
