import React from "react"
import { Box, Typography } from "@mui/material";

export default function SSOError() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: 2,
      }}
    >
      <Typography variant="h5" color="error" gutterBottom>
        Access Denied
      </Typography>
      <Typography variant="body1">
        We are not able to allow access at this time. Please login via your Identity Provider using your Single Sign-On credentials.
      </Typography>
      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Please close this tab and login as suggested.
      </Typography>
    </Box>
  );
}
