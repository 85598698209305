// React Hook Imports
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useCustomDrop } from "../../../../shared/customeHooks/useCustomDrop";
// MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fade } from "@mui/material";
//  Custom Component Import
import { RecipeCard } from "./RecipeCard";
import { BaseButton } from "../../../../shared/BaseButton";
// Slice Imports
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";
// Lodash Imports
import isEmpty from "lodash/isEmpty";
// import size from "lodash/size";
import { isEqual, isNil } from "lodash";

export const MealViewModal = ({
  open,
  close,
  rows,
  column,
  menuPlannerData,
  setMenuPlannerData,
}) => {
  // states Declaration
  const [holdingRecipe, setHoldingRecipe] = useState([{ recipes: [] }]);

  const dispatch = useDispatch();
  const holdingArea = JSON.parse(localStorage.getItem(`holdingArea`));
  // deep cloning
  const menuPlanner = structuredClone(menuPlannerData);
  const holdingAreaClone = useMemo(() => {
    return JSON.parse(localStorage.getItem("holdingArea")) || [{ recipes: [] }];
  }, []);
  // function to store data in local storage
  const updateLocalStorage = useCallback((recipes) => {
    if (recipes?.[0]?.recipes?.length) {
      recipes[0].recipes = recipes[0].recipes.filter(Boolean);
    }
    setHoldingRecipe(recipes);
    localStorage.setItem(`holdingArea`, JSON.stringify(recipes));
  }, []);

  // useEffect Section
  useEffect(() => {
    isNil(holdingArea)
      ? setHoldingRecipe([{ recipes: [] }])
      : setHoldingRecipe(holdingArea);
  }, []);
  // when new data insert in localStorage then this event will be trigger
  useEffect(() => {
    const storageListener = (event) => {
      isEqual(event.key, "holdingArea")
        ? setHoldingRecipe(JSON.parse(event.newValue))
        : null;
    };

    // Add event listener for storage changes
    window.addEventListener("storage", storageListener);
    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);
  //  This function would be call when Recipe card drop in meal Area
  const statusChangeThisMeal = (items, monitor) => {
    const item = items?.[0];

    //find out if they dropped it in the dead zone at the bottom of the list
    //to update index accordingly (end of array), hover callback in recipe card may have never fired
    const el = document.getElementById("thisMeal");
    const bottomBox = el?.lastElementChild?.getBoundingClientRect();
    const currentBottomYThreshold = bottomBox?.bottom - bottomBox?.height / 2;

    if (monitor.getClientOffset()?.y > currentBottomYThreshold) {
      item.index =
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes?.length;
    }

    dispatch(setIsChangeTrue(true));
    let duplicatedRecipes =
      menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    duplicatedRecipes = duplicatedRecipes.some(
      (x) => x.recipeId == item?.finalData?.recipeId
    );
    if (!duplicatedRecipes) {
      let previousRecipes = holdingRecipe[0]?.recipes?.filter(
        (individualRecipe) =>
          individualRecipe?.recipeId !== item?.finalData?.recipeId
      );
      holdingAreaClone[0].recipes = previousRecipes;
      setHoldingRecipe([holdingAreaClone[0]]);
      // updateLocalStorage([holdingAreaClone[0]]);
      let updatedRecipes = structuredClone(
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes
      );
      updatedRecipes.splice(item.index, 0, item?.finalData);
      menuPlanner.resultSet[rows].days[column].recipes = updatedRecipes;
      setMenuPlannerData(menuPlanner);
    }
  };

  //  this function would be call when Recipe card drop in Holding Area
  const statusChange = (items, monitor) => {
    const item = items?.[0];

    const el = document.getElementById("holdingArea");
    const bottomBox = el?.lastElementChild?.getBoundingClientRect();
    const currentBottomYThreshold = bottomBox?.bottom - bottomBox?.height / 2;

    if (monitor.getClientOffset()?.y > currentBottomYThreshold) {
      item.index = holdingRecipe[0]?.recipes?.length;
    }

    dispatch(setIsChangeTrue(true));
    let duplicatedRecipes = holdingRecipe[0]?.recipes?.some(
      (x) => x.recipeId == item?.finalData?.recipeId
    );
    if (!duplicatedRecipes) {
      let previousRecipes =
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
      previousRecipes = previousRecipes?.filter(
        (individualRecipe) =>
          individualRecipe?.recipeId !== item?.finalData?.recipeId
      );
      menuPlanner.resultSet[rows].days[column].recipes = previousRecipes;
      setMenuPlannerData(menuPlanner);
      holdingAreaClone[0]?.recipes?.splice(item.index, 0, item?.finalData);
      updateLocalStorage([holdingAreaClone[0]]);
    }
  };

  const checkCanDrop = (items) => {
    let canDrop = true;
    // Add proper null checks
    if (items && items[0]) {
      if (items[0].isHoldingArea && isOverThisMeal) {
        canDrop = !menuPlannerData?.resultSet[rows]?.days[
          column
        ]?.recipes?.some(
          (recipe) =>
            recipe &&
            items[0]?.finalData &&
            recipe.recipeId === items[0].finalData.recipeId
        );
        items[0].canDrop = canDrop;
      } else if (!items[0].isHoldingArea && isOverHoldingArea) {
        canDrop = !holdingRecipe[0]?.recipes?.some(
          (recipe) =>
            recipe &&
            items[0]?.finalData &&
            recipe.recipeId === items[0].finalData.recipeId
        );
        items[0].canDrop = canDrop;
      }
    }
    return canDrop;
  };

  // Custom Hooks
  const { isOver: isOverThisMeal, drop: dropThisMeal } = useCustomDrop(
    "card",
    statusChangeThisMeal,
    checkCanDrop
  );

  const { isOver: isOverHoldingArea, drop: dropHoldingArea } = useCustomDrop(
    "card",
    statusChange,
    checkCanDrop
  );

  const moveCard = useCallback((dragIndex, hoverIndex, item) => {
    if (item?.thisMeal && isOverThisMeal) {
      //this check will be true when someone want to sort this meal Box cards
      const updatedCards = structuredClone(
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes
      );

      /* Always need current index because dragIndex is not reliable
       * If the user leaves current meal card and then comes back somewhere else
       * the item has not shifted with the hover and it will move the wrong recipe
       */
      const initialIdx = updatedCards?.findIndex(
        (recipe) => recipe?.recipeId == item?.finalData?.recipeId
      );

      const movedItem = updatedCards.splice(initialIdx, 1); // Remove the dragged card from its original position
      updatedCards.splice(hoverIndex, 0, movedItem[0]);
      menuPlanner.resultSet[rows].days[column].recipes = updatedCards;
      setMenuPlannerData(menuPlanner);
    } else if (!item?.thisMeal && isOverHoldingArea) {
      //this check will be true when someone want to sort this Holding Area cards
      const initialIdx = holdingAreaClone[0].recipes.findIndex(
        (recipe) => recipe?.recipeId === item?.finalData?.recipeId
      );
      const moveCard = holdingAreaClone[0].recipes.splice(initialIdx, 1);
      holdingAreaClone[0].recipes.splice(hoverIndex, 0, moveCard[0]);
      updateLocalStorage([holdingAreaClone[0]]);
    }
  });
  // function will delete all the recipe from This meal Area
  const deleteThisMeal = () => {
    dispatch(setIsChangeTrue(true));
    menuPlanner.resultSet[rows].days[column].recipes = [];
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to swap from Meal Area to Holding Area
  const swapMealToHolding = () => {
    let mealData = menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    let holdingRecipes = holdingAreaClone[0]?.recipes || [];
    const filteredMealRecipes = mealData.filter(
      (mealData) =>
        !holdingRecipes.some(
          (holdingRecipes) => holdingRecipes.recipeId === mealData.recipeId
        )
    );

    updateLocalStorage([
      { recipes: [...holdingRecipes, ...filteredMealRecipes] },
    ]);
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to swap from Holding Area to Meal Area
  const swapHoldingToMeal = () => {
    dispatch(setIsChangeTrue(true));
    let mealData = menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    let holdingRecipes = holdingAreaClone[0]?.recipes || [];

    const filteredHoldingRecipes = holdingRecipes.filter(
      (holdingRecipe) =>
        !mealData.some(
          (mealRecipe) => mealRecipe.recipeId === holdingRecipe.recipeId
        )
    );
    menuPlanner.resultSet[rows].days[column].recipes = [
      ...mealData,
      ...filteredHoldingRecipes,
    ];
    // holdingAreaClone[0]?.recipes;
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to swap both Meal Area and Holding Area
  const swapBothMealAndHolding = () => {
    dispatch(setIsChangeTrue(true));
    const holdingCopy = holdingAreaClone[0].recipes;
    let mealData = menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    holdingAreaClone[0].recipes = mealData;
    updateLocalStorage([holdingAreaClone[0]]);
    menuPlanner.resultSet[rows].days[column].recipes = holdingCopy;
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to delete all the recipes from Holding Area
  const deleteHoldingArea = () => {
    holdingAreaClone[0].recipes = [];
    updateLocalStorage([holdingAreaClone[0]]);
    setMenuPlannerData(menuPlanner);
  };
  // Recipe card Rendering -- we are passing in hard-coded column values
  // because we need to know the difference during drag/hover events
  const renderCard = (data, index, thisMeal, isHoldingArea, column) => {
    return (
      <RecipeCard
        key={index}
        rows={rows}
        column={column}
        isDescription={false}
        isCost={false}
        data={data}
        index={index}
        moveCard={moveCard}
        thisMeal={thisMeal}
        isHoldingArea={isHoldingArea}
        holdingAreaAndThisMeal={true}
        supportMultiSelect={false}
        setMenuPlannerData={setMenuPlannerData}
        menuPlannerData={menuPlannerData}
        holdingAreaClone={holdingAreaClone}
        updateLocalStorage={updateLocalStorage}
      />
    );
  };
  // UI section
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ invisible: true }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "74%",
              backgroundColor: "var(--whiteColor)",
              boxShadow: "var(--darkBoxShadow)",
              borderRadius: "var(--borderRadius)",
              p: 4,
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: "16px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Meal Options
            </Typography>
            <span
              onClick={close}
              style={{
                color: "grey",
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
              }}
              className="material-icons"
            >
              close
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: "10px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                    height: "95%",
                    borderRadius: "var(--borderRadius)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                      id="modal-modal-title"
                      variant="h6"
                      component="h6"
                    >
                      This Meal
                    </Typography>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={deleteThisMeal}
                      className="material-icons"
                    >
                      delete
                    </span>
                  </Box>
                  <Box
                    id="thisMeal"
                    ref={dropThisMeal}
                    sx={{
                      opacity: isOverThisMeal ? 0.5 : 1,
                      boxShadow: "var(--darkBoxShadow)",
                      border: "1px solid #CCCCCC",
                      borderRadius: "10px",
                      paddingX: "2px",
                      paddingY: "5px",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                      height: { md: "250px", xl: "400px" },
                      margin: "5px",
                      gap: "2px",
                      overflow: "scroll",
                      alignItems: "left",
                    }}
                  >
                    {menuPlannerData?.resultSet[rows]?.days[
                      column
                    ]?.recipes.map(
                      (data, index) =>
                        renderCard(data, index, true, false, column) // keep orginal menu data
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    onClick={swapMealToHolding}
                    sx={{
                      backgroundColor: "var(--tealColor)",
                      paddingX: "20px",
                      paddingY: "5px",
                      borderRadius: "var(--borderRadius)",
                      textAlign: "center",
                      marginBottom: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{ color: "white", cursor: "pointer" }}
                      className="material-icons"
                    >
                      arrow_forward
                    </span>
                  </Box>
                  <Box
                    onClick={swapHoldingToMeal}
                    sx={{
                      backgroundColor: "var(--tealColor)",
                      paddingX: "20px",
                      paddingY: "5px",
                      borderRadius: "var(--borderRadius)",
                      textAlign: "center",
                      marginBottom: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{ color: "white", cursor: "pointer" }}
                      className="material-icons"
                    >
                      arrow_back
                    </span>
                  </Box>
                  <Box
                    onClick={swapBothMealAndHolding}
                    sx={{
                      backgroundColor: "var(--tealColor)",
                      paddingX: "20px",
                      paddingY: "5px",
                      borderRadius: "var(--borderRadius)",
                      textAlign: "center",
                      marginBottom: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ color: "white" }} className="material-icons">
                      swap_calls
                    </span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                    maxHeight: "95%",
                    borderRadius: "var(--borderRadius)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                      id="modal-modal-title"
                      variant="h6"
                      component="h6"
                    >
                      Holding Area
                    </Typography>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={deleteHoldingArea}
                      className="material-icons"
                    >
                      delete
                    </span>
                  </Box>
                  <Box
                    id="holdingArea"
                    ref={dropHoldingArea}
                    sx={{
                      opacity: isOverHoldingArea ? 0.5 : 1,
                      boxShadow: "var(--darkBoxShadow)",
                      border: "1px solid #CCCCCC",
                      borderRadius: "10px",
                      paddingX: "2px",
                      paddingY: "5px",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                      height: { md: "250px", xl: "400px" },
                      margin: "5px",
                      gap: "2px",
                      overflow: "scroll",
                      alignItems: "left",
                    }}
                  >
                    {!isEmpty(holdingRecipe)
                      ? holdingRecipe[0]?.recipes?.map(
                          (data, index) =>
                            renderCard(data, index, false, true, -1) // use -1 so it doesnt match any menu rows/column value
                        )
                      : null}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <BaseButton onClick={close} text="Close" />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
