import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { UNITS } = API_ENDPOINTS;

export const UnitAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: ({ searchTerm = "" }) =>
        `/${UNITS}?searchText=${searchTerm}&skip=0&take=1000`,
      keepUnusedDataFor: 120, // Units shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: UNITS,
                id,
              })),
              UNITS,
            ]
          : [UNITS],
    }),
    getSelectUnits: builder.query({
      query: () => `/${UNITS}?skip=0&take=1000`,
      keepUnusedDataFor: 120, // Units shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: UNITS,
                id,
              })),
              UNITS,
            ]
          : [UNITS],
    }),
  }),
});

// export hooks for calling endpoints
export const { useGetUnitsQuery, useGetSelectUnitsQuery } = UnitAPIs;
