import React, { useEffect } from "react";
import {
  useGetDiningAreaByIdQuery,
  useAddDiningAreaMutation,
  useUpdateDiningAreaMutation,
} from "../../../../store/apis/ResidentApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Box, Typography, Card } from "@mui/material";
import Grid from "@mui/material/Grid2";

import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../admin/admin.overlays";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { addDiningAreaFields } from "./form.constants";

export default function AddDiningArea() {
  const facilityName = useSelector((state) => state?.userInfo?.facilityName);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("id");
  const facilityId = searchParams.get("facilityId");

  const isEdit = !!editId;

  // Mutation Section
  const [
    addDiningArea,
    { isError: addError, isLoading: addLoading, isSuccess: addSuccess },
  ] = useAddDiningAreaMutation();

  const [
    updateDiningArea,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateDiningAreaMutation();

  // Query Section
  const {
    data: diningArea,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDiningAreaByIdQuery(editId, {
    skip: !isEdit,
  });

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const Success = addSuccess || updateSuccess;

  const isEditLoading = isEdit && currentFetching;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && diningArea) {
      reset(diningArea);
    }

    if (!isError && Success) {
      navigate(-1);
    }
  }, [diningArea, isError, Success]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateDiningArea(data);
    } else {
      data.facilityId = facilityId;
      addDiningArea(data);
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Card>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              margin: "20px",
            }}
          >
            {`Dining Area for ${facilityName}`}
          </Typography>
          <Box
            sx={{
              margin: "20px",
            }}
          >
            <FormProvider {...methods}>
              <Grid container spacing={2}>
                <GeneratedForm oldGrid={false} list={addDiningAreaFields} />
              </Grid>
              <Box marginTop={"10px"}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                />
              </Box>
              {isError && (
                <Typography
                  sx={{
                    color: "error",
                  }}
                >
                  There was an error submitting the form, please try again.
                </Typography>
              )}
            </FormProvider>
          </Box>
        </Card>
      )}
    </>
  );
}
