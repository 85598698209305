import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {  useMemo } from "react";
import { useGetCurrentUserQuery } from "store/apis/UserApis";

export default function useMsalAccount() {
  const { instance, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();

  // Memoize userId based on activeAccount to prevent unnecessary recalculations
  const userId = useMemo(() => {
    return activeAccount?.idTokenClaims?.extension_DiningManagerUserId || null;
  }, [activeAccount?.idTokenClaims?.extension_DiningManagerUserId]);


  const isAuthenticated = useIsAuthenticated();

  const {data: accountFromDB, isLoading: dbLoading, refetch} = useGetCurrentUserQuery(userId,{skip: !userId || !isAuthenticated});

// Memoize account object to prevent unnecessary recalculations
const account = useMemo(() => {

  if (activeAccount?.idTokenClaims) {

    const claims = activeAccount.idTokenClaims;

    if (accountFromDB) {
      // Return account data from DB if available
      return {
        userId: accountFromDB.id,
        email: accountFromDB.emailAddress,
        roles: claims.extension_DiningManagerRoles?.split(","),
        signInName: claims.signInName,
        account: activeAccount,
        userName: accountFromDB.userName || claims.signInName,
        fullName: `${accountFromDB.firstName} ${accountFromDB.lastName}`,
        impersonatedUser: claims?.impersonatedUser,
        facilityId: accountFromDB.facilityId,
        isDBLoading: dbLoading,
        inProgress,
        accountFromDB,
        refetch,
        userSSOId: accountFromDB.userSSOId,
        ssoDomainHint: accountFromDB.ssoDomainHint,
      };
    }

    // If no DB data, use claims from active account
    return {
      userId: claims.extension_DiningManagerUserId,
      email: claims.email,
      roles: claims.extension_DiningManagerRoles?.split(","),
      signInName: claims.signInName,
      account: activeAccount,
      userName: claims.email || claims.signInName,
      fullName: claims.name,
      impersonatedUser: claims?.impersonatedUser,
      facilityId: claims.extension_FacilityId,
      showFrontPageDocuments: null,
      isDBLoading: true, // Indicating that DB data is still loading
      inProgress,
    };
  }

  // Return empty account object if no active account
  return {
    userId: null,
    email: null,
    roles: [],
    signInName: null,
    account: null,
    userName: null,
    fullName: null,
    impersonatedUser: null,
    facilityId: null,
    showFrontPageDocuments: null,
    isDBLoading: true, // DB data is still loading
    inProgress,
  };
}, [activeAccount, accountFromDB, dbLoading, inProgress]);

return account;
}