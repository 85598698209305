import { API_ENDPOINTS } from "store/store.constants";
import { BaseApi } from "./BaseApi";

const { CLIENT_FACILITIES, FACILITIES, CORPORATE_GROUPS } = API_ENDPOINTS;

export const ClientPortalAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilityDetails: builder.query({
      query: ({ facilityId }) =>
        `Portal/CommunityDetails?facilityID=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: CLIENT_FACILITIES, id: response.id }, CLIENT_FACILITIES]
          : [CLIENT_FACILITIES],
    }),

    getPastVisitsByFacility: builder.query({
      query: ({ facilityId }) =>
        `PortalDocuments/VisitsByFacility?facilityId=${facilityId}&skip=${0}&take=${100}`,
      transformResponse: (response) => response?.data,
      // Not providing tags because these entries dont need to be invalidated.
    }),

    getUpcomingVisitsByFacility: builder.query({
      query: ({ facilityId, skip, take }) =>
        `PortalDocuments/VisitsByFacility?facilityId=${facilityId}&skip=${skip}&take=${take}&getUpcoming=true`,
      transformResponse: (response) => response?.data,
      // Not providing tags because these entries dont need to be invalidated.
    }),

    changeFacilityContact: builder.mutation({
      query: (data) => ({
        url: `Portal/CommunityDetails`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [CLIENT_FACILITIES],
    }),
    getFacilitiesByUser: builder.query({
      query: ({ searchTerm, corporateGroupId }) =>
        `/Portal/UserFacilities?searchText=${
          searchTerm || ""
        }&corporateGroupId=${corporateGroupId || ""}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: FACILITIES, id })),
              FACILITIES,
            ]
          : [FACILITIES],
    }),
    getCorporateGroupsByUserSelectBox: builder.query({
      query: () => `/Portal/UserCorporateGroups`,
      transformResponse: (response) => transformResponse(response),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({ type: CORPORATE_GROUPS, id })),
              CORPORATE_GROUPS,
            ]
          : [CORPORATE_GROUPS],
    }),
  }),
});

function transformResponse(response) {
  const staticOptions = [
    {
      label: "All",
      id: null,
      description: "Show all facilities",
    },
  ];

  if (!response || !response.data) {
    return staticOptions;
  }

  const dynamicOptions = response.data.map((group) => ({
    label: group.name,
    id: group.id,
    description: `${group.city} - ${group.state} [Facilities: ${group.facilityCount}]`,
  }));

  return [...staticOptions, ...dynamicOptions];
}

// export hooks for calling endpoints
export const {
  useGetFacilityDetailsQuery,
  useGetPastVisitsByFacilityQuery,
  useGetUpcomingVisitsByFacilityQuery,
  useChangeFacilityContactMutation,
  useGetFacilitiesByUserQuery,
  useGetCorporateGroupsByUserSelectBoxQuery,
} = ClientPortalAPIs;
