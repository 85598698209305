import React from "react";
import { alpha, createTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

import NunitoSans from "@fontsource/nunito-sans";

// https://mui.com/material-ui/integrations/routing/#global-theme-link
// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
  },
  status: {
    danger: "#FF3F28",
  },
  zIndex: {
    snackbar: 9999,
  },
  palette: {
    primary: {
      main: "#008385",
      contrastText: "#fff",
      light: "#33C9CB",
      dark: "#004f50",
      active: "#003435",
    },
    error: {
      main: "#A80000",
      contrastText: "#fff",
      light: "#FF6B5A",
      dark: "#650000",
    },
    warning: {
      main: "#FEC92C",
      contrastText: "#000",
      light: "#FED85A",
      dark: "#B28920",
    },
    success: {
      main: "#00BCBE",
      contrastText: "#fff",
      light: "#33C9CB",
      dark: "#008385",
    },
    white: {
      main: "#fff",
      contrastText: "#000",
      contrastTextOpacity: "rgba(255, 255, 255, 0.5)",
      light: "#ffffff",
      dark: "#cccccc",
    },
    gray: {
      main: "#dcdcdc",
      contrastText: "#000",
      light: "#FCFCFC",
      dark: "#9A9A9A",
      darkGray: "#575757",
    },
    black: {
      main: "#000",
      contrastText: "#fff",
      light: "#333333",
      dark: "#000000",
    },
    orange: {
      main: "#DE6D00",
      contrastText: "#fff",
      light: "#F88446",
      dark: "#854100",
    },
    lightBlue: {
      main: "#D6F0F3",
      contrastText: "#262626",
      light: "#C4E1E2",
      dark: "#A6D8DC",
      linkBlue: " #2A537E",
    },
    blue: {
      main: "#548ADF",
      contrastText: "#fff",
      light: "#76A1E5",
      dark: "#3A609C",
    },
    pacificBlue: {
      main: "#00BCBE",
      contrastText: "#fff",
      dark: "#008385",
      light: "#33C9CB",
    },
    maroon: {
      main: "#AB3E68",
      contrastText: "#fff",
      light: "#BB6486",
      dark: "#772B48",
    },
    purple: {
      main: "#6B37A6",
      contrastText: "#fff",
      light: "#885FB7",
      dark: "#4A2674",
    },
    yellow: {
      main: "#F8D061",
      contrastText: "#000",
      light: "#F9DA8A",
      dark: "#D9B03C",
    },
    green: {
      main: "#288B51",
      contrastText: "#fff",
      light: "#4AAE75",
      dark: "#1C6138",
      greenGradient: "linear-gradient(90deg, #1A4650 0%, #FFFFFF 100%)",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontStyle: "normal",
          fontWeight: 800,
          fontSize: "0.9em",
          lineHeight: "19px",
          padding: "0.4rem 1.2em",
          textTransform: "none",
          borderRadius: "6px",
          variants: [
            {
              props: { icon: "true" },
              style: {
                paddingLeft: 6,
                paddingRight: 6,
                minWidth: "unset",
                fontWeight: "bold",
                fontSize: "0.9rem",
              },
            },
            {
              props: { dark: "true" },
              style: { color: "var(--darkGrayColor)" },
            },

            {
              props: { light: "true" },
              style: { color: "white" },
            },
            {
              props: { color: "white", variant: "contained" },
              style: {
                border: "1px black solid",
                fontWeight: "700",
                borderRadius: "6px",
              },
            },
          ],
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "var(--lightGrayColor) !important",
            WebkitTextFillColor: "unset !important",
            color: "var(--whiteColor)",
          },
        },
        outlined: {
          "&.Mui-disabled": {
            borderColor: "var(--lightGrayColor)",
            color: "var(--whiteColor)",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          boxShadow: "9px 10px 24px -10px rgba(0, 0, 0, 0.25))",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          variants: [
            // For when the variant is "standard" and the bootstrap prop is `true`
            {
              props: { variant: "standard", bootstrap: `true` },
              style: {
                "& .MuiFormLabel-root": {
                  color: theme.palette.black.main,
                  fontSize: theme.spacing(2.5),
                  "&.Mui-error": {
                    color: theme.palette.error.main,
                  },
                },

                "& .MuiInputBase-root": {
                  marginTop: ownerState.size === "small" ? "16px" : "24px",
                  borderRadius: 4,
                  border: "1px solid",
                  padding:
                    ownerState.size === "small" ? "8px 6px" : "16.5px 12px",
                  boxShadow: ` 0px 2px 4px 0px #00000040`,
                  transition: theme.transitions.create([
                    "border-color",
                    "background-color",
                    "box-shadow",
                  ]),
                  "&.Mui-error": {
                    borderColor: theme.palette.error.main,
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    boxShadow: `0px 2px 4px 0px ${alpha(
                      theme.palette.error.main,
                      0.2
                    )}`,
                  },
                  "&.Mui-focused": {
                    boxShadow: `${alpha(
                      theme.palette[ownerState.color || "primary"].main,
                      0.25
                    )} 0 0 0 0.2rem`,
                    borderColor:
                      theme.palette[ownerState.color || "primary"].main,
                  },
                },
                "& .MuiInputBase-input": {},
              },
            },
            // Outlined Inputs border
            {
              props: { variant: "outlined" },
              style: {
                "& .MuiFormLabel-root": {
                  color: theme.palette.black.main,
                  top: ownerState.size === "small" ? "0px" : "4px",
                  fontSize: theme.spacing(2.0),
                  "&.Mui-error": {
                    color: theme.palette.error.main,
                  },
                  "&.MuiInputLabel-shrink": {
                    top: "0px",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid",
                  "&.Mui-focused": {
                    boxShadow: `${alpha(
                      theme.palette[ownerState.color || "primary"].main,
                      0.25
                    )} 0 0 0 0.2rem`,
                    borderColor:
                      theme.palette[ownerState.color || "primary"].main,
                    transition: theme.transitions.create([
                      "border-color",
                      "background-color",
                      "box-shadow",
                    ]),
                  },
                },

                "& .MuiInputBase-root": {
                  borderRadius: 4,
                  padding:
                    ownerState.size === "small"
                      ? "4px 8px !important"
                      : "8px 12px !important",
                  boxShadow: ` 0px 2px 4px 0px #00000040`,
                  transition: theme.transitions.create([
                    "border-color",
                    "background-color",
                    "box-shadow",
                  ]),
                  "&.Mui-error": {
                    borderColor: theme.palette.error.main,
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    boxShadow: `0px 2px 4px 0px ${alpha(
                      theme.palette.error.main,
                      0.2
                    )}`,
                  },
                },
                "& .MuiInputBase-input": {
                  padding:
                    ownerState.size === "small"
                      ? "4px 8px !important"
                      : "12px 8px !important",
                },
              },
            },
          ],
        }),
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${NunitoSans}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: "#f6f6f6",
    },
  },
});
