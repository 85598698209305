import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGetCorporateGroupCategoriesQuery } from "../../../../../../store/apis/CorporateGroupApis";
import { Button, Typography } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "../../../../../shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { KeyboardArrowLeft, PostAdd } from "@mui/icons-material";

export default function CorporateGroupCategories() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("id");

  const [categoryName, setCategoryName] = useState("");

  const {
    data: corporateGroupCategories,
    isLoading: isLoading,
    isError: isError,
  } = useGetCorporateGroupCategoriesQuery(categoryId, {
    refetchOnMountOrArgChange: true,
    skip: !categoryId,
  });

  useEffect(() => {
    if (corporateGroupCategories && corporateGroupCategories.length > 0) {
      setCategoryName(corporateGroupCategories[0].categoryName);
    }
  }, [corporateGroupCategories]);

  const CorpGroupCategoryColumns = [
    {
      field: "corporateGroupName",
      headerName: "Corporate Group",
      flex: 3,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={corporateGroupCategories}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["corporateGroupName"],
              apiPath: "deleteCorporateGroupCategory",
              title: "Corporate Group",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        <BaseButton
          text="Back To Recipe Categories"
          startIcon={<KeyboardArrowLeft />}
          onClick={() => navigate(-1)}
          sx={{ margin: "0px !important" }}
        />
        <Button
          sx={{ marginLeft: "20px", marginBottom: "25px" }}
          component={Link}
          to={`addCorporateGroup?id=${categoryId}`}
          color={"primary"}
          variant="contained"
          endIcon={<PostAdd />}
        >
          Add Corporate Group
        </Button>
      </FlexBox>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        style={{ marginBottom: "8px", width: "100%" }}
        sx={{
          color: "primary",
        }}
      >
        Corporate Groups with access to {categoryName} Category
      </Typography>
      <BaseDataGrid
        rows={corporateGroupCategories}
        columns={CorpGroupCategoryColumns}
        loading={isLoading}
        error={isError}
        width={"100%"}
      />
    </>
  );
}
