import React, { useEffect, useRef, useState } from "react";
import GeneratedForm from "../../../../../../../shared/generatedForm/GeneratedForm";
import {
  RESIDENT_ASSESSMENT_DEFAULTS,
  RESIDENT_ASSESSMENT_FIELDS,
} from "./forms.constants";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAddNutritionalCareMutation,
  useNutritionalCareByIdQuery,
  useUpdateNutritionalCareMutation,
} from "../../../../../../../../store/apis/NutritionalCareAPIs";
import { Box, Grid2 } from "@mui/material";
import BaseSubmitButton from "../../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../../../../admin/admin.overlays";
import { setErrorsForProperties } from "../../../../../../../shared/utils/helpers";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { Nutrition_ScreenNames } from "../../constants";
import { BackButton } from "components/shared/BackButton";
import BaseInput from "components/shared/baseForm/BaseInput";
import { useGetVisitReportsByIdQuery } from "store/apis/ConsultantApis";

import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";
import { isEqual } from "lodash";
import { WarningText } from "components/shared/WarningText";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";

export default function AddResidentAssessment() {
  // local States Variables
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const assessmentId = searchParams.get("id");
  const visitReportId = searchParams.get("visitReportId");
  const visitReportTypeId = searchParams.get("visitReportTypeId");
  const visitPlanId = searchParams.get("visitPlanId");
  const visitId = searchParams.get("visitId");
  const [hiddenList, setHiddenList] = useState([]);
  const [validationSummary, setValidationSummary] = useState();
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [buttonName, setButtonName] = useState();
  const [isChange, setIsChange] = useState(false);

  const isEdit = !!assessmentId;

  const warningRef = useRef(null);

  // useForm Declaration
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: RESIDENT_ASSESSMENT_DEFAULTS,
  });
  // destructuring of Method
  const {
    setError,
    handleSubmit,
    watch,
    reset,

    formState: { dirtyFields, isDirty },
  } = methods;

  // Watch Values from form
  const assessmentTypeId = watch("assessmentTypeId");
  const otherNeeded = watch("otherNeeded");
  const changeToCarePlanNeeded = watch("changeToCarePlanNeeded");
  const noChangeNeeded = watch("noChangeNeeded");
  const changeToTrayCardNeeded = watch("changeToTrayCardNeeded");
  const dietOrderChangeNeeded = watch("dietOrderChangeNeeded");
  const isAnyCheckBoxChecked =
    dietOrderChangeNeeded ||
    changeToTrayCardNeeded ||
    noChangeNeeded ||
    changeToCarePlanNeeded ||
    otherNeeded;

  // api Section
  // Query Section
  const { data: visitReportData } = useGetVisitReportsByIdQuery(visitReportId, {
    refetchOnMountOrArgChange: true,
    skip: !visitReportId,
  });

  const {
    data: currentAssessment,
    isLoading: currentLoading,
    isSuccess: currentSuccess,
  } = useNutritionalCareByIdQuery(assessmentId, { skip: !assessmentId });

  const isEditLoading = isEdit && currentLoading;

  // Mutation Section
  const [
    updateAssessment,
    { error: addError, isLoading: addLoading, isSuccess: addSuccess },
  ] = useUpdateNutritionalCareMutation();

  const [
    addAssessment,
    {
      data: addAssessmentData,
      error: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useAddNutritionalCareMutation();
  const errorResponse = addError || updateError;
  const isLoading = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;

  useEffect(() => {
    if (isSuccess) {
      const assesmentsId = currentAssessment?.id || addAssessmentData?.id;
      navigate(
        isEqual(buttonName, "PCP Fax")
          ? `/Consultant/MyRosters/VisitDetails/CreateMDFAX?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${assesmentsId}`
          : -1
      );
    }
  }, [isSuccess]);

  // OnSubmit Function
  const onSubmit = (data, buttonName) => {
    setIsChange(false);
    if (isEqual(buttonName, "PCP Fax") && !isAnyCheckBoxChecked) {
      setIsCheckBoxChecked(true);
      warningRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (isEdit) {
      updateAssessment(data);
      return;
    }
    data[`visitReportId`] = visitReportId;
    addAssessment({ ...data, submitButton: buttonName });
  };

  useEffect(() => {
    if (!currentLoading && currentSuccess) {
      if (currentAssessment?.subTypes === null) {
        reset({
          ...currentAssessment,
          subTypes: [],
          description: visitReportData?.description,
        });
      } else {
        reset({
          ...currentAssessment,
          description: visitReportData?.description,
        });
      }

      showInputs(currentAssessment);
    } else {
      reset({
        description: visitReportData?.description,
      });
    }
  }, [currentLoading, visitReportData, currentSuccess]);

  useEffect(() => {
    if (errorResponse) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
        setValidationSummary: setValidationSummary,
      });
    }
  }, [errorResponse]);

  function showInputs(data) {
    // Hide SubTypes Field if asessement type is not 6
    if ((data?.assessmentTypeId || assessmentTypeId) !== 6) {
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "subTypes" },
        { name: "subTypesOffset" },
      ]);
    } else {
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "subTypes" && x.name !== "subTypesOffset"
        )
      );
    }

    // Hide OtherComment if Other Needed is not checked
    if (!(data?.otherNeeded || otherNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "otherComment" },
        { name: "otherCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "otherComment" && x.name !== "otherCommentOffset"
        )
      );

    // Hide Change To Care Plan Comment if not checked
    if (!(data?.changeToCarePlanNeeded || changeToCarePlanNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "changeToCarePlanComment" },
        { name: "changeToCarePlanCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) =>
            x.name !== "changeToCarePlanComment" &&
            x.name !== "changeToCarePlanCommentOffset"
        )
      );

    // Hide Change to MealCard Comment if not checked
    if (!(data?.changeToTrayCardNeeded || changeToTrayCardNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "changeToTrayCardComment" },
        { name: "changeToTrayCardCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) =>
            x.name !== "changeToTrayCardComment" &&
            x.name !== "changeToTrayCardCommentOffset"
        )
      );

    // Hide Diet Order Change Needed Comment if not checked
    if (!(data?.dietOrderChangeNeeded || dietOrderChangeNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "newDiet" },
        { name: "newDietOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "newDiet" && x.name !== "newDietOffset"
        )
      );
  }

  useEffect(() => {
    showInputs();
  }, [
    assessmentTypeId,
    otherNeeded,
    changeToCarePlanNeeded,
    changeToTrayCardNeeded,
    dietOrderChangeNeeded,
  ]);

  const handleWarningScroll = () => {
    if (!isAnyCheckBoxChecked) {
      setIsCheckBoxChecked(true);
      setTimeout(() => {
        if (warningRef.current) {
          warningRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton text={"Loading Resident Assessment Information."} />
      ) : (
        <>
          <BaseBlockerModal2
            hasChange={isChange}
            setHasChange={setIsChange}
            dirtyFields={dirtyFields}
            isDirty={isDirty}
          />
          <FormProvider {...methods}>
            <Box
              sx={{
                padding: "8px",
              }}
            >
              <BackButton
                To={`/Consultant/MyRosters/VisitDetails/NutritionalCares?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&visitReportTypeId=${visitReportTypeId}&visitId=${visitId}&isEdit=true`}
                iconDisabled={true}
                sx={{ marginBottom: "10px" }}
              />
              <HeaderBox
                visitPlanId={visitPlanId}
                ScreensArray={Nutrition_ScreenNames}
              />
              <Box
                sx={{
                  marginY: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                  }}
                >
                  <BaseInput
                    name="description"
                    id="description"
                    label="Description"
                    size="small"
                    disabled
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseButton
                    sx={{
                      color: DMStyles.Teal,
                      marginRight: "10px",
                    }}
                    loadingColor="black"
                    border={`1px solid ${DMStyles.Teal}`}
                    variant="outlined"
                    text="PCP Fax"
                    onClick={() => {
                      handleWarningScroll();
                      setButtonName("PCP Fax");
                      handleSubmit((data) => onSubmit(data, "PCP Fax"))();
                    }}
                    isSubmitting={
                      isEqual(buttonName, "PCP Fax") ? isLoading : false
                    }
                    validationSummary={validationSummary}
                  />

                  <BaseSubmitButton
                    onClick={() => {
                      setButtonName("save");
                      handleSubmit((data) => onSubmit(data, "save"))();
                    }}
                    isSubmitting={
                      isEqual(buttonName, "save") ? isLoading : false
                    }
                    validationSummary={validationSummary}
                  />
                </Box>
              </Box>
              <Grid2
                container
                spacing={2}
                sx={{
                  marginBottom: "8px",
                }}
              >
                <GeneratedForm
                  oldGrid={false}
                  list={RESIDENT_ASSESSMENT_FIELDS}
                  hiddenList={hiddenList}
                  queries={[
                    {
                      name: "subTypes",
                      query: {
                        assessmentTypeId: assessmentTypeId,
                      },
                      skip: !assessmentTypeId,
                    },
                  ]}
                />
              </Grid2>
              {isCheckBoxChecked ? (
                <WarningText
                  color="red"
                  ref={warningRef}
                  sx={{ marginLeft: "0px !important", width: "50%" }}
                  text={"Please select at least one checkbox"}
                />
              ) : null}
            </Box>
          </FormProvider>
        </>
      )}
    </>
  );
}
