import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  useGetDiningAreaByIdQuery,
  useGetDiningAreaTableListQuery,
} from "../../../store/apis/ResidentApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import { BackButton } from "components/shared/BackButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PostAdd } from "@mui/icons-material";

export default function DiningAreaTables() {
  const [DiningAreaName, setDiningAreaName] = useState();

  const [searchParams] = useSearchParams();

  const diningAreaId = searchParams.get("diningAreaId");

  const {
    data: diningArea,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDiningAreaByIdQuery(diningAreaId);

  useEffect(() => {
    if (currentSuccess && !currentFetching && diningArea) {
      setDiningAreaName(diningArea.name);
    }
  }, [diningArea]);

  const {
    data: diningAreaList,
    isFetching: loading,
    isError: hasError,
  } = useGetDiningAreaTableListQuery(
    {
      diningAreaId: diningAreaId,
    },
    { refetchOnMountOrArgChange: true, skip: !diningAreaId }
  );

  const diningAreasColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 3,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={diningAreaList}
            hasEdit={true}
            editProps={{
              To: `/MealCard/settings/DiningAreaTables/AddDiningAreaTables?diningAreaId=${diningAreaId}&id=${params.row.id}`,
              title: "Dining Area Table",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"] || "",
              apiPath: "deleteDiningAreaTable",
              title: "Dining Area Table",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox my={"8px"} pr={1} justifyContent={"space-between"}>
        <BackButton sx={{ marginTop: "10px" }} iconDisabled={true} />
        <BaseButton
          sx={{ margin: "0px !important" }}
          text="Add Table"
          endIcon={<PostAdd />}
          To={`/MealCard/settings/DiningAreaTables/AddDiningAreaTables?diningAreaId=${diningAreaId}`}
        />
      </FlexBox>
      <Grid
        container
        spacing={1}
        sx={{
          paddingRight: 1,
          marginTop: 1,
        }}
      >
        <Grid item sm={8} md={10} lg={10} xl={10}>
          <Typography variant="h6" component="h2">
            {`Tables for ${DiningAreaName}`}
          </Typography>
        </Grid>
        <Grid item sm={4} md={2} lg={2} xl={2}></Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={diningAreaList || []}
            loading={loading}
            columns={diningAreasColumns}
            error={hasError}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "58vh",
              lg: "63vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
