import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  INGREDIENTS,
  VENDOR_INGREDIENTS,
  INGREDIENT_ALLERGIES,
  INGREDIENT_CONVERSIONS,
  INGREDIENT_CATEGORIES,
  HEPS,
} = API_ENDPOINTS;

export const IngredientAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Ingredients
    getIngredients: builder.query({
      query: ({ searchTerm, isRecipe }) =>
        `/${INGREDIENTS}?searchText=${searchTerm}&isRecipe=${
          isRecipe?.toString() || "false"
        }`,
      transformResponse: (response) => response?.data?.items,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: INGREDIENTS, id })),
              INGREDIENTS,
            ]
          : [INGREDIENTS];
      },
    }),
    getPagedIngredients: builder.query({
      query: ({ searchTerm, isRecipe, skip, take }) =>
        `/${INGREDIENTS}?searchText=${searchTerm}&isRecipe=${
          isRecipe?.toString() || "false"
        }&skip=${skip || 0}&take=${take || 100}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({ type: INGREDIENTS, id })),
              INGREDIENTS,
            ]
          : [INGREDIENTS];
      },
    }),
    getIngredientById: builder.query({
      query: (id) => `/${INGREDIENTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: INGREDIENTS, id: response.id }, INGREDIENTS]
          : [INGREDIENTS],
    }),
    getIngredientList: builder.query({
      query: () => `/${INGREDIENTS}/IngredientReport`,
    }),
    getIngredientUsage: builder.query({
      query: ({ ingredientId, searchTerm }) =>
        `/${INGREDIENTS}/usage?ingredientId=${ingredientId}&searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
    }),
    deleteIngredient: builder.mutation({
      query: (id) => ({
        url: `/${INGREDIENTS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: INGREDIENTS, id: arg.id },
      ],
    }),
    addIngredient: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENTS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: INGREDIENTS, id: result?.id }],
    }),
    updateIngredient: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: INGREDIENTS, id: result?.id }],
    }),
    getIngredientCategories: builder.query({
      query: () => `/${INGREDIENTS}/category`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: INGREDIENT_CATEGORIES,
                id,
              })),
              INGREDIENT_CATEGORIES,
            ]
          : [INGREDIENT_CATEGORIES];
      },
    }),
    getVendorIngredients: builder.query({
      query: ({ ingredientId, searchTerm }) =>
        `/${VENDOR_INGREDIENTS}?ingredientId=${ingredientId}&searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: VENDOR_INGREDIENTS, id })),
              VENDOR_INGREDIENTS,
            ]
          : [VENDOR_INGREDIENTS];
      },
    }),
    getVendorIngredientById: builder.query({
      query: (id) => `/${VENDOR_INGREDIENTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: VENDOR_INGREDIENTS, id: response.id }, VENDOR_INGREDIENTS]
          : [VENDOR_INGREDIENTS],
    }),
    addVendorIngredient: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_INGREDIENTS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: VENDOR_INGREDIENTS, id: result?.id },
      ],
    }),
    recalculateIngredient: builder.mutation({
      query: (ingredientId) => ({
        url: `${INGREDIENTS}/Recalculate?ingredientId=${ingredientId}`,
        method: "POST",
      }),
      invalidatesTags: (result) => [
        { type: "NutrientAnalysisRecalculateRequest", id: result?.id },
      ],
    }),

    updateVendorIngredient: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_INGREDIENTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: VENDOR_INGREDIENTS, id: result?.id },
      ],
    }),
    deleteVendorIngredient: builder.mutation({
      query: (id) => ({
        url: `/${VENDOR_INGREDIENTS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VENDOR_INGREDIENTS, id: arg.id },
      ],
    }),
    copyVendorIngredient: builder.mutation({
      query: (data) => ({
        url: `/${VENDOR_INGREDIENTS}/CopyVendorIngredient?vendorIngredientId=${data.vendorIngredientId}&newVendorId=${data.newVendorId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: VENDOR_INGREDIENTS, id: result?.id },
      ],
    }),
    getIngredientAllergies: builder.query({
      query: ({ ingredientId, searchTerm }) =>
        `/${INGREDIENTS}/${INGREDIENT_ALLERGIES}?ingredientId=${ingredientId}&searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: INGREDIENT_ALLERGIES,
                id,
              })),
              INGREDIENT_ALLERGIES,
            ]
          : [INGREDIENT_ALLERGIES];
      },
    }),
    addIngredientAllergy: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENTS}/${INGREDIENT_ALLERGIES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: INGREDIENT_ALLERGIES, id: result?.id },
      ],
    }),
    deleteIngredientAllergy: builder.mutation({
      query: (id) => ({
        url: `/${INGREDIENTS}/${INGREDIENT_ALLERGIES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: INGREDIENT_ALLERGIES, id: arg.id },
      ],
    }),
    getUnitConversion: builder.query({
      query: (ingredientId) =>
        `/${INGREDIENT_CONVERSIONS}?ingredientId=${ingredientId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: INGREDIENT_CONVERSIONS,
                id,
              })),
              INGREDIENT_CONVERSIONS,
            ]
          : [INGREDIENT_CONVERSIONS];
      },
    }),
    getUnitConversionById: builder.query({
      query: (id) => `/${INGREDIENT_CONVERSIONS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: INGREDIENT_CONVERSIONS, id: response.id },
              INGREDIENT_CONVERSIONS,
            ]
          : [INGREDIENT_CONVERSIONS],
    }),
    addUnitConversion: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENT_CONVERSIONS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: INGREDIENT_CONVERSIONS, id: result?.id },
      ],
    }),
    updateUnitConversion: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENT_CONVERSIONS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: INGREDIENT_CONVERSIONS, id: result?.id },
      ],
    }),
    deleteUnitConversion: builder.mutation({
      query: (id) => ({
        url: `/${INGREDIENT_CONVERSIONS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: INGREDIENT_CONVERSIONS, id: arg.id },
      ],
    }),
    getHepByIngredientId: builder.query({
      query: (ingredientId) =>
        `/${INGREDIENTS}/HEP/?ingredientId=${ingredientId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: HEPS, id: response.id }, HEPS] : [HEPS],
    }),
    addHep: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENTS}/${HEPS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: HEPS, id: result?.id }],
    }),
    updateHep: builder.mutation({
      query: (data) => ({
        url: `/${INGREDIENTS}/${HEPS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: HEPS, id: result?.id }],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetIngredientsQuery,
  useGetPagedIngredientsQuery,
  useGetIngredientByIdQuery,
  useGetIngredientListQuery,
  useGetIngredientUsageQuery,
  useDeleteIngredientMutation,
  useAddIngredientMutation,
  useUpdateIngredientMutation,
  useGetIngredientCategoriesQuery,
  useGetVendorIngredientsQuery,
  useGetVendorIngredientByIdQuery,
  useAddVendorIngredientMutation,
  useRecalculateIngredientMutation,
  useUpdateVendorIngredientMutation,
  useDeleteVendorIngredientMutation,
  useCopyVendorIngredientMutation,
  useGetIngredientAllergiesQuery,
  useAddIngredientAllergyMutation,
  useDeleteIngredientAllergyMutation,
  useGetUnitConversionQuery,
  useGetUnitConversionByIdQuery,
  useAddUnitConversionMutation,
  useUpdateUnitConversionMutation,
  useDeleteUnitConversionMutation,
  useGetHepByIngredientIdQuery,
  useAddHepMutation,
  useUpdateHepMutation,
} = IngredientAPIs;
