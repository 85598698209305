export const VENDOR_PRICE_IMPORT_FIELDS = {
  formSelectProps: [
    {
      props: {
        label: "Item Number Column",
        id: "itemIndex",
        name: "itemIndex",
        placeholder: "Select an Item Number Column",
      },
      validationProps: {
        required: "Item Number is required",
      },
    },
    {
      props: {
        label: "Description Column",
        id: "descriptionIndex",
        name: "descriptionIndex",
        placeholder: "Select a Description Column",
      },
      validationProps: {
        required: "Description is required",
      },
    },
    {
      props: {
        label: "Pack Size Column",
        id: "packIndex",
        name: "packIndex",
        placeholder: "Select a Pack Size Column",
      },
      validationProps: {
        required: "Pack Size is required",
      },
    },
    {
      props: {
        label: "Price Column",
        id: "priceIndex",
        name: "priceIndex",
        placeholder: "Select a Price Column",
      },
      validationProps: {
        required: "Price is required",
      },
    },
  ],
};

export const INDEX_OPTIONS = [
  { id: 0, label: "A" },
  { id: 1, label: "B" },
  { id: 2, label: "C" },
  { id: 3, label: "D" },
  { id: 4, label: "E" },
  { id: 5, label: "F" },
  { id: 6, label: "G" },
];
