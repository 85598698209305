import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useGetHEPContributionsQuery } from "../../../../store/apis/MenuApis";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function HepResults() {
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get("menuId");
  const week = searchParams.get("week");
  const [rowData, setRowData] = useState([]);
  const [totalsRow, setTotalsRow] = useState([]);

  const {
    data: hepData,
    isFetching: hepLoading,
    isSuccess,
  } = useGetHEPContributionsQuery(
    { menuId: menuId, week: week, maxWeeks: 1 },
    {
      refetchOnMountOrArgChange: true,
      skip: !menuId, // Skip the initial request
    }
  );

  useEffect(() => {
    if (hepData?.length > 0) {
      const dailyBreakdown = hepData.filter(
        (item) => item.mealTimeText !== "Totals"
      );
      setRowData(dailyBreakdown);
    }
    if (hepData) {
      const totals = hepData.filter((item) => item.mealTimeText == "Totals");
      setTotalsRow(totals);
    }
  }, [isSuccess]);

  const HEPColumns = [
    {
      field: "mealTimeText",
      headerName: "Meal Time",
      flex: 1,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "hepTextName",
      headerName: "HEP Category",
      flex: 1.8,
      headerAlign: "center",
      align: "left",
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepSundayServ",
      headerName: "Sunday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepMondayServ",
      headerName: "Monday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepTuesdayServ",
      headerName: "Tuesday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepWednesdayServ",
      headerName: "Wednesday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepThursdayServ",
      headerName: "Thursday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepFridayServ",
      headerName: "Friday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepSaturdayServ",
      headerName: "Saturday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepServingContributionAvg",
      headerName: "AVG",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
  ];

  const totalsColumn = [
    {
      field: "hepTextName",
      headerName: "HEP Category",
      flex: 1.8,
      headerAlign: "center",
      align: "left",
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepSundayServ",
      headerName: "Sunday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepMondayServ",
      headerName: "Monday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepTuesdayServ",
      headerName: "Tuesday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepWednesdayServ",
      headerName: "Wednesday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepThursdayServ",
      headerName: "Thursday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepFridayServ",
      headerName: "Friday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepSaturdayServ",
      headerName: "Saturday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepServingContributionAvg",
      headerName: "AVG Total",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
  ];

  return (
    <>
      <Box padding={1}>
        <Box>
          <Typography variant="h6">
            <span style={{ fontWeight: "bold" }}>Healthy Eating Pattern Calculations Week : </span>
            {rowData[0]?.menuWeekId}
          </Typography>
        </Box>
        <Box>
          <Accordion defaultExpanded marginBottom={0.5}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                bgcolor: "primary.main",
                borderRadius: "10px",
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white !important",
                }}
              >
                Weekly Average
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  height: "auto",
                  width: "100%",
                  "& .MuiInputBase-root": {
                    "& input[type=number]": {
                      textAlign: "right",
                    },
                  },
                  "& .super-app-theme--NotVendorItem": {
                    backgroundColor: "#a8000038",
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                      backgroundColor: "#a8000038",
                    },
                    ":hover": {
                      backgroundColor: "rgba(168, 0, 0, 0.22) !important",
                    },
                  },
                  // "& .super-app-theme--NotBackfeedItem": {
                  //   backgroundColor: "#AEE4E5",
                  //   "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                  //     backgroundColor: "#AEE4E5",
                  //   },
                  //   ":hover": {
                  //     backgroundColor: "rgba(174, 228, 229, 0.5) !important",
                  //   },
                  // },
                }}
              >
                <BaseDataGrid
                  rows={totalsRow}
                  columns={CreateBaseColumns(totalsColumn)}
                  unstable_rowSpanning={true}
                  loading={hepLoading}
                  height="40vh"
                  getRowClassName={(params) =>
                    params.row.withinRange == undefined
                      ? ""
                      : params?.row?.withinRange == true
                      ? "super-app-theme--NotBackfeedItem"
                      : "super-app-theme--NotVendorItem"
                  }
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                bgcolor: "primary.main",
                borderRadius: "10px",
              }}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white !important",
                }}
              >
                Daily Breakdown
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  height: "auto",
                  width: "100%",
                  "& .MuiInputBase-root": {
                    "& input[type=number]": {
                      textAlign: "right",
                    },
                  },
                  "& .super-app-theme--NotVendorItem": {
                    backgroundColor: "#FFEFB6",
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                      backgroundColor: "#FFEFB6",
                    },
                    ":hover": {
                      backgroundColor: "rgba(255, 239, 182, 0.5) !important",
                    },
                  },
                  "& .super-app-theme--NotBackfeedItem": {
                    backgroundColor: "#AEE4E5",
                    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                      backgroundColor: "#AEE4E5",
                    },
                    ":hover": {
                      backgroundColor: "rgba(174, 228, 229, 0.5) !important",
                    },
                  },
                }}
              >
                <BaseDataGrid
                  rows={rowData}
                  columns={CreateBaseColumns(HEPColumns)}
                  unstable_rowSpanning={true}
                  loading={hepLoading}
                  height="70vh"
                  getRowClassName={(params) =>
                    params.row.withinRange == undefined
                      ? ""
                      : params?.row?.withinRange == true
                      ? "super-app-theme--NotBackfeedItem"
                      : "super-app-theme--NotVendorItem"
                  }
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}
