import { Box, Typography } from "@mui/material";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { useVendorRecipeProductsQuery } from "../../../../../../store/apis/RecipeAPIs";
import { BackButton } from "components/shared/BackButton";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function VendorRecipeProducts() {
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");
  const recipeId = searchParams.get("recipeId");

  const {
    data: vendorProducts,
    isFetching: loading,
    isError: hasError,
    error: errorMessage,
  } = useVendorRecipeProductsQuery(
    {
      vendorId: vendorId,
      recipeId: recipeId,
    },
    { skip: !recipeId || !vendorId }
  );

  const vendorProductColumns = [
    {
      field: "ingredientName",
      headerName: "Ingredient",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/admin/recipes/IngredientDetails/VendorIngredient?id=${params.row["ingredientId"]}&name=${params.row["ingredientName"]}`}
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            >
              {params.row["ingredientName"]}
            </Link>
          </>
        );
      },
    },
    {
      field: "costPerServing",
      headerName: "Cost",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (params?.value ? `$ ${params.value}` : `$ 0`),
    },
  ];

  return (
    <>
      <Box
        sx={{
          padding: 1,
          flexGrow: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <FlexBox justifyContent={"space-between"}>
          <BackButton text="Back To Recipe Vendor Costs" />
          <Box>
            <Typography
              sx={{
                marginLeft: "20px",
                textAlign: "center",
                fontWeight: "700",
                fontSize: "1.2rem",
              }}
            >
              {vendorProducts &&
                `"${vendorProducts?.vendorName}" ingredients listing for "${vendorProducts?.recipeName}"`}
            </Typography>
          </Box>
        </FlexBox>
        <BaseDataGrid
          rows={vendorProducts?.recipeVendorIngredients}
          loading={loading}
          columns={vendorProductColumns}
          error={hasError}
          errorMessage={errorMessage}
          height={"100%"}
        />
      </Box>
    </>
  );
}
