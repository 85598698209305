import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import { useGetYardiResidentListQuery, useUpdateYardiResidentListMutation } from "store/apis/YardiManagementAPI";

export default function YardiImport() {
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const facilityName = searchParams.get("facilityName");
  const ehrId = searchParams.get("ehridentifier");

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const {
    data: residents,
    isFetching: fetchLoading,
    isError: fetchError,
    error: fetchErrorObj,
    refetch: refetchResidents,
  } = useGetYardiResidentListQuery(
    {
      facilityId: facilityId,
      ehrId: ehrId,
    },
    { refetchOnMountOrArgChange: true,
      skip: !facilityId || !ehrId
    }
  );

  const [
    updateYardiResidentList,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateErrorObj,
    },
  ] = useUpdateYardiResidentListMutation();

  const isError = fetchError || updateError;
  const isLoading = fetchLoading || updateLoading;
  const error = fetchErrorObj || updateErrorObj;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  const onSubmit = () => {
    var newArray = [];

    rowSelectionModel.forEach((row) => {
      var resident = residents.find(
        (resident) => resident.ahtPatientId === row
      );
      var newObject = JSON.parse(JSON.stringify(resident));
      newObject.isSelectedForImportUpdate = true;
      newArray.push(newObject);
    });

    updateYardiResidentList({
      facilityId: facilityId,
      ehrId: ehrId,
      data: newArray,
    });
  };

  useEffect(() => {
    if (updateSuccess) {
      refetchResidents();
      setRowSelectionModel([]);
    }
  }, [updateSuccess]);

  const residentsColumns = [
    {
      field: "yardiFullName",
      headerName: "Yardi Resident Name",
      flex: 1.3,
      valueGetter: (value, row) => {
        return `${row.lastName}, ${row.firstName}`;
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.lastName === null
              ? ""
              : `${params.row.lastName}, ${params.row.firstName}`}
          </>
        );
      },
    },
    {
      field: "htiFullName",
      headerName: "HTI Resident Name",
      flex: 1,
      valueGetter: (value, row) => {
        return `${row.localLastName}, ${row.localFirstName}`;
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.localLastName === null
              ? ""
              : `${params.row.localLastName}, ${params.row.localFirstName}`}
          </>
        );
      },
    },
    {
      field: "ahtPatientId",
      headerName: "Yardi Patient ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dateOfBirth",
      headerName: "Yardi DOB",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => {
        if (null === value) {
          return "";
        } else {
          return new Date(value).toLocaleDateString();
        }
      },
    },
    {
      field: "localDateOfBirth",
      headerName: "HTI DOB",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => {
        if (null === value) {
          return "";
        } else {
          return new Date(value).toLocaleDateString();
        }
      },
    },
  ];

  return (
    <div className="container">
      <Typography variant="h6" component="h2" className="header">
        {`Yardi Resident List for ${facilityName}`}
      </Typography>
      <div className="page-body mealCard">
        <div className="Row">
          <Button
            color={"primary"}
            className="baseButton"
            variant="contained"
            disabled={rowSelectionModel?.length === 0}
            onClick={handleSubmit(onSubmit)}
            endIcon={<span className="material-icons">check_box</span>}>
            Import/Update Selected Residents
          </Button>
        </div>

        <BaseDataGrid
          rows={residents}
          loading={isLoading}
          columns={residentsColumns}
          error={isError}
          autoHeight={false}
          height={{ md: "37dvh", xl: "40dvh" }}
          defaultPageSize={100}
          checkboxSelection
          getRowId={(row) => row.ahtPatientId}
          disableRowSelectionOnClick={false}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />

        <div className="Row">
          <Button
            color={"primary"}
            className="baseButton"
            variant="contained"
            sx={{marginTop: ".5rem"}}
            disabled={rowSelectionModel?.length === 0}
            onClick={handleSubmit(onSubmit)}
            endIcon={<span className="material-icons">check_box</span>}>
            Import/Update Selected Residents
          </Button>
        </div>

        <div className="Row">
          {isError && (
            <Typography
              sx={{
                color: "error",
              }}>
              {error?.data?.messages}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
