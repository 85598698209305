// Mui Imports
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
// hooks Import
import React, { useEffect, useMemo, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  // useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import "./PlateFul.css";

// Query Imports
import {
  useGetPlateFulRecipesQuery,
  useGetRecipeTagsQuery,
} from "../../../store/apis/PlateFulRecipesApis";
import { useGetRecipeCategorySelectBoxQuery } from "../../../store/apis/RecipeCategoriesApis";
// Mutation Imports
import { useCopyRecipeMutation } from "../../../store/apis/RecipeAPIs";
//Constant Imports
import {
  Labels,
  PlateRecipesOptionsList,
  PlateRecipesOptions2List,
} from "./plateFul.constant";
import { Roles } from "../../shared/constants";
// Mui Icon
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
// custom Component Imports
import BaseContent from "../../shared/baseContent/BaseContent";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import PlatefulTabs from "../PlatefulTabs";
import { useSnackbar } from "notistack";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import { setRecipeCategory } from "store/slices/plateFulSlice/Recipes";
import useMsalAccount from "utils/useMsalAccount";
import BaseActionBox from "components/shared/BaseActionBox";
import { Error, Report } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseMenu from "components/shared/BaseMenu";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import FullScreenMessage from "components/shared/FullScreenMessage";
import { BaseButton } from "components/shared/BaseButton";

const STORAGE_KEY = "PLATEFUL_STATE";

const PlateFul = ({ isMenu }) => {
  const navigate = useNavigate();
  if (isMenu) navigate("/plateFul/menus");

  // localState Declaration
  const { state } = useLocation();

  const [debouncedValue, setDebouncedValue] = useState(state?.search || "");
  const [searchValue, setSearchValue] = useState(state?.search || "");

  const [category, setCategory] = useState(
    state?.category || Labels.EMPTY_STRING
  );
  const [tags, setTags] = useState(state?.tags || []);
  // storedSearch.searchTags || Labels.EMPTY_ARRAY
  const [menuPlannerOnly, setMenuPlannerOnly] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const [openModalName, setOpenModalName] = useState("");
  const [openModalId, setOpenModalId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [searchResults, setSearchResults] = useState([]);

  // userRoles from redux
  const facilityId = useSelector(selectFacilityId);
  const { userId, roles: userRoles } = useMsalAccount();

  const dispatch = useDispatch();

  const isAllFieldEmpty =
    debouncedValue === Labels.EMPTY_STRING &&
    category === Labels.EMPTY_STRING &&
    tags?.length === 0;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  // Query Section
  const {
    data: PlateRecipesData,
    isFetching: loading,
    isError: hasError,
    error: error,
  } = useGetPlateFulRecipesQuery(
    {
      searchTerm: debouncedValue,
      archive: false,
      categoryId: category,
      tagIds: tags,
      htiCategory: false,
      corporateGroup: "",
      usedInMenuPlannerOnly: menuPlannerOnly,
      facilityId: facilityId,
      userId: userId,
      skip: paginationModel.pageSize * paginationModel.page,
      take: paginationModel.pageSize,
    },
    {
      skip: isAllFieldEmpty || !facilityId || !userId,
    }
  );

  const isEmptyResult = useMemo(
    () => PlateRecipesData?.items?.length === 0,
    [PlateRecipesData?.items?.length]
  );

  const { data: RecipeCategoryData } = useGetRecipeCategorySelectBoxQuery(
    {
      facilityId,
      filterToHTI: false,
      filterToCorporateGroup: false,
    },
    {
      skip: !facilityId,
    }
  );
  const { data: RecipeTagsData } = useGetRecipeTagsQuery();

  // UseEffect Section
  useEffect(() => {
    // Restore persisted state on mount
    const persistedState = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (persistedState) {
      setDebouncedValue(persistedState.search || "");
      setSearchValue(persistedState.search || "");
      setCategory(persistedState.category || "");
      setTags(persistedState.tags || []);
    }
  }, []);

  useEffect(() => {
    // Persist state on change
    const stateToPersist = { search: searchValue, category, tags };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(stateToPersist));

    return () => {
      if (!window.location.href.includes("plateFul")) {
        localStorage.removeItem(STORAGE_KEY);
      }
    };
  }, [searchValue, category, tags]);

  useEffect(() => {
    if (hasError) {
      enqueueSnackbar(
        `${error?.status}: ${
          error?.data ? JSON.stringify(error.data) : "An error occured."
        }`,
        { variant: "error" }
      );
    }
  }, [hasError, PlateRecipesData]);

  useEffect(() => {
    if (PlateRecipesData && !isAllFieldEmpty) {
      setSearchResults(PlateRecipesData?.items);
    } else if (isAllFieldEmpty) {
      setSearchResults([]);
    }
  }, [PlateRecipesData, !loading, isAllFieldEmpty]);

  const canDeleteRecipe = (isHTIRecipe) => {
    const userAllowedWithRoles = [Roles.Admin, Roles.Menu_Team];
    const isRoleAllowedToDelete = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );

    const canDelete =
      (isRoleAllowedToDelete && isHTIRecipe) ||
      (!isRoleAllowedToDelete && !isHTIRecipe);

    return canDelete;
  };

  // DataGrid Column
  const recipesColumns = [
    {
      field: "name",
      flex: 4,
      headerName: "Recipe Name",
      renderCell: (params) => {
        return (
          <Link
            to={`Recipes/RecipeDetail?recipeId=${params.row.id}`}
            state={{
              search: searchValue,
              category: category,
              tags: tags,
            }}
            style={{ color: "rgba(0, 0, 0, 0.87)" }}
          >
            {params.row["name"]}
          </Link>
        );
      },
    },
    {
      field: "description",
      flex: 4,
    },
    {
      field: "category",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "htiRecipe",
      headerName: "HTI Recipe",
      isBoolean: true,
      headerAlign: "center",
    },
    {
      field: "includeInMenuPlanning",
      maxWidth: 175,
      headerName: "Menu Planning",
      headerAlign: "center",
      isBoolean: true,
    },
    {
      field: "actions",
      maxWidth: 150,
      flex: 2,
      headerAlign: "center",
      renderCell: (params) => {
        dispatch(setRecipeCategory(params?.row?.category));
        return (
          <BaseActionBox
            childrenLocation={"end"}
            hasView={true}
            data={searchResults}
            index={params.row.id}
            viewProps={{
              To: `Recipes/RecipeDetail?recipeId=${params.row.id}`,
              title: "Recipe",
              state: {
                search: searchValue,
                category: category,
                tags: tags,
              },
            }}
            hasDelete={canDeleteRecipe(params.row?.htiRecipe)}
            deleteProps={{
              entityName: params.row?.name,
              apiPath: "deletePlatefulRecipes",
              title: "Recipe",
            }}
          >
            <RecipeOptionsButton
              idName="plateMenuButton"
              index={params.row.id}
              setModalToOpen={setOpenModal}
              setOpenModalName={setOpenModalName}
              setOpenModalId={setOpenModalId}
              name={params.row.name}
              List={
                userRoles?.includes("Admin") ||
                params?.row?.category == "House Recipes"
                  ? PlateRecipesOptionsList
                  : PlateRecipesOptionsList?.filter(
                      (item) => item?.optionName !== "Change History"
                    )
              }
              List2={PlateRecipesOptions2List}
              query="?recipeId="
              sliceNumber={2}
              isHTIRecipe={params.row?.htiRecipe}
              state={{
                search: searchValue,
                category: category,
                tags: tags,
              }}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  // UI Section
  return (
    <BaseContent
      cardOverFlow={false}
      disableBack={true}
      headerText="PlateFul"
      transparent={true}
    >
      <PlatefulTabs />
      <Card
        sx={{
          paddingLeft: "5px",
          borderTopLeftRadius: "0px !important",
          borderTopRightRadius: "0px !important",
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            paddingX: 1,
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              component={Link}
              to="recipes/addRecipe"
              color={"primary"}
              sx={{ marginLeft: "0 !important" }}
              variant="contained"
              endIcon={<span className="material-icons">receipt</span>}
            >
              New Recipe
            </Button>
          </Box>
          <FlexBox
            sx={{
              justifyContent: "space-between",
              marginY: 1,
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                // width: "60%",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <BaseSearch
                sx={{ marginRight: "0px", borderRadius: "5px" }}
                placeholder="Search by recipe name..."
                disableUnderline={true}
                type="text"
                // SEARCH_PARAM={SEARCH_PARAM}
                value={searchValue}
                setSearchValue={setSearchValue}
                setDebouncedValue={setDebouncedValue}
                // setSearchParams={setSearchParams}
              />
              <Select
                // sx={{ width: "240px" }}
                id="category-select"
                value={category}
                className="selectInputCategory"
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
                size="small"
                displayEmpty
              >
                <MenuItem value="">Select Category to search by...</MenuItem>
                {RecipeCategoryData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              <Select
                id="tag-select"
                multiple
                value={tags}
                placeholder="Select Tags to Search by..."
                className="selectInput"
                onChange={(event) => {
                  setTags(event.target.value);
                }}
                size="small"
                displayEmpty
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "60px", // Set the maximum height here
                      overflowY: "auto", // Add scrollbar if tags exceed the maximum height
                    }}
                  >
                    {selected.length === 0
                      ? "Select Tags to search by..."
                      : selected.map((option) => (
                          <Chip
                            key={option.id}
                            label={option.name}
                            onDelete={() =>
                              setTags(
                                tags.filter((item) => item.id !== option.id)
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            sx={{ margin: "2px" }} // Add some spacing between chips
                          />
                        ))}
                  </Box>
                )}
              >
                {RecipeTagsData?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    sx={{ justifyContent: "space-between" }}
                  >
                    {option?.name}
                    {tags?.map((options) =>
                      options?.name?.includes(option?.name) ? (
                        <CheckIcon key={options.id} color="info" />
                      ) : null
                    )}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                // width: "20%",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <BaseButton
                startIcon={menuPlannerOnly ? "check" : "close"}
                text={menuPlannerOnly ? "Menu Recipes" : "All Recipes"}
                onClick={() => {
                  setMenuPlannerOnly(!menuPlannerOnly);
                }}
              />
              <BaseButton
                text={"Reset"}
                colors={"warning"}
                onClick={() => {
                  setSearchResults([]);
                  setSearchValue(Labels.EMPTY_STRING);
                  // setName(Labels.EMPTY_STRING);
                  setCategory(Labels.EMPTY_STRING);
                  setTags(Labels.EMPTY_ARRAY);
                  setDebouncedValue(Labels.EMPTY_STRING);
                }}
              />
            </Box>
          </FlexBox>
          {openModal && (
            <CopyModal
              open={openModal}
              close={() => setOpenModal(false)}
              cgName={openModalName}
              facilityName={openModalName}
              id={openModalId}
              userId={userId}
              facilityId={facilityId}
            />
          )}
          <Box
            sx={{
              height: "100%",
              flexGrow: 1,
              overflowY: "auto",
              pb: 1,
            }}
          >
            <BaseDataGrid
              rows={searchResults}
              rowCount={PlateRecipesData?.totalCount || 0}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              loading={loading}
              columns={CreateBaseColumns(recipesColumns)}
              error={hasError}
              height={"100%"}
              slots={{
                noRowsOverlay: isEmptyResult ? NoResultOverlay : NoRowsOverlay,
                noResultsOverlay: NoResultOverlay,
              }}
              autoHeight={false}
              getRowHeight={() => "auto"}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </BaseContent>
  );
};

export default PlateFul;

function CopyModal({ facilityName, id, userId, facilityId, open, close }) {
  const [isChecked, setIsChecked] = useState(true);
  const [recipeId] = useState(id);
  const navigate = useNavigate();
  const [copyRecipe, { data: copyRecipeData, isLoading: copyRecipeLoading }] =
    useCopyRecipeMutation({
      refetchOnMountOrArgChange: true,
    });
  useEffect(() => {
    // copyRecipe({ recipeId, isChecked });
    if (!copyRecipeLoading && copyRecipeData) {
      navigate(
        `/plateFul/Recipes/addRecipe?recipeId=${copyRecipeData?.id}&Message=CopyRecipe`
      );
    }
  }, [copyRecipeData]);

  const copyRecipesData = () => {
    copyRecipe({ recipeId, userId, facilityId, isChecked });
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <FlexBox
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}
          >
            <Report color="error" sx={{ fontSize: "90px" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                className="modalTitle"
                sx={{
                  textAlign: "center",
                }}
              >
                Copy Recipe
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                {facilityName}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(event) => setIsChecked(event.target.checked)}
                  />
                }
                label="Copy Spreads along with the Recipe"
              />
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox justifyContent={"end"} my="10px" px={"24px"}>
            <Button variant="contained" onClick={close} color={"warning"}>
              Cancel
            </Button>
            <Button
              onClick={copyRecipesData}
              variant="contained"
              component={Link}
              color={"error"}
              disabled={copyRecipeLoading}
              startIcon={
                copyRecipeLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }
            >
              Copy
            </Button>
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}

function NoRowsOverlay() {
  return (
    <FullScreenMessage
      color={"gray"}
      title="Find Recipes"
      message="Use the search bar to find recipes by name, category, or tags."
      instructions={" "}
      verticallyCenter={true}
      icon={
        <Error color="gray" sx={{ fontSize: { md: "90px", xs: "48px" } }} />
      }
    />
  );
}

function NoResultOverlay() {
  return (
    <FullScreenMessage
      color={"gray"}
      title="No Recipes Found"
      message="Please try again with different search criteria."
      instructions={" "}
      verticallyCenter={true}
      icon={
        <Error color="gray" sx={{ fontSize: { md: "90px", xs: "48px" } }} />
      }
    />
  );
}

export const RecipeOptionsButton = ({
  index,
  name,
  setModalToOpen,
  setOpenModalName,
  setOpenModalId,
  List,
  List2,
  query,
  sliceNumber,
  isHTIRecipe,
  state,
}) => {
  const { roles: userRoles } = useMsalAccount();

  // menu permissions
  const isAdmin = () => {
    const userAllowedWithRoles = [Roles.Admin, Roles.Menu_Team];
    const roleAllows = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );

    return roleAllows;
  };

  const canShareRecipe = () => {
    const userRoleList = [Roles.Admin, Roles.Menu_Team];
    const useIsAdmin = userRoles.some((roleToCheck) =>
      userRoleList.includes(roleToCheck)
    );
    const userRoleList2 = [Roles.Recipe_Sharing];
    const userCanShare = userRoles.some((roleToCheck) =>
      userRoleList2.includes(roleToCheck)
    );

    const canShare =
      (userCanShare && !isHTIRecipe) || (!useIsAdmin && !isHTIRecipe);

    return canShare;
  };

  return (
    <BaseMenu>
      {List?.slice(sliceNumber)?.map((options) => (
        <MenuItem
          component={Link}
          key={options.id}
          state={{
            title: name,
            canEdit: isAdmin() ? isAdmin() : false,
            ...state,
          }}
          to={
            options?.optionName?.startsWith("Copy")
              ? ""
              : options?.Link + query + index
          }
          onClick={() => {
            if (options.optionName?.startsWith("Copy")) {
              setOpenModalName(name);
              setOpenModalId(index);
              setModalToOpen(true);
            }
          }}
          disableRipple
        >
          {options.optionName}
        </MenuItem>
      ))}

      {canShareRecipe() ? (
        <MenuItem
          component={Link}
          key={10}
          state={{ title: name, ...state }}
          to={`/plateFul/Recipes/share?recipeId=${index}`}
          disableRipple
        >
          {"Share"}
        </MenuItem>
      ) : null}

      {isAdmin()
        ? List2?.map((options) => (
            <MenuItem
              component={Link}
              key={options.id}
              state={{ title: name, ...state }}
              to={options?.Link + query + index}
              disableRipple
            >
              {options.optionName}
            </MenuItem>
          ))
        : null}
      <MenuItem
        component={Link}
        key={13}
        state={{ title: name, ...state }}
        to={"/plateFul/Recipes/Print" + query + index}
        disableRipple
      >
        {"Print"}
      </MenuItem>
    </BaseMenu>
  );
};
