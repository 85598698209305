import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";

export default function BaseSubmitButton({
  text = "Save",
  color = "primary",
  variant = "contained",
  isSubmitting = false,
  onClick,
  validationSummary,
  endIcon,
  borderRadius,
  fullWidth = false,
  sx,
  startIcon,
  iconButton = false,
  ...rest
}) {
  const StartIcon = isSubmitting ? (
    <CircularProgress color={"white"} size={18} />
  ) : startIcon ? (
    <>
      {typeof startIcon === "string" ? (
        <span className="material-icons">{startIcon}</span>
      ) : (
        <>{startIcon}</>
      )}
    </>
  ) : null;

  const EndIcon = (
    <>
      {typeof endIcon === "string" ? (
        <span className="material-icons">{endIcon}</span>
      ) : (
        <>{endIcon}</>
      )}
    </>
  );

  const errorMessage =
    validationSummary?.data?.messages[0] || validationSummary;

  if (iconButton) {
    return (
      <FormControl fullWidth={fullWidth}>
        <IconButton
          sx={{ ...sx }}
          variant={variant}
          color={color}
          disabled={isSubmitting}
          {...rest}
          onClick={onClick}
        >
          {StartIcon}
        </IconButton>
        {validationSummary && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <span>
        <Button
          sx={{
            ...sx,
            borderRadius: borderRadius ? borderRadius : "5px !important",
            backgroundColor:
              variant == "outline" && isSubmitting ? "#E8E8E8 !important" : "",
          }}
          variant={variant}
          color={color}
          disabled={isSubmitting}
          startIcon={StartIcon}
          endIcon={EndIcon}
          {...rest}
          onClick={onClick}
        >
          {text}
        </Button>
      </span>
      {validationSummary && (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}
