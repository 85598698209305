import React from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { Card } from "@mui/material";

import { CalculationElements } from "./menuCalculation.constants";
import AdminTabs from "components/admin/shared/AdminTabs";
import OutletContainer from "components/shared/styledComponents/OutletContainer";
import BaseContent from "components/shared/baseContent/BaseContent";

export default function MenuCalculation() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get("menuId");
  const week = searchParams.get("week");
  const isHepScreen = location.pathname === "/plateFul/hep/HepResults";
  const query = `?menuId=${menuId}&week=${week}`;

  return (
    <>
      <BaseContent
        headerText={
          isHepScreen ? "Healthy Eating Pattern Calculation" : "Nutrient Analysis Calculations"
        }
        disableHeight={true}
        backText={"Back to Menu Planner"}
        backLink={`/plateFul/Menus/MenuPlanner?menuId=${menuId}`}
      >
        <AdminTabs Data={CalculationElements} query={query} />
        <Card
          id="calculation-group-card"
          sx={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
          }}
        >
          <OutletContainer id="calculation-group-outlet">
            <Outlet />
          </OutletContainer>
        </Card>
      </BaseContent>
    </>
  );
}
