import Grid from "@mui/material/Grid2";
import React from "react";

import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { permissionFormFields } from "./forms.constants";

export default function PermissionFields() {
  return (
    <Grid container spacing={1}>
      <GeneratedForm oldGrid={false} list={permissionFormFields} />
    </Grid>
  );
}
