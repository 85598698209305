import { FORM_TYPES } from "../../../shared/generatedForm/GeneratedForm";
import { roomNumberValidation } from "../../../../utils/ValidationRegex";
import {
  INVALID_PERSON_NAME_VALIDATION,
  REQUIRED_ERROR,
  MAX_LENGTH_VALIDATION,
} from "utils/errorMessages";

export const ResidentAddFields = [
  {
    props: {
      label: "First Name",
      id: "firstName",
      name: "firstName",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("First Name"),
      pattern: INVALID_PERSON_NAME_VALIDATION("First Name"),
    },
    size: 3.3,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Last Name",
      id: "lastName",
      name: "lastName",
      defaultValue: "",
    },
    validationProps: {
      required: REQUIRED_ERROR("Last Name"),
      pattern: INVALID_PERSON_NAME_VALIDATION("Last name"),
    },
    size: 3.3,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Nickname",
      id: "nickname",
      name: "nickname",
      defaultValue: "",
    },
    validationProps: {
      pattern: INVALID_PERSON_NAME_VALIDATION("Nickname"),
    },
    size: 3.4,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Building",
      id: "building",
      name: "building",
      defaultValue: "",
    },
    validationProps: {
      maxLength: {
        value: 20,
        message: "Max Character Length is 20",
      },
    },
    size: 5,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Wing",
      id: "wing",
      name: "wing",
      defaultValue: "",
    },
    validationProps: {},
    size: 5,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Room Number",
      id: "roomNumber",
      name: "roomNumber",
      defaultValue: "",
    },
    validationProps: {
      pattern: {
        value: roomNumberValidation,
        message: "Invalid number",
      },
    },
    size: 5,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Bed",
      id: "bed",
      name: "bed",
      defaultValue: "",
    },
    validationProps: {
      maxLength: {
        value: 5,
        message: "Max Character Length is 5",
      },
    },
    size: 5,
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Date Of Birth",
      id: "dateOfBirth",
      name: "dateOfBirth",
      placeholder: "Date Of Birth",
      disableFuture: true,
      // defaultValue: null,
    },
    validationProps: {
      max: {
        value: new Date(),
        message: "Date of Birth must be before today",
      },
    },
    type: FORM_TYPES.DatePicker,
    size: 5,
  },
  {
    props: {
      label: "Admission Date",
      id: "admissionDate",
      name: "admissionDate",
      placeholder: "Admission Date",
      disableFuture: true,
      // defaultValue: null,
    },
    type: FORM_TYPES.DatePicker,
    size: 5,
  },
  {
    props: {
      label: "AHT/EHR identifier",
      id: "ahtpatientId",
      name: "ahtpatientId",
      defaultValue: "",
    },
    validationProps: {},
    size: 5,
    types: FORM_TYPES.Text,
  },
  {
    props: {},
    size: 5,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      label: "Diet",
      id: "dietId",
      name: "dietId",
    },
    size: 5,
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentDietSelect",
  },
  {
    props: {
      label: "Diet Texture",
      id: "dietTextureId",
      name: "dietTextureId",
    },
    size: 5,
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentDietSelect",
  },
  {
    props: {
      label: "Diet Other",
      id: "dietOther",
      name: "dietOther",
      defaultValue: "",
    },
    size: 10,
    validationProps: {
            maxLength: MAX_LENGTH_VALIDATION("Diet Other", 300),
          },
    types: FORM_TYPES.Text,
  },
  {
    props: {
      label: "Liquid Consistency",
      id: "thickenedLiquidId",
      name: "thickenedLiquidId",
    },
    size: 10,
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentThickenedLiquidSelect",
  },
  {
    props: {
      label: "NPO?",
      name: "isNpo",
      defaultValue: false,
    },
    type: "checkbox",
    size: 7,
  },
  {
    props: {
      label: "Fluid Restrict?",
      name: "hasFluidRestrict",
      defaultValue: false,
    },
    type: "checkbox",
    size: 7,
  },
  {
    props: {
      label: "Fluid Restriction Quantity",
      id: "restrictQty",
      name: "restrictQty",
      defaultValue: "",
    },
    validationProps: {
      maxLength: {
        value: 20,
        message: "Fluid Restriction Quantity must be less than 20 characters",
      },
    },
    size: 10,
    types: FORM_TYPES.Text,
  },
];
