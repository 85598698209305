import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useAddUnitConversionMutation,
  useUpdateUnitConversionMutation,
  useGetUnitConversionByIdQuery,
} from "../../../../../../store/apis/IngredientAPIs";

import { EditSkeleton } from "../../../../admin.overlays";
import { BackButton } from "components/shared/BackButton";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { UNIT_CONVERSION_LIST } from "./form.constants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { theme } from "styles/theme";

function AddUnitConversion() {
  const [
    addUnitConversion,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddUnitConversionMutation();
  const [
    updateUnitConversion,
    {
      isError: isUpdateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdateUnitConversionMutation();

  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentUnitConversionId = searchParams.get("unitId");
  const currentIngredientId = searchParams.get("id");

  const isEdit = !!currentUnitConversionId;

  const {
    data: currentUnitConversion,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetUnitConversionByIdQuery(currentUnitConversionId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit, reset } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentUnitConversion) {
      reset(currentUnitConversion);
    }
  }, [currentUnitConversion]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Unit Conversion ${isEdit ? "updated" : "created"} successfully.`,
        { variant: "success" }
      );
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateUnitConversion(data);
      return;
    }
    data.ingredientId = currentIngredientId;
    addUnitConversion(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <Box>
            <BackButton text="Back To Unit Conversions" />
          </Box>
          <>
            <FormProvider {...methods}>
              <Grid container spacing={2} style={{ marginTop: "24px" }}>
                <GeneratedForm oldGrid={false} list={UNIT_CONVERSION_LIST} />
              </Grid>
              <BaseSubmitButton
                text="Submit"
                isSubmitting={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              />

              {isError ? (
                <Typography
                  sx={{
                    color: theme.palette.error.main,
                  }}
                >
                  {error?.data?.messages}
                </Typography>
              ) : undefined}
            </FormProvider>
          </>
        </>
      )}
    </>
  );
}

export default AddUnitConversion;
