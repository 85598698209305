import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { REQUIRED_ERROR } from "utils/errorMessages";

export const notesList = [
  {
    props: {
      name: "noteTypeId",
      id: "noteTypeId",
      label: "Note Type",
    },
    validationProps: {
      required: REQUIRED_ERROR("Note Type"),
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getNoteTypes",
    size: 7,
  },
  {
    props: {
      name: "note",
      id: "note",
      label: "Note",
      multiline: true,
      minRows: 4,
    },
    validationProps: {
      required: REQUIRED_ERROR("Note"),
    },
    size: 7,
  },
  {
    props: {
      name: "includeOnTrayCard",
      id: "includeOnTrayCard",
      label: "Include On MealCard",
      defaultValue: true,
    },
    type: FORM_TYPES.Checkbox,
    size: 7,
  },
];
