export const tableSideButtons = [
  {
    id: 1,
    text: "Order Tracking",
    Link: "/TableSide/orderTracking",
    icon: "grading",
  },
  {
    id: 2,
    text: "Reports",
    Link: "/TableSide/Reports",
    icon: "summarize",
  },
  {
    id: 3,
    text: "Take Orders",
    // eslint-disable-next-line no-undef
    Link: process.env.REACT_APP_TABLESIDE_URL,
    icon: "edit_note",
    target: "_blank",
  },
];
export const tableSetupButtons = [
  {
    id: 1,
    text: "Residents",
    Link: "/MealCard",
    icon: "people",
  },
  {
    id: 2,
    text: "Dining Areas",
    Link: "/MealCard/settings/DiningArea",
    icon: "restaurant",
  },
  {
    id: 3,
    text: "Install Order Printer Application",
    // eslint-disable-next-line no-undef
    Link: process.env.REACT_APP_ORDERPRINTER_URL,
    icon: "system_update_alt",
    target: "_blank",
  },
];
