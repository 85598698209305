import { Button } from "@mui/material";
import React from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useGetRecipeFoodTypeQuery } from "../../../../../store/apis/RecipeFoodTypeAPIs";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import { ThumbDown } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export const DislikesIntolerances = () => {
  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");

  const {
    data: RecipeFoodTypeData,
    isFetching: loading,
    isError: hasError,
  } = useGetRecipeFoodTypeQuery(
    {
      id: recipeId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const RecipeFoodTypesColumn = [
    {
      field: "name",
      minWidth: 1150,
      flex: 1,
      headerName: "Food Type",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={RecipeFoodTypeData}
            hasEdit={canEdit}
            editProps={{
              To: `addDislikesIntolerances?id=${params.row.id}&recipeId=${recipeId}`,
              title: "Dislikes/Intolerances",
            }}
            hasDelete={canEdit}
            deleteProps={{
              entityName: params.row?.name,
              apiPath: "deleteRecipesFoodType",
              title: "Dislikes/Intolerances",
            }}
          />
        );
      },
    },
  ];
  return (
    <FlexBox
      flexGrow={1}
      flexDirection={"column"}
      alignItems={"start"}
      p={1}
      width={"100%"}
    >
      {canEdit === true ? (
        <Button
          component={Link}
          to={`addDislikesIntolerances?recipeId=${recipeId}`}
          color={"primary"}
          startIcon={<ThumbDown />}
          variant="contained"
        >
          Add New
        </Button>
      ) : null}

      <BaseDataGrid
        rows={RecipeFoodTypeData}
        columns={RecipeFoodTypesColumn}
        error={hasError}
        loading={loading}
        height={"100%"}
      />
    </FlexBox>
  );
};
