export const IngredientMenuElements = [
  {
    Name: "Back",
    Link: "",
    IsActive: false,
  },
  {
    Name: "Details",
    Link: "/admin/recipes/IngredientDetails/addIngredient",
    IsActive: false,
  },
  {
    Name: "Unit Conversions",
    Link: "/admin/recipes/IngredientDetails/UnitConversion",
    IsActive: false,
  },
  {
    Name: "HEP",
    Link: "/admin/recipes/IngredientDetails/addHep",
    IsActive: false,
  },
  {
    Name: "Vendors",
    Link: "/admin/recipes/IngredientDetails/VendorIngredient",
    IsActive: false,
  },
  {
    Name: "Allergies",
    Link: "/admin/recipes/IngredientDetails/IngredientAllergy",
    IsActive: false,
  },
  {
    Name: "Recipe Usage",
    Link: "/admin/recipes/IngredientDetails/IngredientUsage",
    IsActive: false,
  },
];

export const IngredientMenuElement = [
  {
    Name: "Back",
    Link: "",
    IsActive: false,
  },
  {
    Name: "Details",
    Link: "/admin/recipes/IngredientDetails/addIngredient",
    IsActive: false,
  },
];
