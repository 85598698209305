import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPE_CATEGORIES } = API_ENDPOINTS;

export const RecipeCategoriesApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeCategoryList: builder.query({
      query: ({ searchText, skip, take }) =>
        `/${RECIPE_CATEGORIES}?searchText=${searchText}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({
                type: RECIPE_CATEGORIES,
                id,
              })),
              RECIPE_CATEGORIES,
            ]
          : [RECIPE_CATEGORIES];
      },
    }),
    getRecipeCategorySelectBox: builder.query({
      query: ({ facilityId, filterToHTI, filterToCorporateGroup }) =>
        `/${RECIPE_CATEGORIES}/FacilityCategories?facilityId=${facilityId}&filterToHTI=${filterToHTI}&filterToCorporateGroup=${filterToCorporateGroup}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({ id: item?.id, label: item?.name })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_CATEGORIES, id })),
              RECIPE_CATEGORIES,
            ]
          : [RECIPE_CATEGORIES];
      },
    }),
    getRecipeCategoryFacilityOnlySelectBox: builder.query({
      query: ({ facilityId }) =>
        `/${RECIPE_CATEGORIES}/FacilityCategoriesOnly?facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({ id: item?.id, label: item?.name })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_CATEGORIES, id })),
              RECIPE_CATEGORIES,
            ]
          : [RECIPE_CATEGORIES];
      },
    }),
    getRecipeCategoryById: builder.query({
      query: (id) => `/${RECIPE_CATEGORIES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RECIPE_CATEGORIES, id: response.id }, RECIPE_CATEGORIES]
          : [RECIPE_CATEGORIES],
    }),
    addRecipeCategory: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_CATEGORIES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: RECIPE_CATEGORIES, id: result?.id },
      ],
    }),
    updateRecipeCategory: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_CATEGORIES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: RECIPE_CATEGORIES, id: result?.id },
      ],
    }),
    deleteRecipeCategory: builder.mutation({
      query: (id) => ({
        url: `/${RECIPE_CATEGORIES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [
        { type: RECIPE_CATEGORIES, id: result?.id },
      ],
    }),
  }),
});

export const {
  useGetRecipeCategoryListQuery,
  useGetRecipeCategorySelectBoxQuery,
  useGetRecipeCategoryFacilityOnlySelectBoxQuery,
  useGetRecipeCategoryByIdQuery,
  useAddRecipeCategoryMutation,
  useUpdateRecipeCategoryMutation,
  useDeleteRecipeCategoryMutation,
} = RecipeCategoriesApis;
