import { Box, Typography } from "@mui/material";
import React from "react";
import { SymbolsDetails } from "./constants";

export const RecipeSymbols = () => {
  return (
    <>
      <Box
        sx={{
          marginLeft: "5px",
          width: "95%",
          boxShadow: "var(--darkBoxShadow)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #CCCCCC",
        }}
      >
        {SymbolsDetails?.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              marginLeft: "10px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons"
              style={{
                color: data?.color,
                fontSize: "16px",
                marginRight: "5px",
              }}
            >
              {data?.icon}
            </span>
            <Typography sx={{ marginLeft: "5px", fontSize: "12px" }}>
              {data?.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};
